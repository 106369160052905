import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllComplains, deleteComplain } from '../actions';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';

const ReviewComplaints = (props) => {
  const [concatHTML, setConcatHTML] = useState(<></>);
  const [complaints, setComplaints] = useState([]);
  const [Admins, setAdmin] = useState([]);
  const [user, setUser] = useState('');
  const [department, setDepartment] = useState('');
  const [componentMounted, setComponentMounted] = useState(false);

  useEffect(()=>{setComponentMounted(true); return(()=> setComponentMounted(false))},[])

  useEffect(()=> {
    if (componentMounted){
    getDepartment((val) => {
        setDepartment(val);
        props.dispatch(getAllComplains(val)).then((response) => {
          if (componentMounted) {setComplaints(response.payload);}
        });
    });}
  },[componentMounted])

  useEffect(() => {
    getTokens((value) => {
      if (value && value[1, 4] && value[1, 4][1] !== null) setUser(value[1, 4][1].toLowerCase());
    });
    getAdmins((val) => setAdmin(val));
  }, [props]);

  const handleDeleteComplaint = (complaintId) => {
    props.dispatch(deleteComplain({ _id: complaintId })).then(() => {
      setComplaints(complaints.filter((l) => l._id !== complaintId));
    });
  };

  useEffect(() => {
    setConcatHTML(
      <>
      <div style={{width: '100vw', textAlign: 'center'}}>
      <h3>Feedback</h3>
      </div>
        <div style={{ fontFamily: 'Arial', backgroundColor: '#f8f8f8', padding: '20px' }}>
          {complaints.map((complaint) => (
            <div key={complaint._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span style={{ flex: '1', marginRight: '10px' }}>{complaint.complaint}</span>
              <span style={{ flex: '1', marginRight: '10px' }}>{complaint.createdAt}</span>
              {Admins.filter((obj) => obj.user === user && obj.position === 'pd').length > 0 ? (
                <button
                  onClick={() => handleDeleteComplaint(complaint._id)}
                  style={{
                    backgroundColor: '#f44336',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '6px 12px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    outline: 'none',
                  }}
                >
                  Delete
                </button>
              ) : null}
            </div>
          ))}
        </div>
      </>
    );
  }, [complaints, Admins, user]);

  return (
    <Fragment>
      {Admins.filter((obj) => obj.user === user && (obj.position === 'chief' || obj.position === 'pd' || obj.position === 'apd')).length > 0 ? (
        <>{concatHTML}</>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <label style={{ fontSize: '10rem' }}>⛔</label>
        </div>
      )}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return { khara: state };
}

const Container = connect(mapStateToProps)(ReviewComplaints);

export default Container;
