import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getTokens, getDepartment } from '../utils/misc';

const ControlButtons = (props) => {
  const [tooltip, setTooltip] = useState({ visible: false, content: '' });

  const handleMouseEnter = (content) => {
    setTooltip({ visible: true, content });
  };

  const handleMouseLeave = () => {
    setTimeout(() => setTooltip({ visible: false, content: '' }), 2000)
  };

  const handleClick = (path, content) => {
    props.history.push(path);
    //setTooltip({ visible: false, content: '' });
  };

  const renderButton = (path, imageSrc, altText, title) => (
    <button
      className={window.location.pathname.toLowerCase() === path.toLowerCase() ? 'btn btn-danger' : 'btn btn-dark'}
      onMouseEnter={() => handleMouseEnter(title)}
      onMouseLeave={handleMouseLeave}
      onTouchStart={() => handleMouseEnter(title)}
      onTouchEnd={handleMouseLeave}
      onClick={() => handleClick(path, title)}
    >
      <img src={imageSrc} style={{ width: '30px' }} alt={altText} />
    </button>
  );

  return (
    <div className="btn-group" role="group" aria-label="Basic example" style={{ textAlign: 'center', width: '100vw' }}>
      {renderButton('/handoff', require('../media/handoff.png'), 'Handoff', 'Handoff')}
      {renderButton('/tab1', require('../media/phonelogo.png'), 'Tab 1', 'Phonebook')}
      {renderButton('/QRreader', require('../media/Attendance.png'), 'QR Reader', 'Register Attendance')}
      {renderButton('/tab4', require('../media/rules.png'), 'Tab 4', 'Rules')}

      {tooltip.visible && (
        <div
          style={{
            position: 'fixed',
            bottom: '80px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#dc3545cc',
            color: '#fff',
            padding: '10px',
            borderRadius: '5px',
            zIndex: 999,
            transition: 'opacity 0.5s ease', // Added transition
            opacity: 1, // Set initial opacity to 1
          }}
        >
          {tooltip.content}
        </div>
      )}
    </div>
  );
};

export default withRouter(ControlButtons);
