import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';
import { setRules, getRules } from '../actions';
import Alerter from '../Components/AlerterwithMessage';

const Editor = (props) => {
  const [content, setContent] = useState('');
  const [Admins, setAdmin] = useState([]);
  const [user, setUser] = useState('');
  const [Department, setDepartment] = useState('');
  const [alerter, setAlerter] = useState(false);
  const [alerterMessage, setAlerterMessage] = useState('');
  const [alerterSuccess, setAlerterSuccess] = useState(false);
  const quillRef = useRef()

  useEffect(() => {
    
    getDepartment((val) => setDepartment(val));
    getTokens((value) => {
      if (value && value[1, 4] && value[1, 4][1] !== null) setUser(value[1, 4][1].toLowerCase());
    });
    getAdmins((val) => setAdmin(val));

  return () => {
    // Cleanup logic if needed
  };
}, []);

useEffect(() => {
    const fetchRules = async () => {
      
      const rulesResult = await props.dispatch(getRules(Department));
      const htmlString = rulesResult.payload[0].html;
      setContent(htmlString);

    };


    if(user !== '' && Department !== '' && Admins.length > 0)
    {
      fetchRules()

    }
  }, [user, Department, Admins])

  const triggerAlert = (message, success) => {
    setAlerterMessage(message);
    setAlerterSuccess(success)
    setAlerter(true);
    //setTimeout(function(){ setAlerter(false); setAlerterMessage(''); setAlerterSuccess(false) }, 10000);
  }


const handleSave = async () => {
    try {
      // Assuming setRules is an asynchronous function that returns a promise
      const result = await props.dispatch(setRules({
        html: content,
        department: Department,
        modifier: user,
      }));
      if(result.payload.success){triggerAlert('Rules saved successfully', true)}
      // Handle success or show a notification to the user
    } catch (error) {
      triggerAlert(error, false)
      // Handle error or show a notification to the user
    }
  };

  const insertPadding = () => {
    alert('test')
    // const cursorPosition = quillRef.current.getSelection().index;
    // quillRef.current.insertText(cursorPosition, '★');
    // quillRef.current.setSelection(cursorPosition + 1);
  }
  
  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image'],
        ['clean'],
       // ['padding'],
      ],
    //   handlers: {
    //     padding: insertPadding,
    //   },
    },
  };
  
  const hideMessage = () => {
    setAlerter(false); setAlerterMessage(''); setAlerterSuccess(false);
  }

  return (
    <div>
      {alerter ? <Alerter success={alerterSuccess} failed={!alerterSuccess} message={alerterMessage} closeAlerter={hideMessage}/> : null}
        {Admins.filter(obj => obj.user === user && ( obj.position === "chief" || obj.position === "pd" || obj.position === "apd")).length > 0 ?
      <div style={{padding: '10px'}}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={content}
        onChange={(value) => setContent(value)}
        style={{ height: '50vh', marginBottom: '10px' }}
        modules={modules}
      />
        <br/>
        <br/>
        <br/>
        <br/>
      <button className="btn btn-dark" onClick={handleSave}>Save your work</button>
      </div>:
      <div style={{textAlign: 'center'}}><label style={{fontSize: '10rem'}}>⛔</label></div>}
    </div> 
  );
};

function mapStateToProps(state) {
    return { khara: state }
  }
  
    
     const Container = connect(mapStateToProps)(Editor);
  
  
  export default Container;
