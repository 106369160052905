import React,{ useState, useEffect} from 'react';


import Input from '../utils/forms/input';
import ValidationRules from '../utils/forms/validationRules';

import { connect } from 'react-redux';
import { signUp, signIn, verify, getaccountinfo, forgot, resendVerifyLink} from '../actions';
import { bindActionCreators } from 'redux';
 
import { getAllAdministrators, getResidentType } from '../actions/getContact';
import { setTokens, setDepartment } from '../utils/misc';
import 'react-bootstrap/InputGroup';
import 'react-bootstrap/Button';

const AuthForm = (props) => {

    const [state, setState] = useState({
        Error: false,
        Verify: false,
        Pay: false,
        type:'Login',
        action:'Login',
        actionMode:'I do not have an account. Register',
        hasErrors:false,
        isSubscribed: false,
        ScreenWidthproperty: '95vw',
        form:{
            email:{
                value:"",
                valid:false,
                type:"text",
                rules:{
                    isRequired:true,
                    isEmail:true
                }
            },
            password:{
                value:"",
                valid:false,
                type:"password",
                rules:{
                    isRequired:true,
                    minLength:6
                }
            },
            confirmPassword:{
                value:"",
                valid:false,
                type:"password",
                rules:{
                    confirmPass:'password'
                }
            }
        },
        error: "Incorrect information. Please modify your information.",
        idToken: ''
    });

    

    useEffect(() => {
        setState({...state, isSubscribed: true});
        if(window.screen.width > window.screen.height){setState({...state, ScreenWidthproperty: '50vw'})}
        if(window.location.href.split('/')[3] != ''){window.location.href = '/';}

        //window.addEventListener('resize', updateDimensions);
        updateDimensions();
        window.addEventListener('keyup', function(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                document.getElementById("loginButton").click();
            }
            else if (event.keyCode === 13) {
              event.preventDefault();
              document.getElementById("loginButton").click();
          
          
          }

      })

      return(() => {
        setState({...state, isSubscribed: false});
        //window.removeEventListener('resize', updateDimensions);
        window.removeEventListener('keyup', () => {});
      })
    }, [])



    const updateDimensions = () => {if(window.screen.width > window.screen.height){setState({...state, ScreenWidthproperty: '50vw'})} else{setState({...state, ScreenWidthproperty: '90vw'})}}

 

    const formHasErrors = () => (
        state.hasErrors ?
            <div style={{color: "#892424"}}>
               {state.error}
            </div>
        :null
    )

    const confirmPassword = () => (
        state.type === 'Register' ? 
        <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{color: 'white', backgroundColor: '#37363b', borderColor: '#000000', fontWeight: 'bold', fontFamily: 'monospace'   }}>Confirm</span>
                </div>
            <Input
                    placeholder="Confirm your password"
                    type={state.form.confirmPassword.type}
                    name={state.form.password.type}
                    value={state.form.confirmPassword.value}
                    onChange={ value => setState({...state, form: {...state.form, confirmPassword: {value: value.target.value, valid: value.target.value.length > 5 ? true : false, type:"password", rules:{confirmPass:'password'}}}})}
                    className="form-control"
                    aria-label="Small" 
                    aria-describedby="inputGroup-sizing-sm"
                    //secureTextEntry={true}
                />
                </div>
                
        :null
    )

    const changeFormType = () => {
        const type = state.type;

        //if(this.state.isSubscribed){
        setState({...state, 
            hasErrors: false,
            error: "",
            type: type === 'Login' ? 'Register':'Login',
            action: type === 'Login' ? 'Register':'Login',
            actionMode: type === 'Login' ? 'I have an account. Login':'I do not have an account, Register'
        })

    }

    const ForgotPassword = () => {
        const type = state.type;

        setState({ ...state,
            type: type === 'Login' ? 'Forgot':'Login',
            action: type === 'Forgot' ? 'Login':'Send email to change the password',
            actionMode: type !== 'Forgot' ? 'Return to Login':'I Forgot my password'
        })

    }

    const resendLinkVerifyHandler = async () => {
      try {
        const res = await props.resendVerifyLink(state.idToken);

        if (res.payload?.success) {
          props.triggerAlert(`A verification email has been sent again to: ${res.payload.email}`, true);
        } else if (res.payload?.success === false) {
          throw new Error(res.payload.error.message);
        } else {
          throw new Error("Sending new verification email failed");
        }
      } catch (error) {
        props.triggerAlert(error.message, false);
      }
    };

    const manageAccess = (val) => {
        //if(!props.User.auth.uid){
          if(!val.payload.localId){
            setState({...state, hasErrors:true, error: "Incorrect Username/Password combination"})
            props.triggerAlert("Incorrect Username/Password combination", false)
        }
        else if (!val.payload.emailVerified)
        {
            props.triggerAlert("Please verify your email before logging in", false)
            setState({...state, hasErrors:true, error: "Please verify your email before logging in", Verify: true, idToken: val.payload.idToken})
        }
        else {
            props.getResidentType(state.form.email.value.toLowerCase()).then(
                
                res => 
                {
                setDepartment(res.payload[0]?.type, ()=>{
                    if(res.payload[0]?.type)
                    {
                        //setTokens(props.User.auth, state.form.email.value ,()=>{
                          setTokens({SessionCode: null, Verified: val.payload.emailVerified, refToken: val.payload.refreshToken, token: val.payload.idToken, uid: val.payload.localId}, state.form.email.value ,()=>{
                            if(state.isSubscribed)
                            {setState({...state, hasErrors:false});}
                            props.goNext();
                            })
                    }
                    else{
                      props.triggerAlert("Please reach out to your department administrator to be added to the list of authorized residents", false)
                        setState({...state, hasErrors:true, error: "Please reach out to your department administrator to be added to the list of authorized residents"})
                        }
                })
                }
              )

        }
    }

    const ShowVerifyforSignUp = (val) => {

        if(val.payload && val.payload.success)
        {
           props.triggerAlert(val.payload.error, false)
            setState({...state, hasErrors:true, error: val.payload.error})
        }
        else if(val.payload.Verified === false)
        {
            setState({...state, Verify: true})
        }
        else{
          props.triggerAlert("An unknown error has occurred. Please close this tab and open the web site to register again, or contact us for help", false)
            setState({...state, hasErrors:true, error: "An unknown error has occurred. Please close this tab and open the web site to register again, or contact us for help"})
        }
        
    }


    const LogOut = ()=>{
        setTokens({refToken: null, uid: null, token: null}, "", ()=>{
        if(state.isSubscribed)
         {setState({...state, redirect:true, mustlogin: true, UserName: ""})}
        })
      }

    const passwordRetriever = () => {
        let sArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', ' ', '.'];
        let cArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        return(
        cArray[2] + sArray[17] + sArray[4] + sArray[0] + sArray[19] + sArray[4] + sArray[3] + sArray[26] + sArray[1] + sArray[24] + sArray[26] +
        sArray[3] + sArray[17] + sArray[27] + sArray[26] + cArray[5] + sArray[0] + sArray[11] + sArray[0] + sArray[7] + sArray[26] + cArray[0] + sArray[1] + sArray[20] + sArray[26] + cArray[7]
        + sArray[0] + sArray[18] + sArray[18] + sArray[0] + sArray[13])
      }



    const submitUser = () => {
        let isFormValid = true;
        let formToSubmit = {};
        let error = "";
        const formCopy = state.form;
        if(state.form.email.valid === false){error = "Emails should be Texas Tech emails"; props.triggerAlert("This is not a Texas Tech email", false)};
        if(state.form.password.valid === false){error = "Passwords should at least be 6 characters"; props.triggerAlert("Passwords should at least be 6 characters", false)};
        if(state.form.confirmPassword.valid === false && state.type === "Register"){error = "Passwords should at least be 6 characters"; props.triggerAlert("Passwords should at least be 6 characters", false)};
        if(state.form.confirmPassword.value !== state.form.password.value && state.type === "Register"){error = "Passwords and confirmation do not match"; props.triggerAlert("Passwords and confirmation do not match", false)};
        

        for(let key in formCopy){
            if(state.type === 'Login'){
                //LOGIN
                if(key !== 'confirmPassword'){
                    isFormValid = isFormValid && formCopy[key].valid;
                    formToSubmit[key] = formCopy[key].value;
                }

            } 
            else if(state.type === 'Register'){
                // REGISTER
                isFormValid = isFormValid && formCopy[key].valid;
                formToSubmit[key] = formCopy[key].value;
             }
             else {
                 //Put forgot password Validation here
                 if(key === 'email'){
                    isFormValid = isFormValid && formCopy[key].valid;
                    formToSubmit[key] = formCopy[key].value;
                 }
             }
        }

        if(isFormValid){
            if(state.type === 'Login'){
                props.signIn(formToSubmit).then((val)=>{
                    manageAccess(val)
                })
            } else if(state.type === 'Register') {
                  props.signUp(formToSubmit).then((val)=>{
                    ShowVerifyforSignUp(val)
                    if(val.payload.Verified === false){
                      setState({...state, type: 'Login', action: 'Login', actionMode: 'I do not have an account, Register', hasErrors: true, error: "An Email has been sent to your inbox. Please check your junk folder too"});
                      props.triggerAlert("you have successfully registered, Please check your email. You will receive a verification email from us.", true);
                    }
                    else if(val.payload.error)
                    {
                      setState({...state, type: 'Login', action: 'Login', actionMode: 'I do not have an account, Register', hasErrors: true, error: val.payload.error});
                      props.triggerAlert(val.payload.error, false);
                    }

                    else{
                      props.triggerAlert("An unknown error has occured", false);
                      }
                    })
                    
                }
            
            else{
                ////Put forget password logic here
                props.forgot(formToSubmit.email).then((val) => {
                    if(val.payload.error){
                      props.triggerAlert("Email was not found in our registery. Please contact us for help", false)
                        setState({...state, hasErrors: true, error: "Email was not found in our registery. Please contact us for help", type: 'Login', action: 'Login', actionMode: 'Login'})
                    }
                    else
                    {
                      props.triggerAlert("An Email has been sent to your inbox. Please check your junk folder too", true)
                      setState({...state, hasErrors: true, error: "An Email has been sent to your inbox. Please check your junk folder too", type: 'Login', action: 'Login', actionMode: 'Login'})}
                })
            }
        } else {
          props.triggerAlert(error, false)
            setState({ ...state,
                hasErrors:true,
                error: error
            })
        }

    }


        return (
            <>
            <div className="inputcontainer" style={{textAlign: 'center', width: state.ScreenWidthproperty, maxWidth: '550px',background: 'linear-gradient(rgba(3, 18, 27, 0.55) 0%, rgb(16 4 15 / 20%) 100%)', borderRadius: '15px', padding: '25px', height: 'auto', maxHeight: '900px', boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23)"}}>
              <div>
              <label
  className="badge badge-danger"
  style={{
    padding: '15px',
    borderRadius: '10px',
    backgroundColor: '#37363b',
    color: 'rgb(255, 255, 255)',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    margin: '10px 0',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    display: 'inline-block',
    width: '100%',

  }}
>
  Texas Tech Residents
</label>

              <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{color: 'white', backgroundColor: '#37363b', borderColor: '#000000', fontWeight: 'bold', fontFamily: 'monospace'}}>UserName</span>
                </div>
                <Input
                    placeholder="Enter email"
                    type={state.form.email.type}
                    value={state.form.email.value}
                    onChange={value => {
                        const domain = value.target.value.split('@')[1];
                        const isValid = domain === 'ttuhsc.edu' || domain === 'va.gov';
                        
                        setState({...state, 
                          form: {
                            ...state.form,
                            email: {
                              value: value.target.value,
                              valid: isValid,
                              type: "text",
                              rules: {
                                isRequired: true,
                                isEmail: true
                              }
                            }
                          }
                        });
                      }}
                      
                    className="form-control"
                    aria-label="Small" 
                    aria-describedby="inputGroup-sizing-sm"
                    //overrideStyle={{}}
                />
                </div>
                {state.type !== "Forgot" ?
                <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{color: 'white', backgroundColor: '#37363b', borderColor: '#000000', fontWeight: 'bold', fontFamily: 'monospace'   }}>Password</span>
                </div>
                <Input
                    placeholder="Enter your password"
                    type={state.form.password.type}
                    name={state.form.password.type}
                    value={state.form.password.value}
                    onChange={ value => setState({...state, form: {...state.form, password: {value: value.target.value, valid: value.target.value.length > 5 ? true : false, type: "password", rules:{isRequired:true, minLength:6}}}})}
                    className="form-control"
                    aria-label="Small" 
                    aria-describedby="inputGroup-sizing-sm"
                />
                </div> : null}
                {confirmPassword()}
                <div style={{width: "100%", textAlign: 'center'}}>

                {state.type === 'Login' || state.type === 'Forgot' ? (
  <>
  {state.Verify ?       <label
        onClick={() =>
          resendLinkVerifyHandler()
        }
        style={{
          color: '#ffffff',
          margin: '10px 0 10px 0',
          textDecoration: 'underline',
          paddingTop: '5px',
          cursor: 'pointer',
          display: 'block',
          transition: 'color 0.3s ease',
        }}
      >
        Resend my verification link
      </label> : null}
    {state.type === 'Login' ? <label
      onClick={() => {
        setState({...state, isSubscribed: false})
        changeFormType();
      }}
      style={{
        color: '#ffffff',
        margin: '10px 0 10px 0',
        textDecoration: 'underline',
        paddingTop: '5px',
        cursor: 'pointer',
        display: 'block',
        transition: 'color 0.3s ease',
      }}
    >
      Register with my Texas Tech email
    </label> : null}
    {state.type !== 'Forgot' ? (
      <label
        onClick={() => ForgotPassword()}
        style={{
          color: '#ffffff',
          margin: '10px 0 10px 0',
          textDecoration: 'underline',
          paddingTop: '5px',
          cursor: 'pointer',
          display: 'block',
          transition: 'color 0.3s ease',
        }}
      >
        Forgot your Password?
      </label>
    ) : (
      <label
        onClick={() =>
          setState({ ...state,
            hasErrors: false,
            error: '',
            type: 'Login',
            action: 'Login',
            actionMode: 'I do not have an account, Register',
            isSubscribed: true
          })
        }
        style={{
          color: '#ffffff',
          margin: '10px 0 10px 0',
          textDecoration: 'underline',
          paddingTop: '5px',
          cursor: 'pointer',
          display: 'block',
          transition: 'color 0.3s ease',
        }}
      >
        Go back to Login screen
      </label>
    )}
  </>
) : (
  <label
    onClick={() => {
      setState({...state, isSubscribed: true})
      ForgotPassword();
      changeFormType();
    }}
    style={{
      color: '#ffffff',
      margin: '10px 0 10px 0',
      textDecoration: 'underline',
      paddingTop: '5px',
      cursor: 'pointer',
      display: 'block',
      transition: 'color 0.3s ease',
    }}
  >
    Already registered?
  </label>
)}

                  </div>
                {formHasErrors()}
                <br/>
                </div>
                <div style={{textAlign: 'center'}}>
                <button
                    id="loginButton"
                    className="btn btn-danger"
                    style={{
                     backgroundColor: '#37363b',
                     borderRadius: '8px',
                     padding: '12px 20px',
                     fontWeight: 'bold',
                     color: 'rgb(255, 255, 255)',
                     border: 'none',
                     boxShadow: 'rgb(46 37 37 / 30%) -5px 5px 6px',
                     transition: 'background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease',
                     cursor: 'pointer',
                     outline: 'none',}} onClick={submitUser}> {state.action} </button>



                </div>

            </div>
            </>
        )
    
}



function mapStateToProps(state){
    return {
        User: state.Userfunc
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({signIn,signUp,verify,getaccountinfo,forgot, getResidentType, resendVerifyLink},dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AuthForm);
