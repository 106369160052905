import axios from 'axios';
import { getTokens, url } from '../utils/misc';


  export function signIn(data){

    const request = axios.post(`${url}/api/login`,{
        method:'POST',
        data:
        {
            email:data.email,
            password:data.password,
            returnSecureToken:true
        }

    })
                    .then( (response) => { return response.data} )
                    
                    .catch(e => {return false});
                  

    return({type: 'SIGN_IN',
    payload: request})
  }



  export const autoSignIn = (refToken) =>{
    const request = new Promise((resolve, reject) =>{
        getTokens((value) => {
        axios.post(`${url}/api/refresh`,{
        method:"POST",
        data: "grant_type=refresh_token&refresh_token=" + value[1][1],
        SessionCode: value[5][1], 
        email: value[4][1]
    }).then( response =>{
        resolve(response.data)
    }).catch( e => {
        return reject(e)
    });
})})
    return {
        type:'AUTO_SIGN_IN',
        payload:request
    }
  }


  export function signUp(data){

    const request = axios.post(`${url}/api/signup`,{
        method:'POST',
        data:{
            email:data.email,
            password:data.password,
            returnSecureToken:true
        }
    }).then(response=>{
        return response.data
    }).catch( e => {
        return false
    });
  
    return {
        type:'SIGN_UP',
        payload:request
    }
  }


  export function verify(refToken){
    const request = axios.post(`${url}/api/verify`,{
        requestType: "VERIFY_EMAIL",
        refToken: refToken
    }).then(response=>{
        return response.data
    }).catch( e => {
        return false
    });
  
    return {
        type:'VERIFY',
        payload:request
    }
  }

  export function verifyConfirm(oobCode){
    const request = axios.post(`${url}/api/confirmverify`,{
        oobCode: oobCode
    }).then(response=>{
        return response.data
    }).catch( e => {
        return false
    });
  
    return {
        type:'CONFIRMVERIFY',
        payload:request
    }
  }

  export function forgot(email){
    const request = axios.post(`${url}/api/forgotpassword`,{
        email: email
    }).then(response=>{
        return response.data
    }).catch( e => {
        return false
    });
  
    return {
        type:'FORGOT',
        payload:request
    }
  }

  export function changepassword(oobCode, newPassword){
    const request = axios.post(`${url}/api/changepassword`,{
        oobCode: oobCode, 
        newPassword: newPassword
    }).then(response=>{
        return response.data
    }).catch( e => {
        return false
    });
  
    return {
        type:'CHANGE_PASSWORD',
        payload:request
    }
  }

  export function getaccountinfo(refToken){
    const request = axios.post(`${url}/api/getaccountinfo`,{
        refToken: refToken
    }).then(response=>{
        return response.data
    }).catch( e => {
        return false
    });
  
    return {
        type:'GETACCOUNTINFO',
        payload:request
    }
  }

  export function Signout(){
  
    return {
        type:'SIGN_OUT',
        payload:null
    }
  }

  export function KickOut(){
  
    return {
        type:'KICK_OUT',
        payload:null
    }
  }

