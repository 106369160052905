import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider, useDispatch } from 'react-redux';
import promiseMiddleware from 'redux-promise';
import {createStore, applyMiddleware, compose} from 'redux';
import reducers from './reducers';
import Mode from "./Components/mode";
import handoff from "./Components/handoff"
import Idle from 'react-idle';
import { setTokens } from './utils/misc';
import Security from './auth/Security';
import Tab1 from './Components/Tab1';
import Tab2 from './Components/Tab2';
import Tab3 from './Components/Tab3';
import Tab4 from './Components/Tab4';
import Home from './Components/Home';
import QRgenerator from './Components/QRgenerator';
import LectureAttendance from './Components/LectureAttendance';
import HandoffTracking from './Components/HandoffTracking';
import Administrators from './Components/Administrators';
import ResidentTypes from './Components/residentType';
import ReviewComplaints from './Components/ReviewComplaints';
import QRreader from './Components/QRreader';
import myAttendance from './Components/myAttendance';
import VerifyPrintedCode from './Components/VerifyPrintedCode';
import RulesEditor from './Components/RulesEditor';
import AttendanceSuccess from './Components/AttendanceSuccess';
//import SchedulingComponent from './Components/Scheduler';
import Blockstartdate from './Components/BlockStartDate';
import QRManager from './Components/QRManager';



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// let Admins = [];

// export async function getAllAdministrators() {

//   try {
//     getDepartment(async val => {

//       const response = await axios.post(`${url}/api/getalladministrators`, {
//         headers: { Authorization: "grant_type=refresh_token&refresh_token=" }, department: val
//       });
//       setAdmins(response.data.data, ()=>{})
//       Admins = response.data.data
      
//       return {
//         payload: response.data.data,
//       };
//     })

//   } catch (error) {
//     console.warn(error);
//   }
// }

// await getAllAdministrators()


const createStoreWithMiddleware = createStore(reducers, composeEnhancers(
  applyMiddleware(promiseMiddleware)
));


// let user = "";
// getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) user = value[1,4][1].toLowerCase()})

const routing = ( 
  <Provider store={createStoreWithMiddleware}>
    
            <Router>
    <Security>
        <App>
        <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/handoff" component={handoff}/>
        <Route path="/tab1" component={Tab1}/>
        <Route path="/tab2" component={Tab2}/>
        <Route path="/tab3" component={Tab3}/>
        <Route path="/tab4" component={Tab4}/>
        <Route path="/QRreader" component={QRreader}/>
        <Route path="/QRCodegenerator" component={QRgenerator}/> 
        <Route path='/LectureAttendance' component={LectureAttendance}/> 
        <Route path='/HandoffTracking' component={HandoffTracking}/> 
        <Route path='/ReviewComplaints' component={ReviewComplaints}/> 
        <Route path='/Administrators' component={Administrators}/> 
        <Route path='/ResidentTypes' component={ResidentTypes}/> 
        <Route path='/verifyprintedcode' component={VerifyPrintedCode}/> 
        <Route path='/attendancesuccess' component={AttendanceSuccess}/> 
        <Route path='/myattendance' component={myAttendance}/> 
        <Route path='/ruleseditor' component={RulesEditor}/> 
        {/* <Route path='/SchedulingComponent' component={SchedulingComponent}/> */}
        <Route path='/blockstartdate' component={Blockstartdate}/>
        <Route path='/qrmanager' component={QRManager}/> 
        <Route path="/" component={Mode}/>
        </Switch>
        </App>
        </Security>
        </Router>
    </Provider>
    
  )

  function LogOut() {
    setTokens({refToken: null, uid: null, token: null}, "", ()=>{
      window.location.href = '/logout'
    })
    
  }

  const IDLEObject = <Idle
  timeout={1800000}
  onChange={({ idle }) => idle ? LogOut() : {}}
  render={({ idle }) =>
  routing
  }
  />

  const root = document.getElementById('root');
const rootContainer = createRoot(root);

rootContainer.render(IDLEObject);


serviceWorker.unregister();
