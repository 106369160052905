import React, {useState, useEffect, Fragment} from 'react';
import { withRouter } from 'react-router-dom';


import AuthLogo from './authLogo';
import AuthForm from './authForm';

import { connect } from 'react-redux';
import { autoSignIn, Signout } from '../actions';
import { bindActionCreators } from 'redux';
 

import { HashLoader} from 'react-spinners';

import './blur.css';
import Alerter from '../Components/AlerterwithMessage';

const AuthComponent = (props) => {



  

  // const [stateloading, setStateloading] = useState({loading: true});
  const [stateloading] = useState({loading: false});
  // const [stateredirect, setStateredirect] = useState({redirect: false});
  const [statehasErrors, setStatehasErrors] = useState(false);
  const [stateerror, setStateerror] = useState("No errors");
  const [padding, setPadding] = useState('0 rem');
  const [alerter, setAlerter] = useState(false);
  const [alerterMessage, setAlerterMessage] = useState('');
  const [alerterSuccess, setAlerterSuccess] = useState(false);

  useEffect(()=>{
    let isSubscribed = true;
    if(props.errorrmessage && props.errorrmessage !== "" && isSubscribed){setStateerror(props.errorrmessage); setStatehasErrors(true)}

    return () => isSubscribed = false;
  }, [props.errorrmessage])

  useEffect(() => {
    assignPadding();
  }, [window.screen.width])



  const goNext = () => {
    props.history.push("/", {redirect: false});
    props.allowentry();
      }

      const assignPadding = () =>
      {
        if(window.screen.width < window.screen.height) {setPadding('0rem');}
        else {setPadding('3rem');}
      }

      const triggerAlert = (message, success) => {
        setAlerterMessage(message);
        setAlerterSuccess(success)
        setAlerter(true);
        //setTimeout(function(){ setAlerter(false); setAlerterMessage(''); setAlerterSuccess(false) }, 10000);
  }

  const hideMessage = () => {
    setAlerter(false); setAlerterMessage(''); setAlerterSuccess(false);
  }
    
    
      const Background = require('../media/exfix.jpg');
      
      if(stateloading.loading === true){
        return(        
        <div style={{marginLeft: "50vw",
          marginTop: "50vh",
          transform: "translate(-50%, -30vh)",
          position: "absolute"}}>
        <HashLoader
          sizeUnit={"px"}
          size={100}
          color={'#02293f'}
          loading={stateloading.loading}
        />
         </div>
        )
      }
      else
      return (
<Fragment>
  {alerter ? <Alerter success={alerterSuccess} failed={!alerterSuccess} message={alerterMessage} closeAlerter={hideMessage}/> : null}
  <div className="content" style={{
    padding: padding,
    width: '100vw',
    margin: '0',
    textAlign: 'center',
    minHeight: '100vh',
    backgroundImage: 'linear-gradient(to bottom, rgb(52 54 64), rgb(162 152 152))',
    color: 'white',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '0px',
    height: '100vh',
    overflowX: 'clip',
    display: 'grid',
    alignContent: 'space-between',
    justifyContent: 'center',
  }}>
    <div className="inner-container" style={{
      backgroundColor: '#bdb2b2',
      padding: '2rem',
      borderRadius: '8px',
      flexGrow: 1, /* Ensures responsiveness using Flexbox */
      maxWidth: '600px', /* Sets maximum width for wider screens */
      '@media (maxWidth: 600px)': { /* Media query for smaller screens */
        margin: '10px',
      },
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23)'
    }}>
      <AuthLogo style={{
        animation: 'pulse 2s infinite ease-in-out',
        transform: 'scale(1)',
      }} />
      <div style={{
        visibility: `${statehasErrors ? "visible" : "collapse"}`,
        marginTop: '1rem',
      }}>
        <label style={{
          color: '#d63040',
          overflowWrap: 'break-word',
          minWidth: '200px',
          display: 'inline-block',
          width: '100%',
          fontWeight: 'bold',
        }}>{stateerror?.message}</label>
      </div>
      <div style={{width: '100%', display: 'grid', alignContent: 'center', justifyContent: 'space-around'}}>
      <AuthForm goNext={goNext.bind(this)} style={{ animation: 'fadeInUp' }} triggerAlert={triggerAlert}/>
      </div>
    </div>
  </div>
</Fragment>
       
      );
    
    
  }





function mapStateToProps(state){
  return {
      User: state.Userfunc
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({autoSignIn, Signout},dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AuthComponent));
