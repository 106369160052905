import axios from 'axios';
import { getDepartment, getTokens, setAdmins, url } from '../utils/misc';


export async function getAllContacts(){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.get(`${url}/api/getallContacts`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_ALLCONTACTS',
                    payload: request})
  }

  export async function checkContactAvailable(name){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getthisContact`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }, data: {name: name} })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_CHKCONTACT',
                    payload: request})
  }

  export async function getAllAttendances(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getallattendances`, department,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_ATTENDANCES',
                    payload: request})
  }

  export async function getUserAttendance(department, user, startDate){

    let token = {};
    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getuserattendance`, {department: department, user: user, startDate: startDate}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_USERATTENDANCES',
                    payload: request})
  }

  export async function getAllAdministrators(department){

    let token = {};
    let Department = '';

    await getTokens(tok => token = {...tok});
    await getDepartment(dep => Department = dep)
      
    if(department && department !== '') {Department = department}
        const request = axios.post(`${url}/api/getalladministrators`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }, department: Department })
                    .then ( (response) => { setAdmins(response.data.data, ()=>{return response.data.data}) } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_ALLADMINISTRATORS',
                    payload: request})
  }

  export async function getAllResidentTypes(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getallresidenttypes`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }, department: department})
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_ALLRESIDENTTYPES',
                    payload: request})
  }

  export async function getResidentType(email){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getresidenttype`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }, email: email })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_RESIDENTTYPE',
                    payload: request})
  }
  
  export async function getAllHandoffs(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getallhandoffs`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }, department: department})
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_ALLHANDOFFS',
                    payload: request})
  }

  export async function getAllComplains(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getallComplains`, { department: department, headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_ALLCOMPLAINS',
                    payload: request})
  }

  export async function getCurrentTime(){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.get(`${url}/api/getcurrenttime`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_TIME',
                    payload: request})
  }

  export async function getProblemResident(){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.get(`${url}/api/getproblemresident`, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_PROBLEMRESIDENT',
                    payload: request})
  }

  export async function getRules(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getrules`, {department: department}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_RULES',
                    payload: request})
  }

  export async function getLectureType(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getlecturetypes`, {department: department}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_LECTURETYPE',
                    payload: request})
  }

  export async function getBlockStartDate(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getblockstartdates`, {department: department}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_BLOCKSTARTDATE',
                    payload: request})
  }

  export async function getqrcodes(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getqrcodes`, {department: department}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_QRCODES',
                    payload: request})
  }

  export async function getBlocked(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/getblocked`, {department: department}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_BLOCKED',
                    payload: request})
  }

  export async function getTimeZone(department){

    let token = {};

    await getTokens(tok => token = {...tok});
      
        
        const request = axios.post(`${url}/api/gettimezone`, {department: department}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                    .then ( (response) => { return response.data.data } )
                    .catch((error) => console.warn(error));

                    return({type: 'GET_TIMEZONE',
                    payload: request})
  }
