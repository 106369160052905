import React, { Fragment, useEffect, useState, useRef } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { getTokens, getDepartment } from '../utils/misc';
import { QrReader } from 'react-qr-reader';
import { submitAttendance } from '../actions/addContact';
import Alerter from './AlerterwithMessage';
var CryptoJS = require("crypto-js");

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const QRreader = (props) => {
    const [data, setData] = useState('No result');
    const [user, setUser] = useState('');
    const [reader, setReader] = useState(<label>We are sorry, we cannot track attendance without tagging your location. Please allow the location tag before proceeding.</label>);
    const [QRregister, setQRregister] = useState(null);
    const [AwaitingUsername, setAwaitingUsername] = useState(true);
    const [Latitude, setLatitude] = useState('');
    const [Longitude, setLongitude] = useState('');
    const [Altitude, setAltitude] = useState('');
    const [Accuracy, setAccuracy] = useState('');
    const [iteration, setIteration] = useState(0);
    const [Department, setDepartment] = useState('');
    const [alerter, setAlerter] = useState(false);
    const [alerterMessage, setAlerterMessage] = useState('');
    const [componentMounted, setComponentMounted] = useState(true);
    const [videoWidth, setVideoWidth] = useState(null);
    const [videoHeight, setVideoHeight] = useState(window.screen.width);
    const [SVG, setSVG] = useState(<></>);
    // const [dateNow, setDateNow] = useState(new Date());
    const qrReaderRef = useRef(null);
    let anothIteration = 0;

    // useEffect(() => {
    //     props.dispatch(getCurrentTime()).then(res => 
    //       {
    //         setDateNow(new Date(res.payload))
    //         // let diffs = (new Date() - new Date(res.payload))/60000;
    //         // if(Math.abs(diffs) > 10){alert('The time of your system does not match the time in Amarillo. Please fix your time prior to continuing.')}
    //       }
          
    //       )
    //   },[])

    useEffect(() => {
        getDepartment(val => {setDepartment(val)})
        getTokens(value => 
            {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase());
                setAwaitingUsername(false);
            })
            
    }, [])
    
    useEffect(() => {
        let isMounted = true;
        const handleGeoLocation = () => {
            window.navigator.geolocation.getCurrentPosition(e => {

                if(e.coords.latitude && Latitude === '' && componentMounted){
                setReader(null)
                setLatitude(e.coords.latitude.toString())
                setLongitude(e.coords.longitude.toString())
                setAltitude(e.coords.altitude ? e.coords.altitude.toString(): '')
                setAccuracy(e.coords.accuracy.toString())
                
                }
                // else{alert('Please try again, the attendance did not work')}
            }, e => {
                setReader(<label>We are sorry, we cannot track attendance without tagging your location. Please allow the location tag before proceeding.</label>)});
          };
      
          handleGeoLocation();
      
          return () => {
            setComponentMounted(false);
          };
    }, [])

    useEffect(()=>{if(document.getElementById('video')?.getClientRects()){setVideoWidth(document.getElementById('video')?.getClientRects()[0].width); setVideoHeight(document.getElementById('video')?.getClientRects()[0].height)}},[reader])


    useEffect(()=>{
        if(data !== 'No result' && (QRregister === '' || QRregister === null) && iteration === 0)
        {   
            if(data.split('^')[1] === "attendance") {
            if(data.split('@')[2] == Department){
            setIteration(1)
            if(data.includes('*') && data.includes(",")){
            let startDate = new Date();
            startDate.setHours(parseInt(data.split('*')[1].split(':')[0]), parseInt(data.split('*')[1].split(':')[1]), 0)
            let endDate = new Date();
            endDate.setHours(parseInt(data.split('*')[2].split(':')[0]), parseInt(data.split('*')[2].split(':')[1]), 0)
            // if(data.split('*')[0] === formatDate(new Date()) && new Date() >= startDate && new Date() <= endDate ){
                
                setReader(<label>Registering attendance...</label>)
                setQRregister(data)
            // }
            // else{setAlerterMessage('The QR code you are trying to scan is for a lecture that has not started or has ended'); triggerAlert();}
        }
        else{setAlerterMessage('invalid QR code detected'); triggerAlert();}
    } else{setAlerterMessage("This is not your department's code"); triggerAlert(); setData('No result')}
    }
    else{setAlerterMessage('This is not a lecture QR code'); triggerAlert();}
    }
    },[Latitude, data])


    useEffect(() => {
        
        if(QRregister && QRregister !== '' && AwaitingUsername === false && anothIteration === 0)
        {
            anothIteration++;
            submitAttendancefunc()
        }
    
    
    }, [QRregister, AwaitingUsername])

    useEffect(()=>{
        if(videoWidth > 0)
        {
            
            setSVG(<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={videoWidth} height={videoHeight} viewBox={`0 0 ${videoWidth} ${videoHeight}`} preserveAspectRatio="xMidYMid meet">
            <g transform={`translate(${0}, ${videoHeight}) scale(${videoWidth/3000 || 0.2}, ${videoWidth/-3000 || 0.2})`} fill="#FFFFFF" stroke="none">
            <path d="M172 1483 l3 -1328 1325 0 1325 0 0 1325 0 1325 -1328 3 -1327 2 2 -1327z m2638 0 l0 -1318 -1315 0 -1315 0 0 1318 0 1317 1315 0 1315 0 0 -1317z"/>
            <path d="M333 2693 c-52 -10 -53 -19 -53 -410 0 -350 1 -364 20 -383 19 -19 33 -20 390 -20 367 0 369 0 390 22 19 21 20 33 20 384 0 341 -1 363 -19 385 l-19 24 -354 1 c-194 1 -363 0 -375 -3z m747 -32 c6 -13 10 -149 10 -373 0 -304 -2 -357 -16 -376 l-15 -22 -369 0 -369 0 -15 22 c-14 19 -16 72 -16 375 0 264 3 358 12 373 12 19 24 20 390 20 363 0 378 -1 388 -19z"/>
            <path d="M400 2285 l0 -295 290 0 290 0 0 295 0 295 -290 0 -290 0 0 -295z m570 0 l0 -285 -280 0 -280 0 0 285 0 285 280 0 280 0 0 -285z"/>
            <path d="M628 2463 c34 -2 90 -2 125 0 34 2 6 3 -63 3 -69 0 -97 -1 -62 -3z"/>
            <path d="M527 2442 c-14 -15 -17 -42 -17 -153 0 -184 -5 -179 180 -179 186 0 180 -6 180 173 0 104 -3 137 -16 155 -8 12 -19 22 -25 22 -5 0 0 -9 11 -20 18 -18 20 -33 20 -153 0 -174 8 -167 -173 -167 -173 0 -167 -6 -167 172 0 115 2 130 20 148 11 11 16 20 12 20 -5 0 -16 -8 -25 -18z"/>
            <path d="M683 2343 l57 -4 0 -49 0 -49 -57 -4 -58 -3 63 -2 62 -2 0 60 0 60 -62 -2 -63 -2 58 -3z"/>
            <path d="M1945 2693 c-28 -7 -45 -23 -45 -41 0 -12 4 -11 16 6 l15 22 374 0 c360 0 375 -1 385 -19 6 -13 10 -150 10 -379 0 -335 -1 -360 -18 -375 -17 -15 -57 -17 -376 -17 -227 0 -364 4 -377 10 -10 6 -19 18 -20 28 0 11 -2 13 -6 4 -3 -7 3 -22 13 -33 16 -18 33 -19 386 -19 355 0 369 1 388 20 19 19 20 33 20 388 0 362 0 369 -21 388 -20 18 -42 19 -373 20 -193 1 -360 0 -371 -3z"/>
            <path d="M1216 2668 c-21 -29 -20 -40 5 -72 25 -31 61 -34 89 -6 41 41 13 100 -48 100 -21 0 -36 -7 -46 -22z m94 -8 c15 -27 4 -59 -25 -72 -46 -21 -88 29 -58 71 19 28 68 28 83 1z"/>
            <path d="M1567 2672 c-14 -15 -17 -38 -15 -118 l2 -99 5 100 c3 55 10 106 14 113 10 15 59 16 75 0 8 -8 12 -56 12 -149 0 -157 -5 -169 -71 -169 l-39 0 0 -213 c0 -195 -2 -215 -18 -230 -22 -20 -55 -22 -70 -4 -6 7 -12 33 -14 57 l-3 45 -60 0 -60 0 -6 -42 c-3 -23 -11 -49 -19 -58 -19 -23 -62 -15 -78 15 -16 31 -12 154 6 172 8 8 52 15 115 18 l102 5 0 60 0 60 -45 3 c-58 4 -70 16 -70 68 l0 44 -38 0 c-68 0 -98 45 -57 86 16 15 19 24 10 24 -17 0 -45 -38 -45 -60 0 -29 43 -60 83 -60 36 0 37 -1 37 -37 0 -21 4 -43 8 -49 11 -18 48 -34 76 -34 24 0 26 -3 26 -50 l0 -50 -94 0 c-90 0 -96 -1 -115 -26 -35 -45 -27 -180 13 -206 40 -27 96 12 96 67 l0 35 50 0 c48 0 50 -1 50 -29 0 -38 35 -81 66 -81 13 0 33 9 44 20 19 19 20 33 20 230 l0 210 38 0 c69 0 77 20 77 180 0 101 -4 141 -14 153 -18 23 -74 22 -94 -1z"/>
            <path d="M1340 2560 c-14 -14 -20 -32 -18 -62 l1 -43 4 41 c5 55 33 81 71 68 24 -8 27 -15 30 -57 2 -26 6 -47 10 -47 14 0 3 83 -14 101 -22 25 -59 24 -84 -1z"/>
            <path d="M2012 2288 l3 -293 290 0 290 0 0 290 0 290 -293 3 -292 2 2 -292z m578 -3 l0 -285 -285 0 -285 0 0 285 0 285 285 0 285 0 0 -285z"/>
            <path d="M2163 2460 c-36 -15 -43 -43 -43 -170 0 -116 8 -155 34 -172 6 -4 75 -8 152 -8 120 0 144 3 155 17 13 15 12 15 -8 5 -13 -8 -73 -12 -156 -12 -113 0 -137 3 -148 17 -10 11 -15 54 -17 144 -4 126 -3 129 22 154 24 24 30 25 148 25 174 0 167 6 170 -173 l2 -142 4 130 c3 153 1 168 -33 183 -30 14 -249 15 -282 2z"/>
            <path d="M2240 2290 l0 -60 65 0 66 0 -3 58 -3 57 -62 3 -63 3 0 -61z m120 0 l0 -50 -55 0 -55 0 0 50 0 50 55 0 55 0 0 -50z"/>
            <path d="M1725 2343 c45 -11 55 -32 55 -113 0 -65 -3 -80 -20 -95 -16 -15 -26 -17 -48 -9 -26 9 -27 12 -30 98 -2 58 1 94 10 104 9 13 7 13 -8 -1 -28 -26 -28 -165 0 -196 24 -26 60 -27 86 -1 17 17 20 33 20 100 0 88 -17 121 -62 119 -21 -1 -21 -2 -3 -6z"/>
            <path d="M1890 2165 c0 -65 2 -116 5 -113 7 7 8 218 1 226 -3 3 -6 -48 -6 -113z"/>
            <path d="M1682 1988 c-18 -18 -14 -73 6 -91 25 -23 58 -21 82 3 24 24 25 48 4 78 -17 25 -71 31 -92 10z m92 -30 c8 -22 6 -32 -9 -48 -34 -38 -85 -20 -85 30 0 35 19 52 55 48 22 -2 32 -10 39 -30z"/>
            <path d="M1570 1870 c-14 -14 -20 -32 -18 -62 l1 -43 4 42 c4 42 29 73 60 73 23 0 43 -32 43 -71 0 -22 5 -39 11 -39 16 0 2 80 -18 102 -21 24 -58 23 -83 -2z"/>
            <path d="M295 1746 c-22 -33 -13 -73 20 -88 33 -15 69 -3 85 28 21 39 -3 78 -52 82 -30 3 -40 -1 -53 -22z m81 6 c6 -4 14 -17 18 -29 8 -24 -22 -63 -49 -63 -19 0 -55 35 -55 54 0 34 55 59 86 38z"/>
            <path d="M526 1748 c-9 -12 -16 -37 -16 -55 0 -31 -2 -33 -33 -33 -18 0 -43 -7 -55 -16 -20 -14 -22 -24 -22 -103 0 -72 3 -91 18 -104 23 -21 66 -22 82 -1 12 13 10 14 -13 3 -21 -9 -33 -9 -52 -1 -24 11 -25 16 -25 95 0 97 13 117 72 117 35 0 36 1 41 46 5 48 18 64 53 64 31 0 54 -31 54 -73 l0 -37 154 0 c167 0 186 -6 186 -54 0 -31 -17 -44 -65 -48 l-40 -3 -3 -62 -3 -63 40 0 c45 0 71 -20 71 -54 0 -31 -17 -44 -65 -48 -39 -3 -40 -4 -46 -45 -6 -50 -24 -73 -55 -73 -46 0 -54 31 -54 198 l0 153 -117 -3 -118 -3 -1 -45 c-1 -39 -1 -40 3 -7 l4 37 110 0 109 0 0 -140 c0 -150 9 -187 46 -196 50 -13 84 20 84 82 0 20 5 24 30 24 48 0 80 26 80 66 0 40 -29 64 -77 64 -33 0 -33 0 -33 49 l0 48 42 6 c47 6 68 27 68 68 0 51 -27 59 -192 59 l-148 0 0 33 c0 48 -24 77 -65 77 -24 0 -39 -7 -49 -22z"/>
            <path d="M1170 1757 c32 -16 40 -65 14 -84 -11 -8 -47 -13 -91 -13 -60 0 -77 4 -93 20 -11 11 -20 15 -20 9 0 -26 36 -38 116 -39 66 0 84 3 97 18 33 36 12 102 -31 102 -12 0 -9 -4 8 -13z"/>
            <path d="M1337 1752 c-21 -23 -22 -51 -1 -80 15 -21 20 -22 215 -22 186 0 200 1 219 20 11 11 20 28 20 38 0 28 -29 62 -53 61 -19 0 -19 -2 6 -11 35 -13 46 -52 22 -78 -17 -19 -32 -20 -211 -20 -164 0 -195 2 -209 16 -23 23 -15 66 15 81 16 8 19 13 9 13 -9 0 -23 -8 -32 -18z"/>
            <path d="M2026 1748 c-9 -12 -16 -30 -16 -40 0 -25 36 -58 63 -58 47 0 78 68 47 105 -19 23 -76 19 -94 -7z m82 0 c22 -22 14 -67 -14 -80 -45 -21 -87 21 -66 66 13 28 58 36 80 14z"/>
            <path d="M2370 1751 c-36 -69 36 -135 90 -81 11 11 19 30 19 43 -2 21 -2 22 -6 1 -7 -37 -39 -59 -71 -48 -30 11 -42 55 -21 80 14 17 60 18 79 2 12 -11 12 -10 1 5 -19 24 -77 23 -91 -2z"/>
            <path d="M2608 1759 c-13 -7 -18 -23 -18 -54 l0 -45 -40 0 c-27 0 -45 -6 -60 -22 -42 -45 -14 -95 59 -105 l41 -6 0 -48 0 -49 -40 0 c-72 0 -106 -68 -54 -109 45 -35 180 -27 206 13 4 6 8 99 8 207 0 177 -2 197 -18 212 -21 19 -58 22 -84 6z m82 -18 c15 -29 13 -394 -2 -409 -8 -8 -45 -12 -100 -12 -84 0 -89 1 -100 25 -18 39 11 73 64 77 l43 3 0 60 0 60 -48 3 c-39 3 -50 7 -59 27 -18 39 11 73 64 77 42 3 43 4 46 43 2 22 8 45 14 52 16 20 66 16 78 -6z"/>
            <path d="M1922 1644 c-12 -8 -22 -23 -21 -32 0 -13 3 -11 9 5 13 31 55 40 80 18 17 -15 20 -31 20 -95 0 -64 -3 -80 -20 -95 -25 -22 -67 -13 -80 18 -6 14 -9 17 -9 6 -1 -22 31 -49 57 -49 45 0 62 33 62 120 0 67 -3 83 -20 100 -24 24 -48 25 -78 4z"/>
            <path d="M2144 1635 c-25 -25 -25 -27 -22 -161 3 -129 4 -137 27 -155 13 -10 38 -19 57 -19 l34 0 0 -50 0 -50 -38 0 c-62 0 -76 -24 -80 -136 l-4 -94 -109 0 -109 0 0 49 c0 49 1 50 33 54 31 4 31 4 -6 5 l-38 2 3 -57 3 -58 123 -3 122 -3 0 100 c0 115 8 131 71 131 l40 0 -3 63 -3 62 -42 3 c-23 2 -46 9 -53 16 -7 9 -10 63 -8 157 l3 144 27 9 c22 8 32 6 48 -9 17 -16 20 -31 21 -119 1 -74 3 -89 8 -56 4 25 10 51 14 58 11 17 178 17 197 0 8 -7 7 -4 -2 7 -24 26 -133 34 -177 12 l-31 -15 0 49 c0 82 -55 115 -106 64z"/>
            <path d="M1011 1538 c-14 -7 -27 -20 -29 -28 -4 -12 0 -11 15 3 18 16 45 18 274 18 229 0 255 -2 266 -18 32 -42 0 -83 -65 -83 l-42 0 0 -43 c0 -49 -13 -67 -50 -67 -37 0 -50 18 -50 67 l0 44 -62 -3 -63 -3 -3 -99 c-2 -59 -8 -106 -15 -115 -11 -14 -10 -14 6 -2 14 11 17 30 17 112 l0 99 55 0 55 0 0 -37 c0 -44 6 -56 37 -72 30 -15 40 -14 63 9 13 13 20 33 20 60 0 40 0 40 40 40 69 0 101 52 61 101 -19 24 -20 24 -263 27 -180 2 -249 -1 -267 -10z"/>
            <path d="M2471 1484 c-2 -31 -31 -52 -76 -56 l-40 -3 -3 -155 c-2 -89 -8 -160 -14 -167 -6 -7 -25 -18 -42 -23 l-31 -10 35 5 c63 9 70 28 70 196 l0 149 40 0 c31 0 43 5 56 25 8 13 14 32 11 42 -4 15 -5 15 -6 -3z"/>
            <path d="M980 1464 c0 -22 37 -44 73 -44 l37 0 0 -55 0 -55 -43 0 c-41 0 -67 -13 -67 -34 0 -5 8 -2 18 7 9 8 35 17 57 19 l40 3 3 63 3 62 -45 0 c-35 0 -48 5 -60 22 -9 12 -16 18 -16 12z"/>
            <path d="M301 1406 c-29 -30 -23 -68 15 -92 31 -21 68 -7 86 31 10 22 8 30 -9 53 -27 36 -62 40 -92 8z m63 8 c26 -10 37 -39 26 -68 -18 -48 -100 -29 -100 23 0 13 37 51 49 51 5 0 17 -3 25 -6z"/>
            <path d="M530 1408 c-11 -12 -20 -30 -20 -40 0 -23 43 -68 65 -68 20 0 65 44 65 63 0 26 -41 67 -65 67 -13 0 -33 -10 -45 -22z m80 -8 c11 -11 20 -26 20 -32 -1 -26 -28 -48 -59 -48 -25 0 -34 6 -41 26 -22 57 37 97 80 54z"/>
            <path d="M1686 1411 c-26 -29 -18 -82 16 -100 25 -14 29 -14 55 5 35 26 41 66 14 93 -26 26 -63 27 -85 2z m80 -16 c24 -36 6 -75 -34 -75 -35 0 -52 16 -52 52 0 49 59 65 86 23z"/>
            <path d="M1885 1312 c-99 -4 -105 -11 -105 -134 l0 -98 -49 0 -48 0 -7 42 c-9 57 -30 78 -78 78 -36 0 -38 1 -38 33 0 44 -25 77 -58 77 -15 0 -32 -4 -38 -8 -18 -11 -34 -48 -34 -76 l0 -26 -94 0 c-90 0 -96 -1 -115 -26 -30 -38 -30 -160 -1 -192 46 -49 111 -13 108 61 l-1 42 -4 -40 c-8 -70 -52 -96 -94 -54 -18 18 -21 30 -17 95 2 41 9 79 16 86 8 8 51 15 115 18 l102 5 3 40 c4 49 17 65 50 65 33 0 51 -26 52 -72 0 -37 1 -38 38 -38 53 0 65 -12 71 -67 l6 -48 60 0 60 0 5 100 c3 55 9 106 13 113 11 17 288 17 305 0 7 -7 12 -24 12 -38 0 -37 -18 -50 -67 -50 l-43 0 0 -40 c0 -27 -7 -47 -20 -60 -11 -11 -16 -20 -12 -20 20 0 42 39 42 73 0 36 1 37 38 37 43 0 55 6 71 37 14 28 -4 72 -33 78 -34 7 -136 10 -211 7z"/>
            <path d="M416 1294 c-21 -21 -20 -68 2 -85 14 -11 15 -11 5 1 -16 18 -17 62 -1 78 7 7 24 12 38 12 14 0 31 -5 38 -12 16 -16 15 -60 0 -78 -11 -12 -10 -12 5 -1 21 17 22 64 1 85 -8 9 -28 16 -44 16 -16 0 -36 -7 -44 -16z"/>
            <path d="M975 1240 c12 -42 32 -50 119 -48 l81 2 -83 3 c-81 4 -83 5 -103 36 -18 28 -20 29 -14 7z"/>
            <path d="M2612 1184 c-20 -14 -22 -24 -22 -103 0 -97 12 -121 62 -121 44 0 58 28 58 118 0 69 -3 85 -20 102 -24 24 -48 25 -78 4z m66 -10 c20 -13 22 -24 22 -94 0 -100 -24 -130 -81 -100 -16 9 -19 22 -19 95 0 63 4 87 16 99 19 20 34 20 62 0z"/>
            <path d="M296 1058 c-14 -19 -16 -73 -16 -385 0 -350 1 -364 20 -383 19 -19 33 -20 390 -20 357 0 371 1 390 20 19 19 20 33 20 383 0 312 -2 366 -16 385 l-15 22 -379 0 -379 0 -15 -22z m774 2 c23 -14 28 -721 5 -756 l-15 -24 -370 0 -369 0 -15 22 c-14 19 -16 72 -16 377 0 316 2 356 17 373 15 17 40 18 382 18 213 0 372 -4 381 -10z"/>
            <path d="M400 675 l0 -295 290 0 290 0 0 295 0 295 -290 0 -290 0 0 -295z m570 0 l0 -285 -280 0 -280 0 0 285 0 285 280 0 280 0 0 -285z"/>
            <path d="M535 835 c-24 -23 -25 -29 -25 -160 0 -181 -6 -175 180 -175 186 0 180 -6 180 175 0 131 -1 137 -25 160 -23 24 -29 25 -155 25 -126 0 -132 -1 -155 -25z m309 -11 c13 -12 16 -41 16 -146 0 -175 7 -168 -171 -168 -175 0 -169 -6 -169 172 0 102 3 130 16 142 23 23 285 23 308 0z"/>
            <path d="M632 688 l2 -53 3 48 4 47 50 0 49 0 0 -56 0 -56 -54 4 c-30 1 -52 0 -49 -4 2 -5 29 -8 59 -8 l55 0 -3 63 -3 62 -57 3 -58 3 2 -53z"/>
            <path d="M1430 1046 c0 -51 33 -86 81 -86 l39 0 0 -98 c0 -139 -12 -132 238 -132 196 0 201 1 216 22 18 25 21 64 6 73 -6 4 -7 -5 -4 -23 4 -20 1 -34 -11 -45 -24 -25 -395 -25 -419 -1 -12 12 -16 38 -16 115 l0 99 -43 0 c-54 0 -67 13 -67 67 0 24 -4 43 -10 43 -5 0 -10 -15 -10 -34z"/>
            <path d="M2242 1023 l-2 -63 38 0 c64 0 71 -12 74 -125 l3 -100 40 -1 c32 -1 34 -1 8 3 l-33 4 0 90 c0 82 -2 92 -25 114 -16 17 -35 25 -59 25 l-35 0 -4 58 -3 57 -2 -62z"/>
            <path d="M2376 1064 c-36 -35 -7 -104 42 -104 29 0 62 34 61 63 -1 17 -3 15 -10 -10 -11 -37 -44 -51 -78 -33 -24 12 -29 58 -9 78 16 16 60 15 78 0 12 -11 12 -10 1 5 -17 21 -64 22 -85 1z"/>
            <path d="M1340 950 c-47 -47 1 -124 63 -101 42 16 51 80 15 105 -30 21 -54 20 -78 -4z m71 0 c22 -12 26 -59 7 -78 -7 -7 -24 -12 -38 -12 -51 0 -69 55 -28 84 26 19 33 19 59 6z"/>
            <path d="M1664 956 c-3 -7 -4 -35 -2 -62 l3 -49 63 -3 62 -3 0 66 0 65 -60 0 c-42 0 -62 -4 -66 -14z m116 -52 l0 -55 -51 3 -52 3 1 48 c0 26 1 49 1 52 1 3 24 5 51 5 l50 0 0 -56z"/>
            <path d="M1960 850 c-59 -6 -61 -7 -20 -9 25 -1 102 -2 173 -1 l127 1 0 -50 0 -51 -35 0 c-25 0 -44 -8 -60 -25 -22 -21 -25 -33 -25 -93 0 -100 40 -144 101 -112 18 10 19 6 19 -95 0 -92 3 -108 20 -125 20 -20 56 -26 76 -12 27 18 34 56 34 191 l0 141 25 0 c38 0 75 21 82 47 6 28 -11 73 -28 73 -8 0 -6 -7 4 -18 20 -22 22 -55 5 -70 -7 -5 -33 -12 -58 -15 -25 -3 -46 -6 -47 -6 -2 -1 -3 -72 -3 -159 0 -166 -4 -182 -45 -182 -48 0 -55 25 -56 191 -1 99 -4 137 -8 106 -7 -50 -21 -67 -56 -67 -37 0 -45 20 -45 112 0 98 7 108 75 108 l36 0 -3 63 -3 62 -110 1 c-60 1 -139 -2 -175 -6z"/>
            <path d="M2486 828 c-9 -12 -16 -29 -16 -38 0 -29 30 -60 59 -60 26 0 61 26 61 46 0 4 -7 -2 -16 -14 -8 -12 -26 -22 -39 -22 -23 0 -55 32 -55 57 0 19 30 43 55 43 13 0 31 -10 39 -22 13 -18 16 -19 16 -6 0 40 -80 51 -104 16z"/>
            <path d="M1455 715 c-20 -19 -25 -34 -25 -72 0 -36 -3 -44 -12 -35 -7 7 -44 12 -95 12 -70 0 -86 -3 -103 -20 -42 -42 -7 -100 60 -100 39 0 40 -1 40 -37 0 -65 28 -83 125 -83 57 0 69 3 90 25 24 23 25 28 23 157 l-2 133 -5 -138 c-7 -165 -9 -167 -111 -167 -87 0 -110 16 -110 76 l0 44 -45 0 c-36 0 -47 4 -61 25 -15 23 -15 27 0 50 15 23 22 25 101 25 74 0 86 -3 97 -20 22 -34 28 -23 28 50 0 65 2 71 25 82 19 9 29 8 50 -6 14 -9 25 -12 25 -6 0 10 -41 30 -61 30 -5 0 -21 -11 -34 -25z"/>
            <path d="M1916 604 c-36 -35 -7 -104 42 -104 29 0 62 34 62 63 0 49 -70 76 -104 41z m88 -26 c8 -22 6 -32 -9 -48 -34 -38 -85 -20 -85 30 0 35 19 52 55 48 22 -2 32 -10 39 -30z"/>
            <path d="M2490 600 c-11 -11 -20 -29 -20 -40 0 -43 34 -60 118 -60 90 0 122 15 122 59 0 48 -26 61 -118 61 -69 0 -85 -3 -102 -20z m200 -9 c30 -57 0 -81 -100 -81 -70 0 -81 2 -94 22 -20 28 -20 43 0 62 12 12 36 16 99 16 73 0 86 -3 95 -19z"/>
            <path d="M1692 494 c-33 -23 -27 -83 10 -103 25 -14 29 -14 55 5 36 27 41 66 12 93 -27 25 -47 26 -77 5z m68 -14 c11 -11 20 -27 20 -35 0 -16 -34 -55 -48 -55 -23 0 -52 34 -52 60 0 47 46 64 80 30z"/>
            <path d="M2032 490 c-12 -12 -21 -31 -21 -43 l2 -22 4 23 c7 35 48 58 78 44 35 -16 35 -65 0 -88 -23 -15 -27 -15 -52 0 l-28 17 24 -20 c29 -26 42 -26 71 -3 43 35 15 112 -39 112 -10 0 -27 -9 -39 -20z"/>
            <path d="M1216 368 c-21 -30 -20 -50 4 -76 26 -27 64 -28 90 -2 40 40 12 100 -45 100 -24 0 -39 -7 -49 -22z m86 -5 c22 -20 23 -41 1 -65 -23 -26 -59 -23 -79 7 -15 23 -15 27 0 50 19 29 51 32 78 8z"/>
            <path d="M1916 374 c-36 -35 -10 -104 40 -104 31 0 64 33 64 63 0 49 -70 76 -104 41z m78 -10 c9 -8 16 -24 16 -34 0 -22 -26 -50 -46 -50 -46 0 -73 57 -42 88 17 17 54 15 72 -4z"/>
            <path d="M2607 372 c-36 -40 -9 -102 45 -102 51 0 78 69 40 103 -24 22 -65 21 -85 -1z m83 -13 c27 -49 -20 -96 -69 -69 -24 12 -29 58 -9 78 20 20 66 15 78 -9z"/>
            </g>
            </svg>)
        }
    },[videoWidth])

    const submitAttendancefunc = () => {
        props.dispatch(submitAttendance({
            id: props._id,
            User: user,
            department: Department,
            LectureDate: data.split('*')[0],
            LectureType: data.split('@')[1],
            LectureStartTime: data.split('*')[1],
            LectureEndTime: data.split('*')[2],
            LectureTitle: data.split(',')[1],
            Latitude: Latitude,
            Longitude: Longitude,
            Altitude: Altitude,
            Accuracy: Accuracy,
            Offset: new Date().getTimezoneOffset(),
          })).then((res) => {
            if (res.payload.success === true) {
                props.history.push('/attendancesuccess', {data: data, type: 'attendance'})
                
            } else {
                
                if(res?.payload?.error){setAlerterMessage(res.payload.error); triggerAlert();}
                else{setAlerterMessage('Please refresh and try again, the attendance did not register'); triggerAlert()}
            }
          });
    }

    const triggerAlert = () => {
          setAlerter(true);
          //setTimeout(function(){ setAlerter(false); setAlerterMessage('') }, 10000);
    }

    const hideMessage = () => {
        setAlerter(false); setAlerterMessage('');
      }

        
      


    return(

        <Fragment>
            {alerter ? <Alerter failed={true} message={alerterMessage} closeAlerter={hideMessage}/> : null}
        <div style={{width: '100vw', textAlign: 'center'}}>
        {Department === '' ? <label>We are fetching your department</label> : <>{reader ||               
    <div style={{width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <QrReader
    //ref={qrReaderRef}
    constraints={{facingMode: "environment", aspectRatio: '1' }}
    //style = {{height: '50vh'}}
    videoContainerStyle = {{width: '100vw', maxWidth: '600px'}}
    videoStyle={{width: '100vw', maxWidth: '600px', transform: 'translateY: -80px'}}
    ViewFinder={() => {return(<div style={{position: 'absolute', top: '0', zIndex: '1'}}>
        {SVG}
    </div>)}}
    onResult={(result, error) => {
      if (!!result) {
        var bytes  = CryptoJS.AES.decrypt(result?.text, 'shit');
        try{var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); setData(decryptedData);} catch { setAlerterMessage('This is not a Valid Lecture QR code'); triggerAlert()}
      }

      if (!!error) {
        //setAlerterMessage(error); triggerAlert();
      }
    }}
    
  />
  </div>}</>}
      </div>
      </Fragment>
    )

}

function mapStateToProps(state) {
  return { khara: state }
}
   const Container = connect(mapStateToProps)(QRreader);


export default Container;
