import React, { Fragment, useEffect, useState } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { deleteAdmin, submitAdmin } from '../actions/addContact';
import { getAdmins, getTokens, getDepartment } from '../utils/misc';
import { getAllAdministrators } from '../actions';



const Administrators = (props) => {
  const [email, setEmail] = useState('');
  const [position, setPosition] = useState('');
  const [users, setUsers] = useState([]);
  const [Admins, setAdmin] = useState([]);
  const [Department, setDepartment] = useState('');
  const [user, setUser] = useState('');
  const [ErrorDisplay, setErrorDisplay] = useState(false);
  const [Error, setError] = useState('');

  useEffect(()=>{
    getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})
    getAdmins(val => {setAdmin(val); setUsers(val)})
      getDepartment(val => {setDepartment(val)})
  },[])


  const handleSubmit = (e) => {
    e.preventDefault();
    if(Admins?.filter(obj => obj.user === email.toLowerCase()).length > 0){
      setError('You cannot add the same user twice');
      setErrorDisplay(true);
    }
    else {
    props.dispatch(submitAdmin({user: email.toLowerCase(), position: position, department: Department})).then(res => {
      if(res && res.payload && res.payload.success){
        setEmail('');
        setPosition('');
        setUsers([...users, {user: email.toLowerCase(), position: position, department: Department}]);
        props.dispatch(getAllAdministrators(Department)).then(() => getAdmins(val => setAdmin(val)));
      }
      else{
        setError("Administrator was not added");
      setErrorDisplay(true);
      }
    })
  }
  };

  const ConvertPosition = (input) => {
    if(input === "pd") return "Program Director"
    else if(input === "apd") return "Associate Program Director"
    else if(input === "chief") return "Chief Resident"
    else if(input === "housestaff") return "Housestaff Officer"
    else if(input === "coordinator") return "Coordinator"
    else if(input === "va") return "Vaterans Affairs"
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleDelete = (email) => {
    props.dispatch(deleteAdmin({'email' : email})).then((res) => {
      if (res && res.payload && res.payload.success) {
        const updatedUsers = users.filter((user) => user.user !== email);
        setUsers(updatedUsers);
        props.dispatch(getAllAdministrators(Department)).then(() => getAdmins(val => setAdmin(val)));
      } else {
        setError('Failed to delete administrator');
        setErrorDisplay(true)
      }
    });
  };

  const ConvertDepartment = (input) => {
    if(input === "PED") return "Pediatrics"
    else if(input === "MED") return "Medicine"
  }

  return (
    <Fragment>
      {Admins?.filter((obj) => obj.user === user && (obj.position === 'pd' || obj.position === 'apd')).length > 0 ? (
        <div style={{ textAlign: 'center' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <thead>
              <tr>
                <th style={{ background: '#f4f4f4', padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold', color: '#333', textTransform: 'uppercase' }}>User</th>
                <th style={{ background: '#f4f4f4', padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold', color: '#333', textTransform: 'uppercase' }}>Position</th>
                <th style={{ background: '#f4f4f4', padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold', color: '#333', textTransform: 'uppercase' }}>Department</th>
                <th style={{ background: '#f4f4f4', padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold', color: '#333', textTransform: 'uppercase' }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index} style={{ background: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: '#555' }}>{user.user}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: '#555' }}>{ConvertPosition(user.position)}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: '#555' }}>{ConvertDepartment(user.department)}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                    <button onClick={() => handleDelete(user.user)} style={{ marginRight: '10px', border: 'none', background: 'transparent' }}>
                    🗑️
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
  
          <br />
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', margin: '0 auto' }}>
            <label style={{ marginBottom: '10px', fontWeight: 'bold' }}>
              Email:
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                pattern=".+@(ttuhsc\.edu|va\.gov)"
                title="Please enter a valid @ttuhsc.edu email address"
                style={{ padding: '8px', marginTop: '5px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px', width: '80vw', maxWidth: '300px' }}
              />
            </label>
            <label style={{ marginBottom: '10px', fontWeight: 'bold' }}>
              Position:
              <select
                value={position}
                onChange={handlePositionChange}
                required
                style={{ padding: '8px', marginTop: '5px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px', width: '80vw', maxWidth: '300px' }}
              >
                <option value="">Select Position</option>
                <option value="pd">Program Director</option>
                <option value="apd">Associate Program Director</option>
                <option value="chief">Chief Resident</option>
                <option value="housestaff">Housestaff Officer</option>
                <option value="coordinator">Coordinator</option>
                <option value="va">Veterans Affairs</option>
              </select>
            </label>
            {ErrorDisplay ? <div><label style={{color: 'red'}}>{Error}</label></div>: null}
            <button type="submit" style={{ padding: '10px', marginTop: '10px', borderRadius: '4px', border: 'none', background: 'rgb(52 58 64)', color: '#fff', fontSize: '16px', fontWeight: 'bold', cursor: 'pointer' }}>
              Add Administrator
            </button>
          </form>
          <br/>
          <br/>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <label style={{ fontSize: '10rem' }}>⛔</label>
        </div>
      )}
    </Fragment>
  );  
}

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(Administrators);


export default Container;
