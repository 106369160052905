import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getBlockStartDate, setBlockStartDates } from '../actions';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';

const BlockStartDate = (props) => {
  const [editedDates, setEditedDates] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [Department, setDepartment] = useState('');
  const [Admins, setAdmin] = useState([]);
  const [user, setUser] = useState('');

  useEffect(() => {
    getDepartment((val) => {
      setDepartment(val);
      props.dispatch(getBlockStartDate(val)).then((result) => {if(result?.payload[0]?.blockStartDate?.length > 0){setEditedDates(result.payload[0].blockStartDate.map((dateString) => new Date(dateString)))}});
      getAdmins((val) => setAdmin(val));
    });
    getTokens((value) => {
      if (value && value[1, 4] && value[1, 4][1] !== null) setUser(value[1, 4][1].toLowerCase());
    });

    return () => {};
  }, []);

  useEffect(() => {
    // Initialize the editedDates state with Date objects
    const initialDates = editedDates.map((dateString) => new Date(dateString));
    setEditedDates(initialDates);
  }, []); 

  const handleDateChange = (index, event) => {
    const { value } = event.target;
    if (Date.parse(value)) {
      const updatedDates = [...editedDates];
      const updatedDate = new Date(event.target.value + 'T12:00:00Z');
      updatedDates[index] = updatedDate;
  
      // Sort the array in ascending order
      updatedDates.sort((a, b) => a.getTime() - b.getTime());
  
      setEditedDates(updatedDates);
    }
  };

  const handleSaveClick = () => {
    // Format the dates as "yyyy-mm-dd"
    const formattedDates = editedDates.map((date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    });

    props.dispatch(setBlockStartDates({ department: Department, blockStartDate: formattedDates }));
    setIsEditing(false);
    props.dispatch(getBlockStartDate(Department)).then((result) => {if(result?.payload[0]?.blockStartDate?.length > 0){setEditedDates(result.payload[0].blockStartDate.map((dateString) => new Date(dateString)))}});
  };

  const handleAddDate = () => {
    setEditedDates([...editedDates, new Date()]);
  };

  const handleRemoveDate = (index) => {
    const updatedDates = [...editedDates];
    updatedDates.splice(index, 1);
    setEditedDates(updatedDates);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <Fragment>
      {Admins.filter(
        (obj) =>
          obj.user === user && (obj.position === 'chief' || obj.position === 'pd' || obj.position === 'apd' || obj.position === 'va')
      ).length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {isEditing ? (
            <>
            <h2 style={{ textAlign: 'center', fontSize: '1.5rem', color: '#333', marginBottom: '20px' }}>Block Start Dates</h2>
            <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {editedDates.map((date, index) => (
                <div key={index} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', maxWidth: '400px', width: '100%', margin: '0 auto', paddingBottom: '10px' }}>
                  <input
                    type='date'
                    pattern="\d{4}-\d{2}-\d{2}" // Use pattern attribute to enforce date format (YYYY-MM-DD)
                    style={{
                      padding: '12px',
                      fontSize: '14px',
                      border: '2px solid #3498db',
                      borderRadius: '5px',
                      width: '60%',
                      marginRight: '10px',
                      outline: 'none',
                      transition: 'border-color 0.3s',
                    }}
                    value={date.toISOString().split('T')[0]}
                    onChange={(event) => handleDateChange(index, event)}
                  />
                  <button
                    className='btn btn-dark'
                    style={{
                      flex: '1',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      backgroundColor: '#333',
                      color: '#fff',
                    }}
                    onClick={() => handleRemoveDate(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
            <button
              className='btn btn-dark'
              style={{
                display: 'block',
                margin: '0 auto',
                marginBottom: '20px',
                padding: '10px 20px',
                borderRadius: '5px',
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: '#333',
                color: '#fff',
              }}
              onClick={handleAddDate}
            >
              Add Date
            </button>
            <button
              className='btn btn-dark'
              style={{
                display: 'block',
                margin: '0 auto',
                padding: '10px 20px',
                borderRadius: '5px',
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: '#333',
                color: '#fff',
              }}
              onClick={handleSaveClick}
            >
              Save
            </button>
            <br/>
          </>
          
          ) : (
            <>
            <br/>
  <h2 style={{ textAlign: 'center', fontSize: '1.5rem', color: '#333', marginBottom: '1.5rem' }}>Block Start Dates</h2>
  <div style={{ marginBottom: '1.5rem' }}>
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {editedDates.map((date, index) => (
  <li key={index} style={{ marginBottom: '1rem', fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center' }}>
    <span style={{ marginRight: '0.5rem', fontSize: '1.2rem' }}>📅</span>
    {date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })}
  </li>
))}
    </ul>
  </div>
  <button
    className='btn btn-primary'
    style={{
      display: 'block',
      margin: '0 auto',
      marginBottom: '2rem',
      padding: '1rem 2rem',
      borderRadius: '0.25rem',
      fontSize: '1rem',
      fontWeight: 'bold',
      backgroundColor: '#4CAF50',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
    }}
    onClick={handleEditClick}
  >
    Edit
  </button>
</>

          )}
        </div>
      ) : (
        <div style={{ textAlign: 'center', fontSize: '3rem' }}>⛔</div>
      )}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return { khara: state };
}

const Container = connect(mapStateToProps)(BlockStartDate);

export default Container;
