import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllAttendances, getUserAttendance } from '../actions';
import { getTokens, getDepartment } from '../utils/misc';
import { getBlockStartDate } from '../actions';

const NewComponent = (props) => {
  const [user, setUser] = useState('');
  const [lectures, setLectures] = useState([]);
  const [nameCountDisplay, setNameCountDisplay] = useState(0)
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate,setEndDate] = useState('2024-06-30');
  const [worksheetDataLength,setWorksheetDataLength] = useState(0);
  const [displayDate,setDisplayDate] = useState('');
  const [Department, setDepartment] = useState('');
  const [loading, setLoading] = useState(true);
  const [lectureCounts, setLectureCounts] = useState([]);
  const [startDates, setStartDates] = useState([]);
  // Add other state variables as needed

  const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

  function isTimeBefore(timeToCompare, referenceTime) {
    const currentTime = new Date(`2000-01-01 ${timeToCompare}`);
    const referenceTimeString = `2000-01-01 ${referenceTime}`;
    const referenceTimeObject = new Date(referenceTimeString);
  
    return currentTime < referenceTimeObject;
  }

  const calculateStartDate = () => {
    if(startDates.length > 0){
      for (const time of startDates) {
        if (new Date(new Date().toISOString().split('T')[0] + 'T12:00:00Z') > new Date(time.toISOString().split('T')[0] + 'T12:00:00Z') && new Date(new Date().toISOString().split('T')[0] + 'T12:00:00Z') < new Date(time.toISOString().split('T')[0] + 'T12:00:00Z').getTime() + (30 * 24 * 60 * 60 * 1000)) {
          setStartDate(time.toISOString().split('T')[0]);
          setEndDate(new Date(new Date(time).setDate(new Date(time).getDate() + 28)).toISOString().split('T')[0]);
          break;
        }
      }}
      else{
        setStartDate(startDate);
          setEndDate(new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 28)).toISOString().split('T')[0]);
      }
    
  };
  

  useEffect(() => {
      setLoading(true)
      getDepartment(val => {setDepartment(val); 
        props.dispatch(getBlockStartDate(val)).then((result) => {if(result?.payload[0]?.blockStartDate?.length > 0){
        setStartDates(result.payload[0].blockStartDate.map((dateString) => new Date(dateString)))
      }
      })
      });
      getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())});
    
    
  }, [])

  useEffect(()=>{calculateStartDate()},[startDates])

  useEffect(() => {
    if(Department !== '' & startDate !== '2024-01-01')
    {props.dispatch(getUserAttendance(Department, user, startDate)).then(val=>{setLectureCounts(val.payload.lectureCounts); setNameCountDisplay(val.payload.nameCountDisplay); setWorksheetDataLength(val.payload.worksheetDataLength); setDisplayDate(val.payload.startDate); setLoading(false)})}
  }, [Department, startDate]);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '50vh',
    backgroundColor: '#f0f0f0',
    margin: 0,
    animation: 'fadeInOut 3s infinite', // Adjust the animation duration as needed
  };

  const keyframes = `
    @keyframes fadeInOut {
      0%, 100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  `;

  const styleTag = document.createElement('style');
  styleTag.type = 'text/css';
  styleTag.appendChild(document.createTextNode(keyframes));
  document.head.appendChild(styleTag);

  return (
    <Fragment>
      {loading ? 
      <div style={containerStyle}>
      <div style={{marginBottom: '20px'}}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="133.000000pt" height="100.000000pt" viewBox="0 0 100.000000 75.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,75.000000) scale(0.050000,-0.050000)" fill="#000000" stroke="none">
<path d="M830 1396 c-39 -15 -39 -15 -20 -114 14 -73 7 -122 -20 -147 -12 -11 -20 -31 -20 -50 0 -29 -5 -34 -46 -50 -53 -20 -63 -38 -39 -65 16 -17 17 -32 12 -118 -9 -124 -16 -162 -32 -162 -8 0 -16 -6 -18 -12 -4 -10 -8 -9 -16 2 -9 12 -41 15 -188 14 -182 0 -253 -7 -253 -24 0 -6 113 -10 290 -10 290 1 327 5 287 35 -18 13 -24 50 -7 40 6 -4 17 -1 25 5 9 7 15 8 15 2 0 -5 11 -13 25 -16 14 -4 25 -14 25 -22 0 -13 -3 -14 -14 -5 -10 9 -16 7 -27 -5 -23 -27 8 -34 170 -34 132 0 152 2 147 15 -7 20 3 19 26 -2 16 -14 23 -15 38 -5 12 7 21 8 25 2 4 -6 131 -10 338 -9 l332 1 -135 19 c-74 10 -165 18 -202 18 -76 1 -94 12 -104 67 -4 24 -15 39 -33 48 -25 13 -194 57 -275 72 l-38 7 4 68 c5 65 4 68 -19 74 -23 5 -24 10 -21 53 2 36 -2 54 -18 77 -26 37 -41 82 -57 172 l-13 68 -55 2 c-29 1 -70 -4 -89 -11z"/>
<path d="M95 670 c-14 -6 -5 -9 33 -9 28 -1 52 3 52 7 0 11 -60 12 -85 2z"/>
<path d="M185 578 c-5 -15 -32 -166 -40 -223 -14 -100 -42 -219 -53 -223 -23 -7 -11 -23 16 -20 22 2 26 7 24 27 -2 13 -1 39 3 58 6 33 7 34 48 31 41 -3 42 -4 49 -48 5 -28 4 -46 -2 -48 -23 -8 -7 -22 24 -22 l34 0 -4 54 c-4 49 -46 329 -60 399 -6 27 -31 37 -39 15z m40 -229 c18 -7 18 -8 2 -8 -16 -1 -17 -7 -11 -46 l6 -45 -40 0 -39 0 18 98 c10 53 19 104 20 112 1 8 7 -12 13 -44 8 -43 17 -61 31 -67z"/>
<path d="M358 577 c15 -18 18 -440 3 -445 -22 -7 -10 -22 17 -22 l27 0 -3 107 c-2 60 -8 111 -14 114 -7 4 -6 9 1 13 7 5 10 38 9 87 -1 63 3 83 16 98 28 31 13 61 -31 61 -28 0 -33 -3 -25 -13z"/>
<path d="M540 548 c-6 -24 -15 -59 -21 -78 -11 -44 -11 -50 0 -50 5 0 13 17 19 38 8 28 10 -4 11 -145 1 -116 -3 -183 -9 -183 -5 0 -10 -4 -10 -10 0 -5 18 -10 41 -10 34 0 40 3 35 16 -8 22 -8 426 0 447 5 14 0 17 -25 17 -27 0 -31 -4 -41 -42z"/>
<path d="M755 568 c-2 -13 -21 -113 -41 -223 -22 -126 -41 -204 -50 -211 -22 -18 -17 -24 21 -24 33 0 48 14 24 22 -7 2 -8 20 -4 51 l7 47 43 0 43 0 7 -47 c4 -31 3 -49 -4 -51 -28 -9 -6 -22 39 -22 46 0 67 13 38 22 -12 4 -48 189 -63 318 -11 100 -20 135 -37 138 -11 2 -19 -5 -23 -20z m24 -211 c16 -110 16 -107 -25 -107 l-37 0 7 43 c30 191 35 198 55 64z"/>
<path d="M920 580 c0 -5 5 -10 10 -10 6 0 10 -32 10 -76 0 -47 4 -73 10 -69 6 3 10 1 10 -4 0 -6 -4 -11 -10 -11 -6 0 -10 -53 -10 -140 0 -87 -4 -140 -10 -140 -5 0 -10 -4 -10 -10 0 -5 17 -10 38 -10 l37 0 -3 106 -4 106 29 -4 c28 -3 28 -3 33 -90 5 -87 23 -128 56 -128 21 0 28 22 10 32 -13 7 -16 25 -16 92 0 63 -4 87 -15 96 -9 7 -12 16 -7 19 27 19 42 60 42 113 0 73 -12 103 -49 123 -33 17 -151 21 -151 5z m134 -32 c11 -15 16 -46 16 -95 0 -60 -4 -77 -20 -93 -21 -21 -74 -27 -85 -10 -3 6 1 10 9 10 16 0 22 35 8 44 -5 3 -5 31 -1 63 4 32 8 68 8 81 1 30 44 30 65 0z"/>
<path d="M1170 581 c0 -6 5 -13 10 -16 7 -5 10 -83 9 -228 l-1 -222 31 -3 c30 -3 42 10 21 23 -6 4 -10 87 -10 215 0 128 4 211 10 215 22 13 8 25 -30 25 -22 0 -40 -4 -40 -9z"/>
<path d="M1290 580 c0 -5 5 -10 10 -10 6 0 10 -28 10 -63 0 -37 5 -69 13 -76 10 -11 10 -13 0 -7 -9 5 -13 -1 -13 -18 0 -25 0 -26 19 -7 12 12 21 40 25 87 4 38 10 77 12 87 5 14 -1 17 -35 17 -23 0 -41 -4 -41 -10z"/>
<path d="M1510 582 c0 -5 3 -12 7 -15 3 -4 6 -31 5 -60 0 -28 5 -64 13 -80 7 -15 10 -25 5 -22 -10 6 -19 -79 -19 -183 1 -46 -2 -86 -5 -89 -17 -18 2 -23 84 -23 l90 0 0 35 c0 40 -11 45 -25 13 -8 -18 -20 -24 -52 -26 l-43 -3 -1 128 c-2 200 2 308 12 311 23 9 5 22 -31 22 -22 0 -40 -4 -40 -8z"/>
<path d="M1783 580 c-12 -5 -29 -22 -37 -39 -20 -37 -21 -131 -3 -156 21 -27 29 -5 31 80 2 79 14 105 51 105 40 0 48 -23 49 -140 1 -61 4 -110 8 -110 3 0 14 3 23 6 13 5 14 8 3 15 -10 7 -10 9 0 9 6 0 12 11 12 25 0 16 -6 25 -15 25 -8 0 -15 5 -15 11 0 6 7 9 15 5 12 -4 15 4 15 44 0 61 -16 97 -51 116 -31 16 -54 17 -86 4z"/>
<path d="M422 480 c-11 -24 -9 -42 11 -121 30 -120 47 -128 71 -33 12 47 14 70 6 78 -7 7 -10 1 -10 -20 0 -64 -16 -50 -34 32 -11 46 -22 86 -25 89 -4 2 -12 -9 -19 -25z"/>
<path d="M1328 370 c-15 -14 -18 -35 -18 -123 0 -60 -4 -109 -10 -112 -27 -17 -2 -25 80 -25 l90 0 0 35 c0 40 -15 47 -24 10 -5 -21 -12 -25 -46 -25 l-40 0 -1 63 c-2 128 -4 152 -14 160 -6 4 -5 10 4 16 7 5 9 11 5 13 -5 3 -17 -2 -26 -12z"/>
<path d="M1732 264 c3 -102 4 -107 31 -130 37 -32 87 -32 123 0 24 20 28 32 32 90 4 66 4 66 -22 66 -25 0 -26 -3 -26 -53 0 -29 5 -58 11 -64 7 -7 3 -17 -17 -32 -29 -23 -42 -25 -62 -12 -17 10 -31 72 -32 133 0 27 -5 48 -12 51 -8 4 -7 6 4 6 30 2 6 50 -25 51 -5 0 -7 -48 -5 -106z"/>
<path d="M305 120 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0 -8 -4 -11 -10z"/>
</g>
        </svg>
      </div>
      <div style={{marginBottom: '20px'}}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="150.000000pt" height="38.000000pt" viewBox="0 0 300.000000 76.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1613 533 c-108 -215 -175 -313 -224 -328 -25 -8 -59 20 -59 47 0 52 68 124 144 153 55 21 65 50 14 40 -77 -16 -176 -96 -209 -169 -36 -80 -16 -136 50 -136 59 0 111 43 178 146 11 17 12 15 6 -11 -13 -53 -9 -107 8 -118 21 -13 59 -3 87 23 31 29 27 40 -13 33 -35 -6 -35 -5 -35 31 0 49 58 202 136 358 35 70 64 130 64 132 0 2 -9 6 -19 9 -16 4 -36 -28 -128 -210z"/>
<path d="M413 651 c-28 -9 -68 -30 -89 -46 l-37 -28 6 31 c6 29 4 32 -18 32 -21 0 -25 -7 -36 -57 -11 -48 -22 -68 -70 -120 -120 -130 -172 -313 -88 -313 32 0 69 42 108 122 43 87 41 87 56 -6 19 -116 85 -216 144 -216 24 0 31 4 31 20 0 12 -10 25 -25 32 -34 15 -61 61 -80 133 -24 96 -21 112 23 126 54 16 125 70 163 125 40 59 51 130 23 155 -31 27 -56 29 -111 10z m43 -75 c18 -72 -72 -172 -176 -195 -24 -5 -46 -9 -47 -7 -2 1 4 20 12 42 8 21 19 58 25 82 9 36 18 46 63 72 66 38 114 40 123 6z m-266 -188 c-34 -94 -68 -158 -85 -158 -22 0 -18 34 11 93 34 66 84 140 91 133 3 -3 -4 -34 -17 -68z"/>
<path d="M2578 578 c-12 -24 -32 -64 -44 -89 -20 -43 -25 -46 -78 -58 -44 -10 -56 -16 -52 -28 3 -8 6 -16 6 -18 0 -2 16 1 36 7 20 5 38 8 40 6 2 -2 -5 -27 -16 -55 -10 -28 -22 -73 -26 -101 -5 -43 -3 -53 16 -72 26 -26 73 -23 114 7 45 33 45 76 1 47 -16 -10 -34 -14 -50 -10 -21 5 -25 12 -25 45 0 22 9 64 21 94 22 58 37 67 111 67 23 0 29 4 26 18 -3 13 -13 17 -51 15 l-46 -1 39 78 c21 43 36 80 33 84 -16 15 -34 3 -55 -36z"/>
<path d="M1182 568 c-7 -7 -12 -25 -12 -40 0 -21 6 -29 26 -34 28 -7 38 0 48 32 12 38 -35 69 -62 42z"/>
<path d="M892 453 c-50 -24 -52 -55 -9 -135 39 -71 45 -96 25 -116 -19 -19 -50 -14 -79 14 -17 16 -34 24 -43 21 -46 -18 22 -87 85 -87 106 0 137 67 79 165 -49 83 -38 115 42 115 41 0 48 3 48 20 0 18 -7 20 -57 20 -33 0 -72 -8 -91 -17z"/>
<path d="M2086 398 c-30 -85 -67 -228 -61 -239 3 -3 14 -9 25 -12 18 -4 25 3 44 48 34 75 91 161 120 180 33 22 66 12 66 -20 0 -12 -14 -53 -31 -89 -30 -63 -30 -68 -15 -91 23 -35 66 -33 111 6 34 30 46 59 25 59 -6 0 -20 -9 -32 -19 -24 -23 -48 -17 -48 11 0 11 14 50 30 88 44 100 38 130 -25 130 -46 0 -110 -51 -158 -126 -22 -35 -42 -62 -44 -61 -1 2 11 46 27 98 35 112 35 109 11 109 -14 0 -25 -17 -45 -72z"/>
<path d="M2787 454 c-51 -28 -52 -56 -5 -144 42 -77 43 -78 24 -99 -25 -28 -48 -26 -84 4 -18 15 -37 23 -46 19 -23 -9 -20 -18 19 -53 50 -43 114 -44 156 -2 38 38 37 59 -6 135 -19 35 -35 68 -35 74 0 24 33 42 75 42 39 0 55 11 55 36 0 11 -128 1 -153 -12z"/>
<path d="M677 446 c-52 -14 -104 -52 -151 -109 -22 -28 -30 -50 -34 -90 -4 -49 -2 -56 23 -75 49 -39 147 -21 199 37 29 32 11 37 -38 13 -53 -27 -67 -27 -97 -4 l-22 19 59 27 c80 37 133 74 150 107 21 42 18 68 -12 79 -14 6 -26 10 -27 9 -1 0 -24 -6 -50 -13z m43 -60 c0 -16 -11 -33 -32 -49 -44 -33 -119 -70 -126 -62 -10 10 30 74 65 106 44 38 93 41 93 5z"/>
<path d="M1907 446 c-52 -14 -104 -52 -151 -109 -22 -28 -30 -50 -34 -90 -4 -49 -2 -56 23 -75 49 -39 147 -21 199 37 28 31 13 36 -37 12 -52 -25 -66 -26 -95 -5 -26 18 -29 34 -7 34 19 0 108 45 148 74 58 42 75 110 31 126 -14 6 -26 10 -27 9 -1 0 -24 -6 -50 -13z m43 -61 c0 -18 -12 -34 -42 -56 -48 -34 -108 -62 -117 -54 -9 10 32 75 66 106 44 38 93 41 93 4z"/>
<path d="M1115 392 c-41 -87 -61 -175 -49 -209 7 -22 17 -29 41 -31 26 -3 39 4 73 38 30 30 39 45 31 53 -8 8 -18 4 -35 -12 -29 -27 -60 -24 -64 6 -2 12 12 61 31 108 32 80 33 86 16 95 -15 9 -21 2 -44 -48z"/>
</g>
        </svg>
      </div>
    </div>
      : <>
      
      <div style={styles.container}>
        <h1 style={styles.heading}>
        {displayDate.split('-')[1]}/{displayDate.split('-')[2]}/{displayDate.split('-')[0]} to Present
        </h1>
        <div style={styles.statsContainer}>
          {lectureCounts.map((item, index) => <div key={index} style={styles.stat}>
            <h2 style={styles.statLabel}>{item.LectureType}</h2>
            <p style={styles.statValue}>{item.lectureTypeCount}</p>
          </div>)}
          <div style={styles.stat}>
            <h2 style={styles.statLabel}>Total Attendance</h2>
            <p style={styles.statValue}>{nameCountDisplay}</p>
          </div>
        </div>
        <br/>
        <div style={styles.statsContainer}>
        <div style={styles.stat}>
  <h2 style={styles.statLabel}>Lecture Attendance Percentage</h2>
  <p style={styles.statValue}>{(nameCountDisplay * 100 / worksheetDataLength).toFixed(1)}%</p>
</div>
        </div>
        <div style={{padding: '20px'}}>
        <label>
          Note that this is your QR scanning activity. The percentage is a rough estimate of your current attendance and does not take into account your vacations.
        </label>
        </div>
      </div>
       </>}
    </Fragment>
  );
};



const styles = {
    container: {
      textAlign: 'center',
      marginTop: '20px',
    },
    heading: {
      fontSize: '1.5rem',
      margin: '20px',
      padding: '5px', // Add padding for space around the text
      color: '#fff', // White text color
      backgroundColor: '#343a40', // Gray background color
      textTransform: 'uppercase',
      letterSpacing: '2px',
      lineHeight: '1.5',
      fontFamily: 'Montserrat, sans-serif', // Modern sans-serif font
      //fontWeight: 'bold', // Make the text bold
      textAlign: 'center',
      borderRadius: '8px', // Add a slight border-radius for a modern touch
    }
    ,
    statsContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap', // Allow flex items to wrap to the next line on small screens
    },
    stat: {
      flex: 1,
      padding: '15px',
      borderRadius: '8px',
      backgroundColor: '#f7f7f7', // Customize background color
      margin: '10px',
      minWidth: '200px', // Set a minimum width for each box
    },
    statLabel: {
      fontSize: '1rem',
      marginBottom: '5px',
      color: '#555', // Customize label color
      margin: '0',
    },
    statValue: {
      fontSize: '1.5rem',
      margin: 0,
      color: '#000', // Customize value color
    },
  };

function mapStateToProps(state) {
  return { khara: state };
}

const ConnectedNewComponent = connect(mapStateToProps)(NewComponent);

export default ConnectedNewComponent;
