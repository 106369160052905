import React, { Fragment, useState, useEffect } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { putContact } from '../actions/addContact';
import { checkContactAvailable } from '../actions/getContact';
import Alerter from './alerter';

const Tab2 = props => {

  const [NumberName, setNumberName] =  useState('');
  const [NumberHospital, setNumberHospital] =  useState([]);
  const [NWTH, setNWTH] =  useState(false);
  const [VA, setVA] =  useState(false);
  const [BSA, setBSA] =  useState(false);
  const [NumberPosition, setNumberPosition] =  useState('');
  const [NumberMedicalGroup, setNumberMedicalGroup] =  useState('');
  const [NumberDepartment, setNumberDepartment] =  useState('');
  const [NumberSubspecialty, setNumberSubspecialty] =  useState('');
  const [PersonalNumber, setPersonalNumber] =  useState(null);
  const [PagerNumber, setPagerNumber] =  useState(null);
  const [OfficeNumber, setOfficeNumber] =  useState(null);
  const [Extension, setExtension] =  useState(null);
  const [NumberNPName, setNumberNPName] =  useState('');
  const [NumberNPNumber, setNumberNPNumber] =  useState('');
  const [Email, setEmail] =  useState('');
  const [suggestionState, setSuggestionState] = useState(<></>);
  const [allContacts, setAllContacts] = useState("");
  const [alerter, setAlerter] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [functionFired, setFunctionfired] = useState(false);
  const [availableContacts, setAvailableContacts] = useState([]);
  const[firstInitiation, setFirstInitiation] = useState(true);

  useEffect(() => {
    setAvailableContacts([]);
    if(props.checkedContacts){props.checkedContacts.forEach(item => {
      setAvailableContacts([...availableContacts, item.NumberName]);
    })}
    returnSuggestions(availableContacts);
  },[props.checkedContacts])

  useEffect(()=>{
    if(functionFired && !firstInitiation){
    if(successfulUpload)
    {
      setAlerter(true);
      setTimeout(() => {
        setAlerter(false);
      }, 2000)
      clearStates();

    } 
      
      else 
      {
        alert("Error uploading document")
    }
  }
    setFirstInitiation(false);
  }, [functionFired])

const clearStates = () => {
  setNumberName('');
  setNumberHospital([]);
  setNWTH(false);
  setVA(false);
  setBSA(false);
  document.getElementById("NWTHCheck").checked = false;
  document.getElementById("VACheck").checked = false;
  document.getElementById("BSACheck").checked = false;
  setNumberPosition('');
  setNumberMedicalGroup('');
  setNumberDepartment('');
  setNumberSubspecialty('');
  setPersonalNumber('');
  setPagerNumber('');
  setOfficeNumber('');
  setExtension('');
  setNumberNPName('');
  setNumberNPNumber('');
  setEmail('');
  setSuggestionState(<></>);
  setAvailableContacts([]);
  setFunctionfired(false);
  setSuccessfulUpload(false);
}

  const confirmIfAddAvailable = (name) => {
    if(name.length > 2) {
      props.dispatch(checkContactAvailable(name));
    }
  
  }

  const flipHospitalRegistration = (hospitalName) => {
    if(hospitalName === "NWTH") {setNWTH(!NWTH)}
    else if(hospitalName === "VA") {setVA(!VA)}
    else if(hospitalName === "BSA") {setBSA(!BSA)}
  }

  const returnHospitalArray = () => {
    if(NWTH && !VA && !BSA) {setNumberHospital(["NWTH"])}
    else if(NWTH && VA && !BSA) {setNumberHospital(["NWTH", "VA"])}
    else if(NWTH && VA && BSA) {setNumberHospital(["NWTH", "VA", "BSA"])}
    else if(!NWTH && VA && !BSA) {setNumberHospital(["VA"])}
    else if(!NWTH && VA && BSA) {setNumberHospital(["VA", "BSA"])}
    else if(!NWTH && !VA && BSA) {setNumberHospital(["BSA"])}
    else if(NWTH && !VA && BSA) {setNumberHospital(["NWTH", "BSA"])}
  }

  const returnSuggestions = (arrayRequired) => {
    var concatList = <></>;
    arrayRequired.forEach(item => {
      concatList = <>{concatList}<div style={{backgroundColor: "#dc3545", width: '100vw', textAlign: 'center', padding: '10px'}}><img style={{width: '50px'}} src={require('../media/alert.png')}></img><label style={{color: 'white'}}>Are you trying to add <h4 style={{display: 'inline'}}>{item}</h4>, contact already available.</label></div></>
    })
    setSuggestionState(concatList);
  }

  const returnError = (error) => {
    setSuggestionState(
      <div style={{backgroundColor: "#dc3545", width: '100vw', textAlign: 'center', padding: '10px'}}>
        <img style={{width: '50px'}} src={require('../media/alert.png')}></img>
        <label style={{color: 'white'}}>Caution! <h4 style={{display: 'inline'}}>{error}</h4></label>
        </div>
    )
  }

  const uploadContact = () => {
    if(NumberName !== '' && NumberHospital.length !== 0 && NumberDepartment !== '' && NumberPosition !== ''){
    props.dispatch(putContact({
      NumberName: NumberName,
      NumberHospital: NumberHospital,
      NumberPosition: NumberPosition,
      NumberMedicalGroup: NumberMedicalGroup,
      NumberDepartment: NumberDepartment,
      NumberSubspecialty: NumberSubspecialty,
      PersonalNumber: PersonalNumber,
      PagerNumber: PagerNumber,
      OfficeNumber: OfficeNumber,
      Extension: Extension,
      NumberNPName: NumberNPName,
      NumberNPNumber: NumberNPNumber,
      Email: Email,
      })).then((res) => {
        setSuccessfulUpload(res.payload.success);
        setFunctionfired(true);
      },)
    }
    else if(NumberName === ''){
      returnError('Name cannot be left empty')
    }
    else if(NumberPosition === ''){
      returnError('Position cannot be left empty')
    }
    else if(NumberHospital.length === 0){
      returnError('Please select a hospital')
    }
    else if(NumberDepartment === ''){
      returnError('Department cannot be left empty')
    }

  }

    return(
        <Fragment>
        {alerter ? <Alerter props={{success: true}}/> : null}

        <div>
        <br/>
        <br/>
        <div style={{width: '100vw', textAlign: 'center'}}>
        <img src={require('../media/testtube.png')} width='100px'></img>
        <br/>
        <label className="badge badge-danger">Add contact information</label>
        </div>
        <div style={{textAlign: 'center', padding: '20px'}}>
          <label style={{width: '250px', textAlign: 'left'}}>Name: </label><input style={{width: '250px'}} value={NumberName} onChange={(event) => {setNumberName(event.target.value); }} onBlur={(event) => confirmIfAddAvailable(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Position: </label><input placeholder="Position" style={{width: '250px'}} value={NumberPosition} onChange={(event) => {setNumberPosition(event.target.value); }}></input>
          <br/>
          <div onChange={(event) => flipHospitalRegistration(event.target.value)} style={{textAlign: '-webkit-center'}}>
          <div style={{width: '345px'}} onBlur={() => {returnHospitalArray()}}>
          <input id="NWTHCheck" type="checkbox" value="NWTH" name="hospital" style={{width: '75px'}}/> <img src={require('../media/NWTH.png')} style={{height: '25px', width: '25px'}}/>
          <input id="VACheck" type="checkbox" value="VA" name="hospital" style={{width: '75px'}}/> <img src={require('../media/va.png')} style={{height: '25px', width: '25px'}}/>
          <input id="BSACheck" type="checkbox" value="BSA" name="hospital" style={{width: '75px'}}/> <img src={require('../media/BSA.png')} style={{height: '25px'}}/>
          </div>
          </div>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Medical Group Name: </label><input style={{width: '250px'}} placeholder="Amarillo Heart group" value={NumberMedicalGroup || ""} onChange={(event) => setNumberMedicalGroup(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Department: </label><input style={{width: '250px'}} placeholder="Internal Medicine" value={NumberDepartment || ""} onChange={(event) => setNumberDepartment(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Subspecialty: </label><input style={{width: '250px'}} placeholder="Cardiology" value={NumberSubspecialty || ""} onChange={(event) => setNumberSubspecialty(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Personal Number: </label><input style={{width: '250px'}} value={PersonalNumber || ""} onChange={(event) => setPersonalNumber(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Pager Number: </label><input style={{width: '250px'}} value={PagerNumber || ""} onChange={(event) => setPagerNumber(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Office Number: </label><input style={{width: '250px'}} value={OfficeNumber || ""} onChange={(event) => setOfficeNumber(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Extension Number: </label><input style={{width: '250px'}} placeholder="Four digit extension number" value={Extension || ""} onChange={(event) => setExtension(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Email: </label><input style={{width: '250px'}} value={Email || ""} onChange={(event) => setEmail(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Nurse practitioner's Name: </label><input style={{width: '250px'}} value={NumberNPName || ""} onChange={(event) => setNumberNPName(event.target.value)}></input>
          <br/>
          <label style={{width: '250px', textAlign: 'left'}}>Nurse practitioner's Number: </label><input style={{width: '250px'}} value={NumberNPNumber || ""} onChange={(event) => setNumberNPNumber(event.target.value)}></input>
          
          
        </div>
        {availableContacts.length === 0 ? suggestionState : null}
        <button style={{marginLeft: '50vw', borderRadius: '15px', backgroundColor: '#dc3545', color: 'white', borderColor: '#dc3545', borderStyle: 'solid', padding: '10px', transform: 'translateX(-50%)', marginTop: '20px'}} 
        onClick={uploadContact}>Upload Contact Info</button>
        <br/>
        <br/>
            </div>
    </Fragment>
        
    )
}

  const mapStateToProps = state => { 
    let successfulUpload = false;
    let functionFired = false
    if(state && state.updateContact && state.updateContact && state.updateContact.uplaodContact && state.updateContact.uplaodContact.success){successfulUpload = state.updateContact.uplaodContact.success; functionFired = true}
    return { checkedContacts: state.contactsReducer.checkedContacts, successfulUpload: successfulUpload, functionFired: functionFired }};
  

  // function mapDispatchToProps(dispatch){
  //   return bindActionCreators({checkContactAvailable},dispatch);
  // }
  
  const Container2 = connect(mapStateToProps)(Tab2);




export default Container2;
