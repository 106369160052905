import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const Alerter = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if(props?.message){setSuccess(props.success); setMessage(props.message || "")}
    setShowModal(true);
  }, [props]);

  const handleClose = () => {
    if(props?.closeAlerter){props.closeAlerter()}
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose} style={{zIndex: '999999999999999999999'}} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {success && 'Success'}
          {!success && 'Error'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Alerter;
