import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { getTokens } from '../utils/misc';
// import SignIn from '../auth';
import SignIn from '../auth';
import App from '../App2';
import { autoSignIn, Signout } from '../actions';
import { bindActionCreators } from 'redux';
import Loading from '../Loading';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';


const Security = props => {

    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState(true);
    const [modifier, setModifier] = useState([["0", null],["0", null],["0", null],["0", null],["0", null],["0", null]]);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasErrors, setHasErrors] = useState(false);

    
    useEffect(()=>{
        let isSubscribed = true;
        //setLoading(true);
        TokencheckLogic().then(val => {if(isSubscribed){
            //setLoading(false);
        }});
        

        return () => isSubscribed = false;
    },[window.location.pathname])

    useEffect(()=>{
        let isSubscribed = true;
        //if(isSubscribed){setLoading(true)};
        if(isSubscribed) {Logic(modifier).then(val => {if(isSubscribed){setLogin(val)}});}

        return () => isSubscribed = false;
    },[modifier]);

    const TokencheckLogic = () =>{

        const LoginToken = new Promise((resolve, reject)=>{
            try{
            getTokens((value)=>{setModifier(value); resolve(value);})
            }
            catch(err){reject(err)}
        }) 
        return LoginToken;
    }

    const Logic = (value) =>{
        const Logicret = new Promise((resolve, reject) => {
            if(value[0][1] === null || value[0][1] === "null"){
                resolve(true); 
                setLoading(false);
                setLogin(true); 
            }
            else { 
                props.autoSignIn(value[1][1]).then((vall)=>{
                if(typeof vall === "undefined" || vall.payload.success === false || vall === null){
                    if(vall && vall.payload && vall.payload.error && vall.payload.success === false)
                    {setHasErrors(true); setErrorMessage(vall.payload.error)}
                    else {setHasErrors(true); setErrorMessage("Unknown Error")}
                    Signout();
                    resolve(true);
                    setLogin(true);
                    setLoading(false);
                }
                else{
                    setLogin(false);
                    resolve(false);   
                    setLoading(false);
                    }
        });
    }});
        return Logicret;
    }


    const returnfalse = () => {
        setLogin(false)
    };

    if(loading !== true)
    {
     
    if(login !== false) {
        return(
            <OnBoot>
        <Router>
            <App>
            <Switch>
            <Route exact path="/logout" render={(props) => (<SignIn allowentry={returnfalse} {...props}/>)}/> 
            <Route path="/" render={(props) => (<SignIn allowentry={returnfalse} errorrmessage={errorMessage} {...props}/>)}/> 
            </Switch>
            </App>
         </Router>
            </OnBoot>
            )
    }
    else {return(props.children)}
}
else {return(<App><Loading/></App>)}

}


const mapStateToProps = (state) => {
    return {Authentication: state}
  }

  function mapDispatchToProps(dispatch){
    return bindActionCreators({autoSignIn, Signout},dispatch);
  }
  
  const Container = connect(mapStateToProps, mapDispatchToProps)(Security);


export default Container;


const OnBoot = props => {

    const[boot, setBoot] = useState(true);

    useEffect(() => {
        let isSubscribed = true;
        setInterval(() => {if(isSubscribed){setBoot(false)}}, 2000);

        return () => {clearInterval(); isSubscribed = false;}
    }, [])

    if(boot){
        return(
            <App><Loading></Loading></App>
        )
    }
    else{
        return(
            <>{props.children}</>
        )
    }
}
