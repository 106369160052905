
import { combineReducers } from 'redux';




function Userfunc(state={},action){
    switch(action.type){
        case 'SIGN_IN':
            return {
                ...state,
                auth:{
                    Verified: action.payload.emailVerified || false, 
                    uid:action.payload.localId || false,
                    token:action.payload.idToken || false,
                    refToken: action.payload.refreshToken || false,
                    SessionCode: action.payload.SessionCode || undefined,
                }
            }
        case 'SIGN_UP':
            return {
                ...state,
                auth:{
                    Paid:  action.payload.Paid, 
                    Verified: action.payload.Verified, 
                   uid:action.payload.localId || false,
                   token:action.payload.idToken || false,
                   refToken: action.payload.refreshToken || false,
                   err:  action.payload.error || undefined, 
                }
            }
            case 'VERIFY':
                return {
                    ...state,
                    auth:{
                        kind: action.payload.kind || false,
                        email: action.payload.email || false
                    }
                }
                case 'CONFIRMVERIFY':
                    return {
                        ...state,
                        auth:{
                            emailVerified: action.payload.emailVerified || false
                        }
                    }
                case 'FORGOT':
                    return {
                        ...state,
                        auth:{
                            kind: action.payload.kind || false,
                            email: action.payload.email || false
                        }
                    }
                case 'CHANGE_PASSWORD':
                        return {
                            ...state,
                            auth:{
                                requestType: action.payload.requestType || false,
                                email: action.payload.email || false,
                                emailVerified: action.payload.emailVerified || false,
                            }
                        }
                case 'GETACCOUNTINFO':
                        return {
                            ...state,
                            auth:{
                                kind: action.payload.kind || false,
                                users: action.payload.users || false
                            }
                        }
        case 'AUTO_SIGN_IN':
            return {
                ...state,
                auth:{
                    Paid:  action.payload.Paid, 
                    Verified: action.payload.Verified,  
                    uid:action.payload.user_id || false,
                    token:action.payload.id_token || false,
                    refToken: action.payload.refresh_token || false,
                    error: action.payload.error || "No errors detected"
                }
            }
        case 'SIGN_OUT':
                return {
                    ...state,
                    auth:{
                        Paid: undefined, 
                        Verified: undefined,  
                        uid: false,
                        token: false,
                        refToken: false,
                        error: "Signed Out"
                    }
                }
                case 'KICK_OUT':
                        return {
                            ...state,
                            auth:{
                                Paid: undefined, 
                                Verified: undefined,  
                                uid: false,
                                token: false,
                                refToken: false,
                                error: "Another user has logged in with your credentials on another tab or another device. Only one session for a user is allowed"
                            }
                        }
                        case 'RESEND_VERIFICATION':
                            return{...state, auth : action.payload}
        default:
            return state
    }
}

const putContact = (state = {}, action) => {

    switch(action.type){
        case 'SET_CONTACT':
            return{...state, uplaodContact : action.payload}
            case 'DELETE_COMPLAIN':
                return{...state, uplaodContact : action.payload}
                case 'DELETE_ATTENDANCE':
                    return{...state, uplaodContact : action.payload}
                    case 'DELETE_ALLATTENDANCE':
                        return{...state, uplaodContact : action.payload}
                        case 'DELETE_ALLHANDOFFS':
                        return{...state, uplaodContact : action.payload}
                        case 'DELETE_ADMIN':
                        return{...state, uplaodContact : action.payload}
                        case 'DELETE_RESIDENT':
                        return{...state, uplaodContact : action.payload}
                        case 'SET_RULES':
                        return{...state, uplaodContact : action.payload}
                        case 'SET_BLOCKSTARTDATE':
                            return{...state, uplaodContact : action.payload}
                            case 'DELETE_QRCODES':
                                return{...state, uplaodContact : action.payload}
        default:
            return{state}   
            
    }
}


const updateContact = (state = {}, action) => {

    switch(action.type){
        case 'UPDATE_CONTACT':
            return{...state, uplaodContact : action.payload}
        default:
            return{state}   
            
    }
}

const submitComplaint = (state = {}, action) => {

    switch(action.type){
        case 'SUBMIT_COMPLAINT':
            return{...state, uplaodContact : action.payload}
            case 'SUBMIT_ADMIN':
                return{...state, uplaodContact : action.payload}
                case 'SUBMIT_RESIDENT':
                return{...state, uplaodContact : action.payload}
        default:
            return{state}   
            
    }
}

const submitAttendance = (state = {}, action) => {

    switch(action.type){
        case 'SUBMIT_ATTENDANCE':
            return{...state, uploadAttendance : action.payload}
        default:
            return{state}   
            
    }
}

const submitHandoff = (state = {}, action) => {

    switch(action.type){
        case 'SUBMIT_HANDOFF':
            return{...state, uploadAttendance : action.payload}
        default:
            return{state}   
            
    }
}

const contactsReducer = (state = {}, action) => {
    switch(action.type){
        case 'GET_ALLCONTACTS':
            return{...state, allContacts : action.payload}
        case 'GET_CHKCONTACT':
            return{...state, checkedContacts : action.payload}
            case 'GET_ALLCOMPLAINS':
            return{...state, checkedContacts : action.payload}
            case 'GET_ALLHANDOFFS':
            return{...state, checkedContacts : action.payload}
            case 'GET_ALLADMINISTRATORS':
            return{...state, checkedContacts : action.payload}
            case 'GET_ALLRESIDENTTYPES':
            return{...state, checkedContacts : action.payload}
            case 'GET_RESIDENTTYPE':
            return{...state, checkedContacts : action.payload}
            case 'GET_ATTENDANCES':
            return{...state, checkedContacts : action.payload}
            case 'GET_USERATTENDANCES':
            return{...state, checkedContacts : action.payload}
            case 'GET_TIME':
            return{...state, checkedContacts : action.payload}
            case 'GET_PROBLEMRESIDENT':
                return{...state, checkedContacts : action.payload}
                case 'GET_RULES':
                return{...state, checkedContacts : action.payload}
                case 'SUBMIT_PROBLEMRESIDENT':
                    return{...state, checkedContacts : action.payload}
                    case 'SET_LECTURETYPE':
                        return{...state, checkedContacts : action.payload}
                        case 'DELTE_LECTURETYPE':
                            return{...state, checkedContacts : action.payload}
                            case 'GET_LECTURETYPE':
                                return{...state, checkedContacts : action.payload}
                                case 'GET_BLOCKSTARTDATE':
                                    return{...state, checkedContacts : action.payload} 
                                    case 'GET_BLOCKED':
                                    return{...state, checkedContacts : action.payload} 
                                    case 'SET_QRCODE':
                                        return{...state, checkedContacts : action.payload}
                                        case 'GET_QRCODE':
                                        return{...state, checkedContacts : action.payload}
                                        case 'SET_MANYQRCODE':
                                        return{...state, checkedContacts : action.payload}
                                        case 'SET_BLOCKEDRESIDENTS':
                                            return{...state, checkedContacts : action.payload}
                                            case 'SET_TIMEZONE':
                                            return{...state, checkedContacts : action.payload}
                                            case 'GET_TIMEZONE':
                                            return{...state, checkedContacts : action.payload}
        default:
            return{state}   
            
    }
}

export default combineReducers({
    Userfunc,
    putContact,
    contactsReducer,
    updateContact,
    submitComplaint,
    submitHandoff,
    submitAttendance
  });
