import React, { Fragment, useState, useEffect } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
import { getTokens } from '../utils/misc';
import { getProblemResident } from '../actions/getContact';

var CryptoJS = require("crypto-js");



const Problem = (props) => {

    const [user, setUser] = useState("");
    const [QRString, setQRString] = useState('');
    const [clicked, setClicked] = useState(false);
    const [codeInsert, setCodeInsert] = useState(<></>);
    const [problemArray, setProblemArray] = useState([])
    const [componentMounted, setComponentMounted] = useState(false);
    let iter = 0;

    useEffect(()=>{setComponentMounted(true); return(()=> setComponentMounted(false))},[])

    useEffect(() => {
      if(componentMounted){
      getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})
      props.dispatch(getProblemResident()).then(res => 
        {
          if(componentMounted){setProblemArray(res.payload)}
        })

      }
    }, [componentMounted])

    useEffect(() => {
        if(user !== ""){
            if(problemArray.filter((obj) => obj.user === user).length > 0 && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) === true){
                setCodeInsert(<button id="QRremovalButton" className="btn btn-dark" onClick={() => setClicked(true)}>Having trouble generating code?</button>)
            }
        }
      },[problemArray])

      useEffect(() => {
        if (clicked && iter === 0) {
          iter++;
          document.getElementById('QRcode').style.display = "none";
          document.getElementById('movementChoice').style.display = "none";
          setCodeInsert(<label>Wait while we generate the QR code</label>)
            setQRString(
              CryptoJS.AES.encrypt(
                JSON.stringify(
                  user +
                    "," +
                    new Date() +
                    "," +
                    "32.792792792792795" +
                    "$" +
                    "-96.7951545261161" +
                    "$" +
                    "" +
                    "$" +
                    "2000" +
                    ",^" +
                    "handoff"
                ),
                "shit"
              ).toString()
            );
 
        }
      }, [clicked]);
      

useEffect(() => {
    setCodeInsert(<>
        <div style={{ textAlign: 'center', margin: '20px' }}>
      <div
        id='QRcode'
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px'
        }}
      >
      <br/>
  
      {user !="" && QRString !== "" ? <QRCode id='QRCodeprintable' value={QRString} /> : <></>}
      <br/>
      </div>
        </div>
    <br/>
    <br/>
        </>)
}, [QRString])


    return(

        <Fragment>
            {codeInsert}
      </Fragment>
    )


}

function mapStateToProps(state) {
  return { khara: state }
}


   const Container = connect(mapStateToProps)(Problem);


export default Container;
