import React, { Fragment, useEffect, useState } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';
import { getLectureType, addLectureType, getAllResidentTypes, getBlocked, saveblockedusers, savetimezone, getTimeZone } from '../actions';
import { deleteLectureType } from '../actions';
import Alerter from './AlerterwithMessage';



const QRgenerator = (props) => {
    const [lectureTypes, setLectureTypes] = useState([]);
    const [Admins, setAdmin] = useState([]);
    const [user, setUser] = useState('');
    const [Department, setDepartment] = useState('');
    const [editing, setEditing] = useState(false);
    const [proposedLectureTitle, setProposedLectureTitle] = useState('');
    const [proposedStartTime, setProposedStartTime] = useState('');
    const [proposedEndTime, setProposedEndTime] = useState('');
    const [alerter, setAlerter] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [selectedLectureType, setSelectedLectureType] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUsersByLectureType, setSelectedUsersByLectureType] = useState({});
    const [selectedTimeZone, setSelectedTimeZone] = useState('UTC');
    const [timeZones, setTimeZones] = useState(['US/Alaska', 'US/Aleutian', 'US/Arizona', 'US/Central', 'US/East-Indiana', 'US/Eastern', 'US/Hawaii', 'US/Indiana-Starke', 'US/Michigan', 'US/Mountain', 'US/Pacific', 'US/Samoa', 'UTC']);
 

const currentTime = new Date().toLocaleString("en-US", { timeZone: selectedTimeZone });

    useEffect(() => {
      getDepartment(val => {
        setDepartment(val); 
        getAdmins(val => setAdmin(val)); 
        props.dispatch(getLectureType(val)).then(value => 
        {
          setLectureTypes(value.payload);
          props.dispatch(getAllResidentTypes(val)).then(res => {setUsersList(res.payload)})})
          props.dispatch(getBlocked(val)).then(blockedpeople => {
            blockedpeople.payload.forEach(element => {
              setSelectedUsersByLectureType(prevState => ({
                ...prevState,
                [element.lectureType]: element.users
            }));
            });
          })
        }); 
      getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})

      return(() => {})
      
    }, [])

    useEffect(()=>{
      props.dispatch(getTimeZone(Department)).then(val => setSelectedTimeZone(val?.payload[0]?.timezone))
    },[Department])

    const handleAddLectureType = () => {
      if(lectureTypes.filter(obj => obj.LectureType === proposedLectureTitle).length > 0){triggerAlert('Cannot add same lecture type twice', false) }
      else if(proposedLectureTitle !== '' && proposedStartTime.split(':').length === 2 && proposedEndTime.split(':').length === 2)
      {
        props.dispatch(addLectureType({department: Department, LectureDefaultStartTime: proposedStartTime, LectureType: proposedLectureTitle, LectureDefaultEndTime: proposedEndTime}))
        .then(result => props.dispatch(getLectureType(Department)).then(value => 
          {
            triggerAlert('Added Succesfully', true)
            setLectureTypes(value.payload)
          }))
      }
      else{triggerAlert('Please make sure to fill all the requested input', false)}
    }

    const handleHidePanel = () => {
      setEditing(false)
    }

    const triggerAlert = (message, success) => {
      setAlerter(true);
      setMessage(message)
      setSuccess(success)
      //setTimeout(function(){ setAlerter(false); setMessage('') }, 10000);
}



const closeAlerter = () => {setAlerter(false); setMessage('')}

const handleSelectLectureType = (lectureType) => {
  setSelectedLectureType(lectureType.LectureType);
  setShowModal(true);
  // Retrieve selected users for the selected lecture type  
  const selectedUsersForLectureType = selectedUsersByLectureType[lectureType.LectureType] || [];
  console.log(selectedUsersByLectureType[lectureType.LectureType], lectureType.LectureType, selectedUsersByLectureType)
  setSelectedUsers(selectedUsersForLectureType);
};

const handleSelectUser = (username) => {
  if (selectedUsers.includes(username)) {
      setSelectedUsers(selectedUsers.filter(user => user !== username));
  } else {
      setSelectedUsers([...selectedUsers, username]);
  }
};

const handleCloseModal = () => {
  handleSaveSelection();
  setShowModal(false);
};

const handleSaveSelection = () => {
  // Update selected users for the selected lecture type
  setSelectedUsersByLectureType(prevState => ({
      ...prevState,
      [selectedLectureType]: selectedUsers
  }));
  props.dispatch(saveblockedusers(Department, selectedLectureType, selectedUsers))
  setShowModal(false);
};

const UserSelectionModal = () => {
  return (
    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '5px', maxWidth: '80vw', maxHeight: '80vh', overflowY: 'auto', position: 'relative' }}>
        <h3>Block Users:</h3>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {usersList.map(user => (
            <li key={user._id} style={{ marginBottom: '10px', cursor: 'pointer', textDecoration: selectedUsers.includes(user.user) ? 'line-through' : 'none', color: selectedUsers.includes(user.user) ? 'red' : 'black', borderRadius: '5px', padding: '10px' }} onClick={() => handleSelectUser(user.user)}>
              {user.user} {selectedUsers.includes(user.user) ? '(Selected)' : ''}
            </li>
          ))}
        </ul>
      </div>
      <button style={{ position: 'absolute', bottom: '20px', backgroundColor: '#4caf50', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }} onClick={handleCloseModal}>Close</button>
    </div>
  );
}

const handleTimeZoneChange = (event) => {
  setSelectedTimeZone(event.target.value);
};

const handleSaveToDatabase = () => {
  props.dispatch(savetimezone(Department, selectedTimeZone)).then(val => {if(val?.payload[0]?.department){triggerAlert('Time zone saved successfully', true)} else {triggerAlert('upload failed', false)}})
};
 
    if(Admins.filter(obj => obj.user === user ).length > 0)
    {

    return(

        <Fragment>
          {alerter ? <Alerter success={success} failed={!success} message={message} closeAlerter={closeAlerter}/> : null}
                <div style={{width: '100vw', textAlign: 'center'}}>
      <h3>QR Code Setting Manager</h3>
      </div>
        {Admins.filter(obj => obj.user === user).length > 0 ?
          <>
        <br/>
        <br/>
        <div style={containerStyle}>
      <label htmlFor="timeZone">Select Time Zone:</label>
      <select id="timeZone" style={selectStyle} value={selectedTimeZone} onChange={handleTimeZoneChange}>
        {timeZones.map((zone) => (
          <option key={zone} value={zone}>{zone}</option>
        ))}
      </select>
      <label>{currentTime}</label>
      <button
        className='btn btn-dark'
        onClick={handleSaveToDatabase}
      >
        Save My default Timezone
      </button>
    </div>
    <br/>

        {editing ? <>
          <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', textAlign: 'center' }}>
      <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Edit Lecture Types</h2>
      <div style={{width: '100%', textAlign: 'center' }}>
      <ul style={{ listStyle: 'none', padding: 0, display: 'inline-block', paddingLeft: '5px' }}>
        {lectureTypes.length > 0 ? lectureTypes.map(type => (
          <li key={type._id} style={{ marginBottom: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <label style={{paddingRight: '20px', width: '300px', textAlign: 'left'}}>{type.LectureType}</label>
            <button className="btn btn-danger" placeholder='Lecture Type' onClick={() => {props.dispatch(deleteLectureType(type._id)).then(result => props.dispatch(getLectureType(Department)).then(value => {triggerAlert('Deleted Successfully', true); setLectureTypes(value.payload)}))}} style={{ display: 'block', margin: '0 auto', marginBottom: '20px', padding: '10px 20px', borderRadius: '5px', fontSize: '16px', fontWeight: 'bold', transform: 'translateX(-10px)', backgroundColor: '#c82333', color: '#fff' }}>🗑️</button>
          </li>
        )): null}
      </ul>
      </div>
      <div style={{ marginTop: '10px' }}>
      <input
        value={proposedLectureTitle}
        onChange={e => setProposedLectureTitle(e.target.value)}
        placeholder='Lecture Type'
        style={{
          display: 'block',
          margin: '10px auto',
          padding: '5px 10px',
          borderRadius: '5px',
          fontSize: '14px',
          border: '1px solid #ccc',
          width: '80vw',
          maxWidth: '600px',
        }}
      />
      <br/>
      <input
        value={proposedStartTime}
        onChange={e => setProposedStartTime(e.target.value)}
        placeholder='Default start time'
        type='time'
        style={{
          display: 'block',
          margin: '10px auto',
          padding: '5px 10px',
          borderRadius: '5px',
          fontSize: '14px',
          border: '1px solid #ccc',
          width: '80vw',
          maxWidth: '600px',
        }}
      />
      <br/>
      <input
        value={proposedEndTime}
        onChange={e => setProposedEndTime(e.target.value)}
        placeholder='Default end time'
        type='time'
        style={{
          display: 'block',
          margin: '10px auto',
          padding: '5px 10px',
          borderRadius: '5px',
          fontSize: '14px',
          border: '1px solid #ccc',
          width: '80vw',
          maxWidth: '600px',
        }}
      />
      <br/>
        <button className="btn btn-dark" onClick={handleAddLectureType} style={{ backgroundColor: '#4caf50', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}>Add New Lecture Type</button>
        <button className="btn btn-dark" onClick={handleHidePanel} style={{ marginLeft: '20px', backgroundColor: '#333333', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}>Hide Panel</button>
      </div>
    </div>
        </> : null}
         <div style={{ textAlign: 'center', margin: '20px' }}>
  
  <button className={editing? "btn btn-dark" : "btn btn-danger"} onClick={() => {editing ? setEditing(false): setEditing(true)}}>{editing? "Hide Editing panel" : "Edit Lecture Types"}</button>
  <br/>
    <br/>
    <br/>
    <div>
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
    <h4>Block users from certain lectures</h4>
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {lectureTypes.map(lectureType => (
            <button
                key={lectureType._id}
                onClick={() => handleSelectLectureType(lectureType)}
                style={{ 
                    margin: '5px', 
                    padding: '10px 20px', 
                    borderRadius: '20px', 
                    border: 'none', 
                    backgroundColor: selectedLectureType === lectureType ? '#4CAF50' : '#f0f0f0', 
                    color: selectedLectureType === lectureType ? '#fff' : '#000', 
                    cursor: 'pointer' 
                }}
            >
                {lectureType.LectureType}
            </button>
        ))}
    </div>
</div>

            {showModal && selectedLectureType && (
                <UserSelectionModal
                    usersList={usersList}
                    selectedUsers={selectedUsers}
                    handleSelectUser={handleSelectUser}
                    handleClose={handleCloseModal}
                />
            )}

        </div>
    </div>
    <br/><br/>
    </>: <div style={{textAlign: 'center'}}><label style={{fontSize: '10rem'}}>⛔</label></div>}
      </Fragment>
    )
}
else{
    return(<><div style={{width: '100%', textAlign: 'center'}}><label style={{fontSize: '20rem'}}>⛔</label></div></>)
}
}

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  maxWidth: '300px',
  margin: '0 auto',
};

const selectStyle = {
  padding: '10px',
  fontSize: '16px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  marginBottom: '20px',
};

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(QRgenerator);


export default Container;
