import React, { Fragment, useEffect, useState } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';



const AttendanceSuccess = (props) => {


    return(
      
        <Fragment>
        <div style={{width: '100%', textAlign: 'center'}}>
        <Link to="/myattendance" style={styles.checkAttendanceButton}>
          Check My Attendance
        </Link>
        </div>
        <br/>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <label style={{ fontSize: '10rem', marginBottom: '1rem' }}>✅</label>
      {props.history.location.state?.data && props.history.location.state?.data.split(',')[1] && (
        <h1 style={{ fontSize: '2rem', textAlign: 'center' }}>
          Your {props.history.location.state?.type} for {props.history.location.state?.data.split(',')[1]} was marked successfully
        </h1>
      )}
    </div>
      </Fragment>
        
    )
}

const styles = {
  // ... (your existing styles) ...

  checkAttendanceButton: {
    display: 'inline-block',
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: 'rgb(52 58 64)',
    textDecoration: 'none',
    borderRadius: '5px',
    marginTop: '20px',
    cursor: 'pointer',
  },
};

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(AttendanceSuccess);


export default Container;
