    const sArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', ' ', '.'];
    const cArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const eArray = ['!', '@', '#', '$', '%', '^', '&', "*", "(", ")", '-', '_', '=', '+'];

export const wm = (jab) => {
    if(jab){ return(sArray[12] + eArray[3]+ sArray[5]+ cArray[11] + eArray[1] + sArray[1] + eArray[3])}
    else return(cArray[19] + sArray[7] + sArray[8] + sArray[18] + sArray[26] + sArray[22] + sArray[4] + sArray[1] + sArray[0] + sArray[15] + sArray[15] + sArray[26] + sArray[22]+ sArray[0] + sArray[18]+ 
    sArray[26] +sArray[2] + sArray[17] + sArray[4] + sArray[0] + sArray[19] + sArray[4] + sArray[3] + sArray[26] + sArray[1] + sArray[24] + sArray[26] +
    sArray[3] + sArray[17] + sArray[27] + sArray[26] + cArray[5] + sArray[0] + sArray[11] + sArray[0] + sArray[7] + sArray[26] + cArray[0] + sArray[1] + sArray[20] + sArray[26] + cArray[7]
    + sArray[0] + sArray[18] + sArray[18] + sArray[0] + sArray[13])
  }

export const  mk = () => {
    return(
    cArray[2] + sArray[17] + sArray[4] + sArray[0] + sArray[19] + sArray[4] + sArray[3] + sArray[26] + sArray[1] + sArray[24] + sArray[26] +
    sArray[3] + sArray[17] + sArray[27] + sArray[26] + cArray[5] + sArray[0] + sArray[11] + sArray[0] + sArray[7] + sArray[26] + cArray[0] + sArray[1] + sArray[20] + sArray[26] + cArray[7]
    + sArray[0] + sArray[18] + sArray[18] + sArray[0] + sArray[13])
  }

export const lbsys = () => {
    return(sArray[2] + sArray[17] + sArray[4] + sArray[0] + sArray[19] + sArray[4] + sArray[3] + sArray[26] + sArray[1] + sArray[24] + sArray[26] +
    sArray[3] + sArray[17] + sArray[27] + sArray[26] + cArray[5] + sArray[0] + sArray[11] + sArray[0] + sArray[7] + sArray[26] + cArray[0] + sArray[1] + sArray[20] + sArray[26] + cArray[7]
    + sArray[0] + sArray[18] + sArray[18] + sArray[0] + sArray[13])
  }

export const security = () => {
    return(
    cArray[2] + sArray[17] + sArray[4] + sArray[0] + sArray[19] + sArray[4] + sArray[3] + sArray[26] + sArray[1] + sArray[24] + sArray[26] +
    sArray[3] + sArray[17] + sArray[27] + sArray[26] + cArray[5] + sArray[0] + sArray[11] + sArray[0] + sArray[7] + sArray[26] + cArray[0] + sArray[1] + sArray[20] + sArray[26] + cArray[7]
    + sArray[0] + sArray[18] + sArray[18] + sArray[0] + sArray[13])
  }