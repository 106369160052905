import React, { Fragment, useEffect, useState } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { getAllResidentTypes } from '../actions/getContact';
import { deleteResident, submitResident } from '../actions/addContact';
import { getAdmins, getTokens, getDepartment } from '../utils/misc';



const ResidentTypes = (props) => {
  const [email, setEmail] = useState('');
  const [Admins, setAdmin] = useState([]);
  const [Residents, setResidents] = useState([]);
  const [Department, setDepartment] = useState('');
  const [user, setUser] = useState('');
  const [ErrorDisplay, setErrorDisplay] = useState(false);
  const [Error, setError] = useState('');
  const [componentMounted, setComponentMounted] = useState(false);

  useEffect(()=>{setComponentMounted(true); return(()=> setComponentMounted(false))},[])

  useEffect(()=>{ if(componentMounted){
    getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})
    getAdmins(val => {setAdmin(val);
      getDepartment(val => {
        
        setDepartment(val)
        props.dispatch(getAllResidentTypes(val)).then(
          res => {if (componentMounted) {setResidents(res.payload)}}
        )
      })
    }) }
  },[componentMounted])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(Residents.filter(obj => obj.user === email).length > 0)
    {
      setError('You cannot add a resident twice');
      setErrorDisplay(true)
    }
    else{
    props.dispatch(submitResident({user: email.toLowerCase(), type: Department})).then(res => {
      if(res && res.payload && res.payload.success){
        setEmail('');
        setResidents([...Residents, {user: email.toLowerCase(), type: Department}])
      }
      else{
        setError('User was not added');
      setErrorDisplay(true)
      }
    })
    }
  };

  const ConvertPosition = (input) => {
    if(input === "PED") return "Pediatrics"
    else if(input === "MED") return "Medicine"
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };



  const handleDelete = (email) => {
    props.dispatch(deleteResident({'email' : email})).then((res) => {
      if (res && res.payload && res.payload.success) {
        const updatedUsers = Residents.filter((user) => user.user !== email);
        setResidents(updatedUsers);
      } else {
        alert('Failed to delete user');
      }
    });
  };

  return (
    <Fragment>
            <div style={{width: '100vw', textAlign: 'center'}}>
      <h3>Allowed Users</h3>
      </div>
      {Admins.filter((obj) => obj.user === user && (obj.position === 'pd' || obj.position === 'apd' || obj.position === 'chief' || obj.position === 'coordinator')).length > 0 ? (
        <div style={{ textAlign: 'center' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <thead>
              <tr>
                <th style={{ background: '#f4f4f4', padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold', color: '#333', textTransform: 'uppercase' }}>User</th>
                <th style={{ background: '#f4f4f4', padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold', color: '#333', textTransform: 'uppercase' }}>Department</th>
                <th style={{ background: '#f4f4f4', padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold', color: '#333', textTransform: 'uppercase' }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Residents ? Residents.map((user, index) => (
                <tr key={index} style={{ background: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: '#555' }}>{user.user}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd', color: '#555' }}>{ConvertPosition(user.type)}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                    <button onClick={() => handleDelete(user.user)} style={{ marginRight: '10px', border: 'none', background: 'transparent' }}>
                    🗑️
                    </button>
                  </td>
                </tr>
              )): null}
            </tbody>
          </table>
  
          <br />
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', margin: '0 auto' }}>
            <label style={{ marginBottom: '10px', fontWeight: 'bold' }}>
              Email:
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                pattern=".+@(ttuhsc\.edu|va\.gov)"
                title="Please enter a valid @ttuhsc.edu email address"
                style={{ padding: '8px', marginTop: '5px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px', width: '80vw', maxWidth: '300px' }}
              />
            </label>
            {ErrorDisplay ? <div><label style={{color: 'red'}}>{Error}</label></div>: null}
            <button type="submit" style={{ padding: '10px', marginTop: '10px', borderRadius: '4px', border: 'none', background: 'rgb(52 58 64)', color: '#fff', fontSize: '16px', fontWeight: 'bold', cursor: 'pointer' }}>
              Add User
            </button>
          </form>
          <br/>
          <br/>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <label style={{ fontSize: '10rem' }}>⛔</label>
        </div>
      )}
    </Fragment>
  );  
}

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(ResidentTypes);


export default Container;
