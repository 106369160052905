import React, { Fragment, useState, useEffect } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { getAllAttendances, deleteAttendances, deleteAllAttendances } from '../actions';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';
import SimpleMap from './map';



const LectureAttendance = (props) => {

    const [lectures, setLectures] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');
    const [Admins, setAdmin] = useState([]);
    const [user, setUser] = useState('');
    const [maps, setMaps] = useState('false');
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [allusers, setAllUsers] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [Department, setDepartment] = useState('');
    const [showDateInputs, setShowDateInputs] = useState(false);
    const [componentMounted, setComponentMounted] = useState(false);
    let i = null;

    useEffect(() => {setComponentMounted(true); return(()=> setComponentMounted(false))}, [])

    useEffect(() => { if(componentMounted){
      getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})
      getAdmins(val => setAdmin(val))
      getDepartment(val => {
        setDepartment(val)
        const fetchAttendances = async () => {
          if(componentMounted){
          const attendancesData = await props.dispatch(getAllAttendances({department: val}));
          const lecturesMap = new Map();
    
          // Group attendances by lecture date and start time
          attendancesData.payload.forEach((attendance) => {
            const key = `${attendance.LectureDate}-${attendance.LectureStartTime}`;
            const lectureAttendances = lecturesMap.get(key) || [];
            lectureAttendances.push(attendance);
            lecturesMap.set(key, lectureAttendances);
          });
    
          // Convert the Map to an array of lectures
          const lecturesData = Array.from(lecturesMap).map(([key, value]) => ({
            LectureDate: value[0].LectureDate,
            LectureStartTime: value[0].LectureStartTime,
            Attendances: value,
          }));
          if(componentMounted) {setLectures(lecturesData);}
        }
        };
    
       fetchAttendances();
      }) }
    }, [componentMounted])
  
    function showAlertWithInput() {
        return new Promise((resolve) => {
          const userInput = prompt('Please type "confirm delete all lecture attendances" to delete:');
          resolve(userInput);
        });
      }

      function showAlertWithSingleInput() {
        return new Promise((resolve) => {
          const userInput = prompt('Please type "confirm delete this lecture attendance" to delete:');
          resolve(userInput);
        });
      }

    // Handler for deleting all attendances marked for the selected lecture
    const handleDeleteAttendances = async () => {
      const input = await showAlertWithSingleInput();
      if (input === "confirm delete this lecture attendance") {
        if (selectedLecture) {
          await props.dispatch(deleteAttendances(selectedLecture.Attendances?.map((attendance) => attendance._id) || [], Department)).then(res => {
            if (res.payload.success === true) {
              const updatedLectures = lectures.filter((l) => l !== selectedLecture);
              setLectures(updatedLectures);
              setSelectedLecture(null);
            } else {
              alert("Deletion failed");
            }
          });
        }
      } else {
        alert("Nothing was deleted");
      }
    };

    const handleDeleteAllAttendances = async () => {
      const input = await showAlertWithInput();
      if (input === "confirm delete all lecture attendances") {
        await props.dispatch(deleteAllAttendances(Department));
        setLectures([]);
        setSelectedLecture(null);
      } else {
        alert("Nothing was deleted");
      }
    };    

    const handleBackupAllAttendances = async () => {
      
        // Assuming getAllAttendances returns a promise that resolves to JSON data
          try {
          // Convert the JSON data to a Blob
        const jsonBlob = new Blob([JSON.stringify(lectures, null, 2)], { type: 'application/json' });
    
        // Create a link element
        const downloadLink = document.createElement('a');
    
        // Create a Blob URL for the Blob
        const url = URL.createObjectURL(jsonBlob);
    
        // Set the download attribute and the file name
        downloadLink.download = 'attendances_backup.json';
    
        // Set the href attribute to the Blob URL
        downloadLink.href = url;
    
        // Append the link to the document
        document.body.appendChild(downloadLink);
    
        // Trigger a click event on the link to initiate the download
        downloadLink.click();
    
        // Remove the link from the document
        document.body.removeChild(downloadLink);
    
      } catch (error) {
        alert('Error during backup:', error);
      }
        }
    
        
    

    const exportToExcel =() => {
// Import the required libraries
const XLSX = require('xlsx');

// Create a new workbook
const workbook = XLSX.utils.book_new();

// Define the cell styles
const headerStyle = { fill: { patternType: 'solid', fgColor: { rgb: '343a40' } }, font: { color: { rgb: 'ffffff' } } };
const titleStyle = { fill: { patternType: 'solid', fgColor: { rgb: 'da2d48' } }, font: { color: { rgb: 'ffffff' } } };

// Process each element in the array
lectures.forEach((element, index) => {
  const { Attendances, LectureDate, LectureStartTime, ...rest } = element;

  // Create a new worksheet
  const worksheetData = [
    ['Date', 'Time', 'Lecture Title'],
    [LectureDate, LectureStartTime, Attendances[0]?.LectureTitle],
    [],
    ['Attendee Name', 'Time Created'],
    ...(Attendances || []).map(attendance => [attendance.User, new Date(attendance.createdAt).toLocaleString('en-US', {
      timeZone: 'America/Chicago'
    })])
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Apply cell styles
/*   const headerRange = { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } };
  const titleRange = { s: { c: 0, r: 1 }, e: { c: 2, r: 1 } };

  const applyCellStyle = (range, style) => {
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ c: col, r: row });
        const cell = worksheet[cellAddress];
        if (cell) {
          cell.s = style;
        }
      }
    }
  };

  applyCellStyle(headerRange, headerStyle);
  applyCellStyle(titleRange, titleStyle); */

  // Auto-fit column widths
  const columnWidths = worksheetData[0].map((_, columnIndex) => {
    const columnData = worksheetData.map(row => row[columnIndex]);
    const columnWidths = columnData.map(value => value ? value.toString().length : 0);
    return Math.max(...columnWidths);
  });
  worksheet['!cols'] = columnWidths.map(width => ({ width: width + 1 }));

  // Set the worksheet name
  const worksheetName = `Sheet ${index + 1}`;

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
});

// Convert the workbook to a binary Excel file
const excelFile = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });

// Create a Blob from the Excel file data
const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

// Create a download link
const downloadLink = document.createElement('a');
downloadLink.href = URL.createObjectURL(blob);
downloadLink.download = 'data.xlsx';

// Append the download link to the document body
document.body.appendChild(downloadLink);

// Programmatically click the download link to trigger the file download
downloadLink.click();

// Clean up by removing
document.body.removeChild(downloadLink);


    }

    const exportToExcelClean =() => {
      // Import the required libraries
      const XLSX = require('xlsx');
      
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      
      // Define the cell styles
      const headerStyle = { fill: { patternType: 'solid', fgColor: { rgb: '343a40' } }, font: { color: { rgb: 'ffffff' } } };
      const titleStyle = { fill: { patternType: 'solid', fgColor: { rgb: 'da2d48' } }, font: { color: { rgb: 'ffffff' } } };



      let worksheetData = []
      // Process each element in the array
      lectures.forEach((element, index) => {
        if(true) {

        const { Attendances, LectureDate, LectureStartTime, ...rest } = element;
        // Create a new worksheet
       
          if(Date.parse(LectureDate) && Date.parse(startDate) && Date.parse(startDate) > Date.parse(LectureDate)){return;}
          if(Date.parse(LectureDate) && Date.parse(endDate) && Date.parse(endDate) < Date.parse(LectureDate)){return;}

      

        const priorData = 
        [[LectureDate + " " + LectureStartTime],
        ...Attendances.map(object => [object?.User],)
        ];

        const maxLength = Math.max(worksheetData.length, priorData.length);
        const result = [];

for (let indexa = 0; indexa < maxLength; indexa++) {
  const combinedArray = [
    ...(worksheetData[indexa] || []),
    ...(priorData[indexa] || [""])
  ];
  
  result.push(combinedArray);
}

worksheetData = result;
      }
      
      })

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      
      // Auto-fit column widths
      const columnWidths = worksheetData[0].map((_, columnIndex) => {
        const columnData = worksheetData.map(row => row[columnIndex]);
        const columnWidths = columnData.map(value => value ? value.toString().length : 0);
        return Math.max(...columnWidths);
      });
      worksheet['!cols'] = columnWidths.map(width => ({ width: width + 1 }));
    
      // Set the worksheet name
      const worksheetName = `Lecture attendances`;
    
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);

      //Create counts
      let rowsLength = worksheetData.length;
      let columnWidth = worksheetData[0].length;
      let uniqueNames = [];
      for(let c = 0; c < columnWidth; c++)
      {
      for(let r = 0; r < rowsLength; r++){
        if(worksheetData[r][c]?.split('@').length > 1){
          if (!uniqueNames.includes(worksheetData[r][c])) {
            uniqueNames.push(worksheetData[r][c]);
          }
        }
      }
    }
      let nameCount = [["Email", "Lectures Attended", "Total QR code Sessions"]]

      uniqueNames.forEach(name => {
        for(let c = 0; c < columnWidth; c++)
      {
        let columnCount = 0
        
      for(let r = 0; r < rowsLength; r++){
        if(worksheetData[r][c]?.split('@').length > 1 && name == worksheetData[r][c] && columnCount == 0){
          const foundItem = nameCount.find(item => item[0] === name);
          if (foundItem){foundItem[1]++}
          else{nameCount.push([name, 1, worksheetData[0].length])}
          columnCount++;
        }
      }
    
    }

      })

      // Add the second sheet
      const worksheet2 = XLSX.utils.aoa_to_sheet(nameCount);
      
      // Auto-fit column widths
      const columnWidths2 = nameCount[0].map((_, columnIndex) => {
        const columnData2 = nameCount.map(row => row[columnIndex]);
        const columnWidths2 = columnData2.map(value => value ? value.toString().length : 0);
        return Math.max(...columnWidths2);
      });
      worksheet2['!cols'] = columnWidths2.map(width => ({ width: width + 1 }));
    
      // Set the worksheet name
      const worksheetName2 = `Counts`;
    
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet2, worksheetName2);

      // Convert the workbook to a binary Excel file
      const excelFile = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
      
      // Create a Blob from the Excel file data
      const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'data.xlsx';
      
      // Append the download link to the document body
      document.body.appendChild(downloadLink);
      
      // Programmatically click the download link to trigger the file download
      downloadLink.click();
      
      // Clean up by removing
      document.body.removeChild(downloadLink);
      
      
          }

    const formatDate = (dateTimeString) => {
      const date = new Date(dateTimeString);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
    
      return `${month}-${day}-${year} ${hours}:${minutes}`;
    };

      // Handler for selecting a lecture from the dropdown menu
  const handleSelectLecture = (lecture) => {
    setSelectedLecture(lecture);
  };

  const tableHeaderStyle = {
    fontWeight: 'bold',
    backgroundColor: '#f2f2f2',
    padding: '8px',
    border: '1px solid #ddd',
  };
  
  const tableCellStyle = {
    padding: '8px',
    border: '1px solid #ddd',
  };

    return(
      
        <Fragment>
        
        {Admins.filter(obj => obj.user === user).length > 0 ?
          <>
          {allusers === false ? <>
          {maps === 'false' ? <>
          <div style={{ width: '100vw', textAlign: 'center' }}>
            <h3 style={{ fontSize: '24px', marginBottom: '20px' }}>Lecture Attendance</h3>
            <div style={{width: '100%', textAlign: 'center', marginTop: '10px', marginBottom: '10px'}}>
              {/* <button
       style={{
        backgroundColor: 'rgb(52 58 64',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 16px',
        cursor: 'pointer',
        margin: '10px',
      }}
      onClick={exportToExcel}
       >Export All data to Excel</button> */}
             <div>
      <button
        style={{
          backgroundColor: 'rgb(52 58 64)',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          padding: '8px 16px',
          cursor: 'pointer',
          margin: '10px',
        }}
        onClick={() => setShowDateInputs(!showDateInputs)}
      >
        {showDateInputs ? 'Hide Panel' : 'Export Panel'}
      </button>

      {showDateInputs && (
        <div>
          <div style={{ display: 'inline' }}>
            <label style={{ padding: '10px', margin: '10px' }}>From Date:</label>
            <input
              style={{ padding: '10px', margin: '10px' }}
              type="date"
              onChange={(date) => setStartDate(date.target.value)}
            ></input>
          </div>
          <div style={{ display: 'inline' }}>
            <label style={{ padding: '10px', margin: '10px' }}>To Date:</label>
            <input
              style={{ padding: '10px', margin: '10px' }}
              type="date"
              onChange={(date) => setEndDate(date.target.value)}
            ></input>
          </div>
          <br/>
          <button
        style={{
          backgroundColor: 'rgb(52 58 64)',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          padding: '8px 16px',
          cursor: 'pointer',
          margin: '10px',
        }}
        onClick={exportToExcelClean}
      >
        Export All Data to Excel
      </button>
      <br/>
      <div>
            { Admins.filter(obj => obj.user === user && ( obj.position === "pd" || obj.position === "apd" || obj.position === "chief" || obj.position === "coordinator")).length > 0 ?
              <button
                onClick={handleBackupAllAttendances}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: '#21521d',
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '4px',
                  marginTop: '20px',
                }}
              >
                Save a JSON local backup copy of attendances
              </button> : null}
            </div>
        </div>
      )}


    </div>
    </div>
       
        <br/>
            <select
              
              onChange={(e) =>{
                //handleSelectLecture(lectures.find((lecture) => lecture.LectureStartTime === e.target.value.split('*')[0] && lecture.LectureDate === e.target.value.split('*')[1]))
                if(!i){i = 0;}
                
                handleSelectLecture(lectures[e.target.value.split('*')[2]])
              }
              }
              style={{ padding: '10px', fontSize: '16px' }}
            >
              
              <option value="">Select a Lecture</option>
              {lectures.map((lecture) => (
                <option
                  key={`${lecture.LectureDate}-${lecture.LectureStartTime}`}
                  value={`${lecture.LectureStartTime}*${lecture.LectureDate}*${i++}`}
                >
                  {`Lecture Date: ${lecture.LectureDate}, Start Time: ${lecture.LectureStartTime}`}
                </option>
              ))}
            </select>
            {selectedLecture && (
              <div>
                <h2 style={{ fontSize: '20px', marginTop: '20px' }}>
                  {selectedLecture?.Attendances[0]?.LectureTitle}
                </h2>
                <table style={{ width: '100%', marginTop: '20px' }}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>User</th>
                    {/* <th style={tableHeaderStyle}>Accuracy</th>
                    <th style={tableHeaderStyle}>Altitude</th>
                    <th style={tableHeaderStyle}>Latitude</th>
                    <th style={tableHeaderStyle}>Longitude</th> */}
                    <th style={tableHeaderStyle}>Created At</th>
                    <th style={tableHeaderStyle}>View on Map</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedLecture.Attendances.map((attendance) => (
                    <tr key={attendance._id}>
                      <td style={tableCellStyle}>{attendance.User}</td>
                      {/* <td style={tableCellStyle}>{attendance.Accuracy}</td>
                      <td style={tableCellStyle}>{attendance.Altitude}</td>
                      <td style={tableCellStyle}>{attendance.Latitude}</td>
                      <td style={tableCellStyle}>{attendance.Longitude}</td> */}
                      <td style={tableCellStyle}>{formatDate(attendance.createdAt)}</td>
                      <td style={tableCellStyle}><button
                  onClick={() => {setLat(attendance.Latitude); setLong(attendance.Longitude); setSelectedUser(attendance.User); setMaps('true'); }}
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#2c2222',
                    color: '#ffffff',
                    border: 'none',
                    borderRadius: '4px',
                    marginTop: '20px',
                  }}>📌</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                onClick={() => setAllUsers(true)}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: '#455f77',
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '4px',
                  marginTop: '20px',
                }}
              >
                🗺️
              </button>
              <br/>
              { Admins.filter(obj => obj.user === user && ( obj.position === "pd" || obj.position === "apd")).length > 0 ?
                <button
                  onClick={handleDeleteAttendances}
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#f44336',
                    color: '#ffffff',
                    border: 'none',
                    borderRadius: '4px',
                    marginTop: '20px',
                  }}
                >
                  Delete All Attendances in this lecture
                </button> : null }
              </div>
            )}
            <div>
            { Admins.filter(obj => obj.user === user && ( obj.position === "pd" || obj.position === "apd")).length > 0 ?
              <button
                onClick={handleDeleteAllAttendances}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: '#f44336',
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '4px',
                  marginTop: '20px',
                }}
              >
                Delete All Attendances in the database
              </button> : null}
            </div>
            
          </div>
        </> : <><SimpleMap location={{center: {lat: parseFloat(lat), lng: parseFloat(long)}, User: selectedUser}}/>        <button
  style={{
    width: '72px',
    height: '36px',
    background: '#343a40',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    color: 'white',
    fontSize: '14px',
    margin: '15px',
  }}
  onClick={() => setMaps('false')}
>
  Close
</button></>} </>: 
<><SimpleMap location={{data: selectedLecture}}/>        <button
  style={{
    width: '72px',
    height: '36px',
    background: '#343a40',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    color: 'white',
    fontSize: '14px',
    margin: '15px',
  }}
  onClick={() => setAllUsers(false)}
>
  Close
</button></>} </>


      
         : <div style={{textAlign: 'center'}}><label style={{fontSize: '10rem'}}>⛔</label></div>}

      <br/>

      </Fragment>
        
    )

    
}

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(LectureAttendance);


export default Container;
