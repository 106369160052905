import React, { Fragment, useEffect, useState } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { getAllContacts } from '../actions/getContact';
import { updateContact } from '../actions/addContact';
import Alerter from './alerter';


const Tab1 = (props) => {

  const [concatHTML, setConcatHTML] = useState(<></>);
  const [overlayHTML , setOverlayHTML] = useState(null);
  const [editing, setEditing] = useState(false);
  const [oldNumberName, setOldNumberName] =  useState('');
  const [_id, set_id] =  useState('');
  const [NumberName, setNumberName] =  useState('');
  const [NumberHospital, setNumberHospital] =  useState([]);
  const [NumberPosition, setNumberPosition] =  useState('');
  const [NumberMedicalGroup, setNumberMedicalGroup] =  useState('');
  const [NumberDepartment, setNumberDepartment] =  useState('');
  const [NumberSubspecialty, setNumberSubspecialty] =  useState('');
  const [PersonalNumber, setPersonalNumber] =  useState('');
  const [PagerNumber, setPagerNumber] =  useState('');
  const [OfficeNumber, setOfficeNumber] =  useState('');
  const [Extension, setExtension] =  useState('');
  const [NumberNPName, setNumberNPName] =  useState('');
  const [NumberNPNumber, setNumberNPNumber] =  useState('');
  const [NumberComment, setNumberComment] =  useState('');
  const [Email, setEmail] =  useState('');
  const [clicking, setClicking] = useState(0);
  const [alerter, setAlerter] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [allContacts, setAllContacts] = useState([]);
  const [NWTHChecked, setNWTHChecked] = useState(false);
  const [BSAChecked, setBSAChecked] = useState(false);
  const [VAChecked, setVAChecked] = useState(false);
  const [TTUHSCChecked, setTTUHSCChecked] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(()=>{
    setIsMounted(true)

    return(() => setIsMounted(false))
  },[])

  
  let bgStyle = {width: '100vw', height: '100vh', background: '#343a40', color:'white', 
                 position: 'absolute', left: '0', top: '0', overflowY: 'auto', overflow: 'auto',
                 textAlign: 'center'};

  let cardSize = {width: '90vw', maxWidth: '350px', maxHeight: '200px', background: '#dc3545', 
                  filter: 'drop-shadow(10px 3px 4px black)', minHeight: '200px', margin: '30px',
                  display: 'inline-block'};

  let restInputeStyle = {display: 'flex', flexDirection: 'column', flexWrap: 'wrap', 
  alignContent: 'center', justifyContent: 'center', alignItems: 'center'}

  let labelStyle = {width: '250px'};
  let inputStyle = {width: 'fitContent',
    margin: '0px', fontWeight: '800', fontSize: '120%', fontFamily: 'monospace',
    background: 'transparent', border: 'none', color: 'white', width: '90vw', textAlign: 'center'};

  let buttonStyle = { borderRadius: '15px', backgroundColor: '#dc3545', color: 'white', borderColor: '#dc3545', borderStyle: 'solid', padding: '10px', margin: '20px', width: '90px'};

  useEffect(()=>{},[alerter,uploadSuccess])

  useEffect(() => {
    if(oldNumberName !== '')
    {
      // if(NumberHospital.includes("VA") || VAChecked) {setVAChecked(true)} else setVAChecked(false);
      // if(NumberHospital.includes("BSA") || BSAChecked) {setBSAChecked(true)} else setBSAChecked(false);
      // if(NumberHospital.includes("NWTH") || NWTHChecked) {setNWTHChecked(true)} else setNWTHChecked(false);
      // if(NumberHospital.includes("TTUHSC") || TTUHSCChecked) {setTTUHSCChecked(true)} else setTTUHSCChecked(false);
    setOverlayHTML(
      <div style={bgStyle}>
        <div style={{width: '100vw', textAlign: 'center'}}>
      <div style={cardSize}>
                <div style={{display: 'flex'}}> 
                <div style={{paddingTop: '10px', marginRight: '10px', width: 'fit-content'}}>
                <img src={require('../media/amarillo.png')} style={{display: 'block', height: '50px', marginLeft: '15px'}}></img>
                </div>
                 <div style={{paddingTop: '10px', marginRight: '10px', position: 'absolute', right: '0'}}>
                 {NumberHospital.includes("NWTH") ? <img src={require('../media/NWTH.png')} style={{height: '25px', width: '25px', float: 'right'}}/> : <></>}
                 {NumberHospital.includes("BSA") ? <img src={require('../media/BSA.png')} style={{height: '25px', width: '50px', float: 'right'}}/> : <></>}
                 {NumberHospital.includes("VA") ? <img src={require('../media/va.png')} style={{height: '25px', float: 'right'}}/> : <></>}
                 {NumberHospital.includes("TTUHSC") ? <img src={require('../media/TTUHSC.png')} style={{height: '25px', float: 'right'}}/> : <></>}
                 </div>
                 </div>
                 <div style={{display: 'flex'}}>
                 <br/>
                 <input style={{...inputStyle, position : 'absolute', top: '100px', paddingLeft: '0px', color: 'white', width: '100%', textAlignLast: 'center'}} value={NumberName} disabled={editing ? false : true} onChange={(event) => {if(editing) {setNumberName(event.target.value)}}}/>
                 <input placeholder="physician" style={{...inputStyle, position : 'absolute', top: '60px', paddingLeft: '0px', color: 'white', width: '100%', textAlignLast: 'center'}} disabled={editing ? false : true} value={NumberPosition} onChange={(event) => {if(editing) setNumberPosition(event.target.value)}}/>
                 <input style={{...inputStyle, position : 'absolute', top: '120px', paddingLeft: '0px', color: 'white', width: '100%', textAlignLast: 'center'}} value={editing ? PersonalNumber : toNumber(PersonalNumber)} onChange={(event) => editing ? setPersonalNumber(event.target.value): null} onClick={!editing && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? () => window.open("tel:" + PersonalNumber) : null}></input>
                 <input style={{...inputStyle, position : 'absolute', top: '150px', paddingLeft: '0px', color: 'white', width: '100%', textAlignLast: 'center'}} disabled value="______________________________" />
                  {/*<i style={{...inputStyle, top: '35%'}}>, </i>*/}
                  <br/>
                 </div>

      </div>
       </div>
      <div style={restInputeStyle}>

                  {editing ? 
                  <>
                        <label>
        <input
          type="checkbox"
          onChange={handleNWTHCheckboxChange}
           checked={NWTHChecked}
        />
        <img
          src={require('../media/NWTH.png')}
          style={{ display: 'inline', height: '25px', width: '25px', float: 'right' }}
        />
      </label>
      <label>
        <input
          type="checkbox"
          onChange={handleBSACheckboxChange}
          checked={BSAChecked}
        />
        <img
          src={require('../media/BSA.png')}
          style={{ display: 'inline', height: '25px', width: '50px', float: 'right' }}
        />
      </label>
      <label>
        <input
          type="checkbox"
          onChange={handleVACheckboxChange}
          checked={VAChecked}
        />
        <img
          src={require('../media/va.png')}
          style={{ display: 'inline', height: '25px', width: '25px', float: 'right' }}
        />
      </label>
      <label>
        <input
          type="checkbox"
          onChange={handleTTUHSCCheckboxChange}
          checked={TTUHSCChecked}
        />
        <img
          src={require('../media/TTUHSC.png')}
          style={{ display: 'inline', height: '25px', width: '25px', float: 'right' }}
        />
      </label>
                  </>
                  : null}

                  <br/>
                  {NumberComment && NumberComment !== '' || editing ? <><input style={{...inputStyle, color: 'red'}} placeholder="Comment" value={NumberComment} onChange={(event) => {if(editing) setNumberComment(event.target.value)}}></input><br/></>: null}
                  
                  
                  <br/>
                  {NumberMedicalGroup && NumberMedicalGroup !== '' || editing ? <><p style={ labelStyle }>Medical Group: </p> <input style={inputStyle} placeholder="Amarillo Heart group" value={NumberMedicalGroup} onChange={(event) => {if(editing) setNumberMedicalGroup(event.target.value)}}></input></> : null}
                  <br/>
                  {NumberDepartment && NumberDepartment !== '' || editing ? <><p style={ labelStyle }>Department: </p><input style={inputStyle} placeholder="Internal Medicine" value={NumberDepartment} onChange={(event) => {if(editing) setNumberDepartment(event.target.value)}}></input></> : null}
                  <br/>
                  {NumberSubspecialty && NumberSubspecialty !== '' || editing ? <><p style={ labelStyle }>Subspecialty: </p><input style={inputStyle} placeholder="Cardiology" value={NumberSubspecialty}  onChange={(event) => {if(editing) setNumberSubspecialty(event.target.value)}}></input></> : null}
                  <br/>
                  {PagerNumber && PagerNumber || editing ? <><p style={ labelStyle }>Pager Number:</p><input style={inputStyle} value={PagerNumber || ""} onChange={(event) => {if(editing) setPagerNumber(event.target.value)}}></input><br/></> : null}
                  
                  {OfficeNumber && OfficeNumber !== '' || editing ? <><p style={ labelStyle }>Office Number: </p><input style={inputStyle} value={editing ? OfficeNumber : toNumber(OfficeNumber)} onChange={(event) => {if(editing) setOfficeNumber(event.target.value) }}></input></> : null}
                  <br/>
                  {Extension && Extension || editing ? <> <p style={ labelStyle }>Extension: </p><input style={inputStyle} placeholder="Four digit extension number" value={Extension} onChange={(event) => {if(editing) setExtension(event.target.value) }}></input> <br/> </> : null}
                  
                  {NumberNPNumber && NumberNPNumber !== '' || editing ? <><p style={ labelStyle }>NP Name: </p><input style={inputStyle} value={NumberNPName} onChange={(event) => {if(editing) setNumberNPName(event.target.value)}}></input></> : null}
                  <br/>
                  {NumberNPNumber && NumberNPNumber !== '' || editing ? <><p style={ labelStyle }>NP phone number: </p><input style={inputStyle} value={editing ? NumberNPNumber : toNumber(NumberNPNumber)} onChange={(event) => {if(editing) setNumberNPNumber(event.target.value)}}></input></> : null}
                  <br/>
                  {Email && Email !== '' || editing ? <><p style={ labelStyle }>Email: </p><input style={inputStyle} value={Email || ""} onChange={(event) => {if(editing) setEmail(event.target.value)}}></input></> : null}
                  <br/>
                  
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    {editing === false ? <button style={buttonStyle} 
                    onClick={() => startEditing()}>Edit</button> : null}
                    {editing ? <button style={buttonStyle} 
                    onClick={() => uploadUpdate()}>Upload Update</button> : null}
                    {editing ? <button style={buttonStyle} 
                    onClick={() => stopEditing()}>Cancel</button> : null}
                    <button style={buttonStyle} 
                    onClick={() => {setOverlayHTML(null); searchFunction('')}}>Close</button>
                    
                    </div>
                    
                  <br/>
                  <br/>
                  </div>
                  
    </div>
    )}
  }, [editing, oldNumberName, NumberName, NumberHospital, NumberComment, NumberPosition, NumberMedicalGroup, NumberDepartment, NumberSubspecialty, 
    PersonalNumber, PagerNumber, OfficeNumber, Extension, NumberNPName, NumberNPNumber, Email, oldNumberName, NWTHChecked, VAChecked, BSAChecked, TTUHSCChecked])

  useEffect(() => {if(isMounted){props.dispatch(getAllContacts()).then((res)=>{if(isMounted){cardStack(res.payload); setAllContacts(res.payload);}})}} ,[isMounted])
  React.useCallback(() => React.updateState({}), [concatHTML]);


  const startEditing = () => {
    alert('You are going to update the info of this contact. Please make sure that your information is correct');
    setEditing(true);
  }

  const stopEditing = () => {
    setEditing(false);
  }

  const handleNWTHCheckboxChange = (event) => {
    setNWTHChecked(event.target.checked)
  };

  const handleBSACheckboxChange = (event) => {
    setBSAChecked(event.target.checked);
  };

  const handleVACheckboxChange = (event) => {
    setVAChecked(event.target.checked);
  };

  const handleTTUHSCCheckboxChange = (event) => {
    setTTUHSCChecked(event.target.checked);
  };

  const toNumber = (value) => {
  if(!value) { return ''};
   let firstthree = value.toString().substr(0,3);
   let secondthree = value.toString().substr(3,3);
   let lastfour = value.toString().substr(6,4);
   return "(" + firstthree + ") " + secondthree + " - " + lastfour;
  }


  const uploadUpdate = () => {
    let concatenatedHospitals = [];
    if(NWTHChecked) concatenatedHospitals = [...concatenatedHospitals, 'NWTH'];
    if(VAChecked) concatenatedHospitals = [...concatenatedHospitals, 'VA'];
    if(BSAChecked) concatenatedHospitals = [...concatenatedHospitals, 'BSA'];
    if(TTUHSCChecked) concatenatedHospitals = [...concatenatedHospitals, 'TTUHSC'];
    props.dispatch(updateContact({
      id: _id,
      NumberName: NumberName,
      NumberHospital: concatenatedHospitals,
      NumberPosition: NumberPosition,
      NumberMedicalGroup: NumberMedicalGroup,
      NumberDepartment: NumberDepartment,
      NumberSubspecialty: NumberSubspecialty,
      PersonalNumber: PersonalNumber,
      NumberComment: NumberComment,
      PagerNumber: PagerNumber,
      OfficeNumber: OfficeNumber,
      Extension: Extension,
      NumberNPName: NumberNPName,
      NumberNPNumber: NumberNPNumber,
      Email: Email,
      })).then((res) => triggerAlert(res.payload.success))

  }

  const triggerAlert = (success) => {
    if(success){
      setUploadSuccess(success);
      setAlerter(true);
      setTimeout(function(){ setAlerter(false) }, 2000);
    }
    else{
      setUploadSuccess(false);
      setAlerter(true);
      setTimeout(function(){ setAlerter(false) }, 2000);
    }
    stopEditing();
  }

  const clicked = (item) => 
  {
      setClicking(clicking + 1);
      setNumberHospital(item.NumberHospital);
      if(item.NumberHospital && item.NumberHospital.includes('NWTH')){setNWTHChecked(true)}else{setNWTHChecked(false)}
      if(item.NumberHospital && item.NumberHospital.includes('BSA')){setBSAChecked(true)}else{setBSAChecked(false)}
      if(item.NumberHospital && item.NumberHospital.includes('TTUHSC')){setTTUHSCChecked(true)}else{setTTUHSCChecked(false)}
      if(item.NumberHospital && item.NumberHospital.includes('VA')){setVAChecked(true)}else{setVAChecked(false)}
      setNumberComment(item.NumberComment);
      setNumberPosition(item.NumberPosition);
      setNumberMedicalGroup(item.NumberMedicalGroup);
      setNumberDepartment(item.NumberDepartment);
      setNumberSubspecialty(item.NumberSubspecialty);
      setPersonalNumber(item.PersonalNumber);
      setPagerNumber(item.PagerNumber);
      setOfficeNumber(item.OfficeNumber);
      setExtension(item.Extension);
      setNumberNPName(item.NumberNPName);
      setNumberNPNumber(item.NumberNPNumber);
      setEmail(item.Email)
      setNumberName(item.NumberName);
      setOldNumberName(item.oldNumberName)
      set_id(item._id);
    }
    
    
  

  const cardStack = (payload) => {
    if(payload && isMounted){
      let updatedconcateHTML = concatHTML;
    payload.forEach((item) => 
    {
      updatedconcateHTML = <>{updatedconcateHTML}
                    <div onClick={() => clicked(item)} style={{cursor: 'pointer', borderTop: '1px solid', borderRightStyle: 'none', borderBottomStyle: 'none', borderLeftStyle: 'none'}}>
                    <label style={{float: 'left', marginLeft: '2vw'}}>{item.NumberSubspecialty || item.NumberDepartment}</label>
                    {item.NumberSubspecialty && item.NumberDepartment ? <label style={{float: 'left', marginLeft: '2vw'}}>{item.NumberDepartment}</label> : null}
                    <div style={{paddingTop: '10px', marginRight: '10px'}}>
                    {item.NumberHospital.includes("NWTH") ? <img src={require('../media/NWTH.png')} style={{height: '25px', width: '25px', float: 'right'}}/> : <></>}
                    {item.NumberHospital.includes("VA") ? <img src={require('../media/va.png')} style={{height: '25px', width: '25px', float: 'right'}}/> : <></>}
                    {item.NumberHospital.includes("BSA") ? <img src={require('../media/BSA.png')} style={{height: '25px', width: '50px', float: 'right'}}/> : <></>}
                    {item.NumberHospital.includes("TTUHSC") ? <img src={require('../media/TTUHSC.png')} style={{height: '25px', width: '25px', float: 'right'}}/> : <></>}
                    </div>
                    <br/>
                    <div style={{padding: '10px'}}>
                    <h4 style={{marginLeft: '2vw'}}><b>{item.NumberName}</b></h4>
                    {item.NumberComment ? <><label style={{float: 'left', marginLeft: '2vw', color: 'red'}}>{item.NumberComment}</label></>: null}
                    <p style={{float: 'left', marginLeft: '2vw'}}>{item.NumberMedicalGroup}</p>
                    <p style={{float: 'inline', textAlign: 'right', marginRight: '30px'}}>{item.PersonalNumber || item.NumberNPNumber || item.OfficeNumber}</p>
                    </div>
                    </div></>;
    })
    
    setConcatHTML(updatedconcateHTML);

  }
  
}

  const searchFunction = (value) => {
    setConcatHTML(<></>);
    let updatedconcateHTML = <></>;
    allContacts.forEach((item) => {
      if((item.NumberName && item.NumberName.toLowerCase().includes(value.toLowerCase())) || (item.NumberNPName && item.NumberNPName.toLowerCase().includes(value.toLowerCase())) || (item.NumberDepartment && item.NumberDepartment.toLowerCase().includes(value.toLowerCase())) || (item.NumberPosition && item.NumberPosition.toLowerCase().includes(value.toLowerCase())) || (item.NumberSubspecialty && item.NumberSubspecialty.toLowerCase().includes(value.toLowerCase())))
      {
        updatedconcateHTML = <>{updatedconcateHTML}
        <div onClick={() => clicked(item)} style={{cursor: 'pointer', borderTop: '1px solid', borderRightStyle: 'none', borderBottomStyle: 'none', borderLeftStyle: 'none'}}>
        <label style={{float: 'left', marginLeft: '2vw'}}>{item.NumberSubspecialty || item.NumberDepartment}</label>
        <div style={{paddingTop: '10px', marginRight: '10px'}}>
        {item.NumberHospital && item.NumberHospital.includes("NWTH") ? <img src={require('../media/NWTH.png')} style={{height: '25px', width: '25px', float: 'right'}}/> : <></>}
        {item.NumberHospital && item.NumberHospital.includes("VA") ? <img src={require('../media/va.png')} style={{height: '25px', width: '25px', float: 'right'}}/> : <></>}
        {item.NumberHospital && item.NumberHospital.includes("BSA") ? <img src={require('../media/BSA.png')} style={{height: '25px', width: '50px', float: 'right'}}/> : <></>}
        {item.NumberHospital && item.NumberHospital.includes("TTUHSC") ? <img src={require('../media/TTUHSC.png')} style={{height: '25px', width: '25px', float: 'right'}}/> : <></>}
        </div>
        <br/>
        <div style={{padding: '10px'}}>
        <h4 style={{marginLeft: '2vw'}}><b>{item.NumberName}</b></h4>
        {item.NumberComment ? <label style={{float: 'left', marginLeft: '2vw', color: 'red'}}>{item.NumberComment}</label>: null}
        <p style={{float: 'left', marginLeft: '2vw'}}>{item.NumberMedicalGroup}</p>
        <p style={{float: 'inline', textAlign: 'right', marginRight: '30px'}}>{item.PersonalNumber || item.NumberNPNumber || item.OfficeNumber}</p>
        </div>
        </div></>;
      }
    })
    setConcatHTML(updatedconcateHTML);
  }

    return(
      
        <Fragment>
          { overlayHTML ? 
            <>
            {alerter ? <Alerter props={{success: uploadSuccess}}/> : overlayHTML}
            </> :
            <>
        <div>
        <button
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        background: '#da2d48',
        color: 'white',
        fontSize: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        lineHeight: '1',
        boxShadow: 'rgb(52 58 64) 0px 2px 4px',
        border: '0px'
      }}
      onClick={() => props.history.push('/tab2')}
    >
      +
    </button>
        <br/>
        <br/>
        <div style={{width: '100vw', textAlign: 'center'}}>
        <img src={require('../media/rbc.png')} width='100px'></img>
        <br/>
        <input
      style={{
        padding: '12px',
        fontSize: '14px',
        border: '2px solid #3498db',
        borderRadius: '5px',
        width: '350px',
        marginBottom: '10px',
        outline: 'none',
        transition: 'border-color 0.3s',
      }}
      placeholder='Search'
      onChange={(e) => searchFunction(e.target.value)}
      onFocus={(e) => (e.target.style.borderColor = '#dc3545')}
      onBlur={(e) => (e.target.style.borderColor = '#343a40')}
    />
        <br/>
        <label className="badge badge-danger" style={{margin: '10px 0px 10px 0px'}}>Phone numbers</label>
        <br/>
        <label style={{fontSize: '0.8rem',padding: '10px',color: '#555',fontWeight: 'bold',marginBottom: '20px',textTransform: 'uppercase',letterSpacing: '0.5px',textAlign: 'center',}}>This telephone directory is maintained by users. Please add missing phone numbers.</label>
        </div>
            </div>
            {concatHTML}
            </>
          }
    </Fragment>
        
    )
}

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(Tab1);


export default Container;
