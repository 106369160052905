import React from 'react';
import 'react-bootstrap/InputGroup';


const input = (props) => { 
    let template = null;

    switch(props.type){
        case "text":
            template = 
            <input
            style={{
                border: '2px solid #e9ecef',
                borderLeft: '0px',
                outline: 'none',
                transition: 'border-color 0.3s',
                fontSize: '1rem',
                padding: '15px',
              }}
              onFocus={(e) => (e.target.style.borderColor = '#dc3545')}
              onBlur={(e) => (e.target.style.borderColor = '#e9ecef')}
                {...props}
            />
            case "password":
                template = 
                <input
                style={{
                    border: '2px solid #e9ecef',
                    borderLeft: '0px',
                    outline: 'none',
                    transition: 'border-color 0.3s',
                    fontSize: '1rem',
                    padding: '15px',
                  }}
                  onFocus={(e) => (e.target.style.borderColor = '#dc3545')}
                  onBlur={(e) => (e.target.style.borderColor = '#e9ecef')}
                    {...props}
                />
        break;
        default:
            return template
    }
    return template;
}





export default input;
