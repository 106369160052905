import React, { Fragment, useEffect, useState, useRef } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { getAdmins, getTokens, getDepartment } from '../utils/misc';
import { QrReader } from 'react-qr-reader';

var CryptoJS = require("crypto-js");

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}



const VerifyPrintedCode = (props) => {

    const [data, setData] = useState('No result');
    const [user, setUser] = useState('');
    const [Admins, setAdmin] = useState([]);
    const [Department, setDepartment] = useState('');
    const [reader, setReader] = useState(null);
    const [QRregister, setQRregister] = useState(null);
    const [iteration, setIteration] = useState(0);
    const [refIncrease, setRefincrease] = useState(0);

    // const [dateNow, setDateNow] = useState(new Date());
    let qrReaderRef = useRef(0);
    let anothIteration = 0;



    useEffect(() => {
        getDepartment(val => {setDepartment(val); getAdmins(val => setAdmin(val))})
        getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})
            
    }, [])
    
    useEffect(() => {
        // let isMounted = true;

                setData('No result');
                setReader(null);

                  
        //   return () => {
        //     isMounted = false;
        //     if (qrReaderRef.current) {
        //         qrReaderRef.current.pause();
        //       }
        //   };
    }, [refIncrease])


    useEffect(()=>{

        if(data !== 'No result' && (QRregister === '' || QRregister === null) && iteration === 0)
        {   
            
            let startDate = new Date();
            startDate.setHours(parseInt(data.split('*')[1].split(':')[0]), parseInt(data.split('*')[1].split(':')[1]), 0)
            let endDate = new Date();
            endDate.setHours(parseInt(data.split('*')[2].split(':')[0]), parseInt(data.split('*')[2].split(':')[1]), 0)

                setQRregister(data)
        }
       
    }
    ,[data])


 

    useEffect(()=>{
        if(data !== 'No result')
        setReader(<div style={{width: '100vw', padding: '10%'}}>
            <h3>Your QR code has the following information</h3>
            <br/>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', alignContent: 'space-around', justifyContent: 'center' }}>
      <div style={infoBoxStyle}>
        <div style={headerStyle}>Department</div>
        <div style={contentStyle}>{data.split('@')[2]}</div>
      </div>
      <div style={infoBoxStyle}>
        <div style={headerStyle}>Lecture Date</div>
        <div style={contentStyle}>{data.split('*')[0]}</div>
      </div>
      <div style={infoBoxStyle}>
        <div style={headerStyle}>Lecture Type</div>
        <div style={contentStyle}>{data.split('@')[1]}</div>
      </div>
      <div style={infoBoxStyle}>
        <div style={headerStyle}>Lecture Start Time</div>
        <div style={contentStyle}>{data.split('*')[1]}</div>
      </div>
      <div style={infoBoxStyle}>
        <div style={headerStyle}>Lecture End Time</div>
        <div style={contentStyle}>{data.split('*')[2]}</div>
      </div>
      <div style={infoBoxStyle}>
        <div style={headerStyle}>Lecture Title</div>
        <div style={contentStyle}>{data.split(',')[1]}</div>
      </div>
    </div>
            <br/>
            <button className="btn btn-dark"
    style={{
      display: 'block',
      margin: '0 auto',
      marginBottom: '20px',
      padding: '10px 20px',
      borderRadius: '5px',
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#333',
      color: '#fff'
    }}
    onClick={() => {setRefincrease(refIncrease + 1);}}>Check Another QR code</button>
          </div>)
    }, [data])



  return (
    <Fragment>
      {Admins?.filter((obj) => obj.user === user).length > 0 ? (
        <div style={{width: '100vw', textAlign: 'center'}}>
    <>{reader ||               
    <div style={{marginLeft: '50%', transform: 'translateX(-50%)'}}>
        <label>Scan your printed QR code here to check the details of it</label>
    <QrReader
    constraints={{facingMode: "environment" }}
    style = {{}}
    onResult={(result, error) => {
      if (!!result) {
        var bytes  = CryptoJS.AES.decrypt(result?.text, 'shit');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setData(decryptedData);
      }

      if (!!error) {
        // alert('QR code is not readable')
      }
    }}
    
  />
  </div>}</>
      </div>
    ) : (
        <div style={{ textAlign: 'center' }}>
          <label style={{ fontSize: '10rem' }}>⛔</label>
        </div>
      )}
    </Fragment>
  );  
}

const infoBoxStyle = {
    background: 'linear-gradient(135deg, rgb(52 58 64), #6c1e26)',
    color: 'white',
    padding: '20px',
    margin: '10px',
    borderRadius: '12px',
    width: '100%', // Adjust the width as needed
    maxWidth: '400px', // Max width for responsiveness
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
    cursor: 'pointer',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  };
  
  const headerStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  };
  
  const contentStyle = {
    fontSize: '16px',
  };

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(VerifyPrintedCode);


export default Container;
