import React from "react";
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';

const SimpleMap = (props) => {
  
  const AnyReactComponent = ({ color, Text }) => {
    const [hovered, setHovered] = React.useState(false);
  
    const handleMouseEnter = () => {
      setHovered(true);
    };
  
    const handleMouseLeave = () => {
      setHovered(false);
    };
  
    return (
      <div
        style={{
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          background: color,
          position: 'relative',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
          display: 'inline-block',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            background: 'white',
            position: 'absolute',
            top: '5px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        ></div>
        <div
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: 'white',
            position: 'absolute',
            top: '11px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        ></div>
        {hovered && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              background: 'black',
              color: 'white',
              padding: '5px',
              borderRadius: '4px',
              fontSize: '12px',
              opacity: 1,
              pointerEvents: 'none',
              transition: 'opacity 0.3s',
            }}
          >
            {Text}
          </div>
        )}
      </div>
    );
  };
  

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  const defaultProps = {
    center: {
      lat: 35.19274552136839,
      lng: -101.92088770285808,
    },
    zoom: 13,
  };

  return (
    <div>
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAonAPeHdS9nrCal6y-92SrPZ7gdbc7k4A" }}
          defaultCenter={props.location?.center || defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {props.location?.center ? (
            <AnyReactComponent
              lat={props.location?.center?.lat}
              lng={props.location?.center?.lng}
              color="green"
              Text={props.location?.User}
            />
          ) : null}
  
          {props.location?.outgoing ? (
            <AnyReactComponent
              lat={props.location.outgoing?.lat}
              lng={props.location.outgoing?.lng}
              color="red"
              Text={props.location.outgoing?.User}
            />
          ) : null}
  
          {props?.location?.data?.Attendances
            ? props.location.data.Attendances.map((attendance) => (
                <AnyReactComponent
                  key={attendance._id}
                  lat={attendance.Latitude}
                  lng={attendance.Longitude}
                  color={'#' + Math.floor(Math.random() * 16777215).toString(16)}
                  Text={attendance.User}
                />
              ))
            : null}
        </GoogleMapReact>
      </div>
    </div>
  );
  
}

function mapStateToProps(state) {
    return { khara: state }
  }
  
    
     const Container = connect(mapStateToProps)(SimpleMap);
  
  
  export default Container;
  
  
