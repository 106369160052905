import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllHandoffs, deleteAllHandoffs } from '../actions';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';
import SimpleMap from './map';

const HandoffTrackings = (props) => {

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [payload, setPayload] = useState([]);
  //const [tempPayload, setTempPayload] = useState([]);
  const [Admins, setAdmin] = useState([]);
  const [user, setUser] = useState('');
  const [mode, setMode] = useState('date');
  const [maps, setMaps] = useState('false');
  const [latincoming, setLatincoming] = useState('');
  const [longincoming, setLongincoming] = useState('');
  const [latoutgoing, setLatoutgoing] = useState('');
  const [longoutgoing, setLongoutgoing] = useState('');
  const [incomingUser, setIncomingUser] = useState('');
  const [outgoingUser, setOutgoingUser] = useState('');
  const [Department, setDepartment] = useState('');
  const [componentMounted, setComponentMounted] = useState(false);

  useEffect(() => {
    setComponentMounted(true);
    return () => setComponentMounted(false);
  }, []);
  
  useEffect(() => {
    if (componentMounted) {
      getDepartment((val) => setDepartment(val));
      getTokens((value) => {
        if (value && value[1, 4] && value[1, 4][1] !== null) setUser(value[1, 4][1].toLowerCase());
      });
      getAdmins((val) => setAdmin(val));
    }
  
    return () => {
      // Cleanup logic if needed
    };
  }, [componentMounted]);
  
  const toDateString = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
  
    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
  };
  
  useEffect(() => {
    if (componentMounted && Admins.length > 1) {
      props.dispatch(getAllHandoffs(Department)).then((res) => {
        if (res.payload) {
          if (componentMounted) {
            setPayload(res.payload);
          }
        }
      });
    }
  
    return () => {
      // Cleanup logic if needed
    };
  }, [Admins, componentMounted]);
  


function showAlertWithInput() {
  return new Promise((resolve) => {
    const userInput = prompt('Please type "confirm delete all handoff data" to delete:');
    resolve(userInput);
  });
}

const handleButton1Click = () => {setMode('date')}
const handleButton2Click = () => {setMode('resident')}

const exportToExcel =() => {
// Import the required libraries
const XLSX = require('xlsx');

// Create a new workbook and worksheet
const workbook = XLSX.utils.book_new();
const worksheetData = payload.map(({ incomingUser, leavingUser, dateOfExchange }) => ({
  'Incoming User': incomingUser,
  'Leaving User': leavingUser,
  'Date of Exchange': new Date(dateOfExchange).toLocaleString('en-US', { timeZone: 'America/Chicago' })
}));
const worksheet = XLSX.utils.json_to_sheet(worksheetData);

// Calculate column widths
const columnWidths = Object.keys(worksheetData[0]).map(column => {
  const columnData = worksheetData.map(row => row[column]);
  const columnLengths = columnData.map(value => value ? value.toString().length : 0);
  return Math.max(...columnLengths);
});

// Set column widths in the worksheet
worksheet['!cols'] = columnWidths.map(width => ({ width }));

// Add the worksheet to the workbook
XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

// Convert the workbook to a binary Excel file
const excelFile = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });

// Create a Blob from the Excel file data
const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

// Create a download link
const downloadLink = document.createElement('a');
downloadLink.href = URL.createObjectURL(blob);
downloadLink.download = 'data.xlsx';

// Append the download link to the document body
document.body.appendChild(downloadLink);

// Programmatically click the download link to trigger the file download
downloadLink.click();

// Clean up by removing the download link from the document body
document.body.removeChild(downloadLink);

}

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
    const filteredData = payload.filter((item) => toDateString(item.dateOfExchange).split(" ")[0] === selectedDate);
    setSelectedData(filteredData);
  };

  const handleNameChange = (event) => {
    const selectedName = event.target.value.toLowerCase();
    setSelectedName(selectedName);
    const filteredData = payload.filter((item) => item.incomingUser.toLowerCase() === selectedName);
    setSelectedData(filteredData);
  };

  const handleDelete = async () => {
    const input = await showAlertWithInput()
    
    if(input === "confirm delete all handoff data")
    {
    props.dispatch(deleteAllHandoffs(Department)).then((res) => 
    {
      if(res.payload.success === true)
      {
        setPayload([]);
        setSelectedData([]);
        setSelectedDate('');
        setSelectedName('');
      }
      else
      {
        alert('An error occured, nothing was deleted')
      }
    }
    )
  }
  else{
    alert('Nothing was deleted')
  }
  };





  return (
    <Fragment>        
          <div style={{width: '100vw', textAlign: 'center'}}>
      <h3>Hand off tracking</h3>
      </div>
    {Admins.filter(obj => obj.user === user && ( obj.position === "chief" || obj.position === "pd" || obj.position === "apd" || obj.position === "va")).length > 0 ?
       <>{ maps === 'false' ?
       <>
       <div>
        <br/>
       <div style={{textAlign: 'center'}}>
  <button
    style={{
      backgroundColor: 'rgb(52 58 64',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '8px 16px',
      cursor: 'pointer',
      marginRight: '10px',
    }}
    onClick={handleButton1Click}
  >
    Group By Date
  </button>
  <button
    style={{
      backgroundColor: 'rgb(52 58 64',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '8px 16px',
      cursor: 'pointer',
    }}
    onClick={handleButton2Click}
  >
    Group By Resident
  </button>
</div>
       </div>
       {Admins.filter(obj => obj.user === user && ( obj.position === "chief" || obj.position === "pd" || obj.position === "apd")).length > 0 ?
       <div style={{width: '100%', textAlign: 'center', marginTop: '10px'}}><button
       style={{
        backgroundColor: 'rgb(52 58 64',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 16px',
        cursor: 'pointer',
      }}
      onClick={exportToExcel}
       >Export All data to Excel</button></div>
       : null}
       {mode === 'date' ? <>
    <div style={{textAlign: 'center', overflow: 'scroll'}}>
      <br/>
      <select
      value={selectedDate}
      onChange={handleDateChange}
      style={{
        marginBottom: '20px',
        width: '90vw',
        maxWidth: '600px',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
      }}
    >
      <option value="">Select a date</option>
      {Array.from(new Set(payload.map(item => toDateString(item.dateOfExchange).split(" ")[0]))) // Get unique dates
        ?.map((date, index) => (
          <option key={index} value={date}>
            {date}
          </option>
      ))}
    </select>

    {selectedData.length > 0 && (
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Incoming Resident
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Leaving Resident
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Time
            </th>
            {/* <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Latitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Longitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
               Altitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Accuracy
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Leaving Latitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Leaving Longitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Leaving Altitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Leaving accuracy
            </th> */}
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Maps
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedData.map((item, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.incomingUser}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.leavingUser}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {toDateString(item.dateOfExchange)}
              </td>
              {/* <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Latitude}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Longitude}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Altitude}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Accuracy}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              {item.OutgoingLatitude}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              {item.OutgoingLongitude}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
            {item.OutgoingAltitude}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
            {item.OutgoingAccuracy}
            </td> */}
            <td style={{ border: '1px solid #ddd', padding: '8px' }}><button
                  onClick={() => {setLatincoming(item.Latitude); setLongincoming(item.Longitude); setLatoutgoing(item.OutgoingLatitude); setLongoutgoing(item.OutgoingLongitude); setIncomingUser(item.incomingUser); setOutgoingUser(item.leavingUser); setMaps('true'); }}
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#2c2222',
                    color: '#ffffff',
                    border: 'none',
                    borderRadius: '4px',
                    marginTop: '20px',
                  }}>📌</button></td>
            </tr>
          ))}
        </tbody>
      </table>
      )}
    </div>
    </>
    :
    <>
    <div style={{textAlign: 'center'}}>
      <br/>
      <select
      value={selectedName}
      onChange={handleNameChange}
      style={{
        marginBottom: '20px',
        width: '90vw',
        maxWidth: '600px',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
      }}
    >
      <option value="">Select a resident</option>
      {Array.from(new Set(payload.map(item => item.incomingUser.toLowerCase()))) // Get unique dates
        .map((date, index) => (
          <option key={index} value={date}>
            {date}
          </option>
      ))}
    </select>
    {selectedData.length > 0 && (
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Incoming Resident
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Leaving Resident
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Time
            </th>
            {/* <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Latitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Longitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
               Altitude
            </th>
            <th
              style={{
                border: '1px solid #ddd',
                padding: '8px',
                fontWeight: 'bold',
              }}
            >
              Accuracy
            </th> */}
          </tr>
        </thead>
        <tbody>
          {selectedData.map((item, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.incomingUser}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.leavingUser}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {toDateString(item.dateOfExchange)}
              </td>
              {/* <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Latitude}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Longitude}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Altitude}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.Accuracy}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      )}
    </div>
    </>}
    { payload.length !== 0 ? <div style={{textAlign: 'center'}}>
    <br/>
    <br/>
    { Admins.filter(obj => obj.user === user && ( obj.position === "pd" || obj.position === "apd")).length > 0 ? 
    <button
                  onClick={() => handleDelete(deleteAllHandoffs)}
                  style={{
                    backgroundColor: '#f44336',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    cursor: 'pointer',
                  }}
                >
                  Delete All data
                </button>: null }
    </div> : null}  </> : 
    <><SimpleMap location={{center: {lat: parseFloat(latincoming), lng: parseFloat(longincoming)}, outgoing: {lat: parseFloat(latoutgoing), lng: parseFloat(longoutgoing), User: outgoingUser}, User: incomingUser}}/>        <button
    style={{
      width: '72px',
      height: '36px',
      background: '#343a40',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: '4px',
      color: 'white',
      fontSize: '14px',
      margin: '15px',
    }}
    onClick={() => setMaps('false')}
  >
    Close
  </button></>
    }</>
    
    : <div style={{textAlign: 'center'}}><label style={{fontSize: '10rem'}}>⛔</label></div>}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return { khara: state };
}

export default connect(mapStateToProps)(HandoffTrackings);
