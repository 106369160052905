import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Signout } from './actions';
import { getTokens, setTokens} from './utils/misc';
import { withRouter, Link } from 'react-router-dom';
import { mk } from './utils/wm';
import ControlButtons from './Components/buttons';
import AdministrativeBanner from './Components/AdministrativeBanner';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';





const App = (props) => {

    const [state, setState] = useState({
      menuVisibility: "none",
      menuVisible: "none",
      borderRadius: '15px 15px 15px 15px',
      backgroundcolorofsubmenu: "transparent",
      width: 0, 
      height: 0,
      prevwidth: 0,
      isSubscribed: false,
      // redirect: true,
      // mustlogin: true,
      Pay: false,
      UserName: "",
      UserColor: "rgba(255,255,255,1)",
      ImageVisible: "inline-block"
    });
    // this.LogOut = this.LogOut.bind(this);
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


  useEffect(()=>{    

  setTimeout(()=>    getTokens((value)=>{
    if(value[4][1] && value[4][1] !== ""){
      setState({...state, UserName: value[4][1].toLowerCase()})
    }
  }), 2000)

  updateWindowDimensions();
  window.addEventListener('resize', updateWindowDimensions);
  setState({...state, prevwidth: window.innerWidth});

  return(() => {window.removeEventListener('resize', updateWindowDimensions);})
},[])


const updateWindowDimensions = () => {
  
  if(window.innerWidth < 900 && state.prevwidth < 900)
  {setState({ ...state, ImageVisible: "none", width: window.innerWidth, height: window.innerHeight, prevwidth: window.innerWidth });}
  else if(window.innerWidth < 900 && state.prevwidth >= 900)
  {setState({ ...state, ImageVisible: "none", width: window.innerWidth, height: window.innerHeight, menuVisible: "none" , borderRadius: {borderRadius: '15px 15px 15px 15px'}, prevwidth: window.innerWidth});}
  else if(window.innerWidth >= 900 && state.prevwidth < 900)
  {setState({ ...state,  ImageVisible: "inline", width: window.innerWidth, height: window.innerHeight, menuVisible: "block" , borderRadius: {borderRadius: '15px 15px 0px 0px'}, prevwidth: window.innerWidth});}
  else{setState({ ...state, ImageVisible: "inline", width: window.innerWidth, height: window.innerHeight, menuVisible: "block", borderRadius: {borderRadius: '15px 15px 0px 0px'}, prevwidth: window.innerWidth });}

}



 const LogOut = ()=>{
  setTokens({refToken: null, uid: null, token: null}, "", ()=>{
    props.dispatch(Signout());
    if(state.isSubscribed)
    {setState({...state, redirect:true, mustlogin: true, UserName: ""})}
    props.history.push("/logout")
  })
}
  


const toggleMenu = ()=> {
  if(state.menuVisible === "block" && window.innerWidth < 900)
  { 
    setState({...state, menuVisible: "none", borderRadius: '0px 0px 0px 0px'});
    setState({...state, backgroundcolorofsubmenu: "rgba(0,0,0,0.0)"})
    setState({...state, ImageVisible: "inline"})
  }
  else if(window.innerWidth < 900){ 
    setState({...state, menuVisible: "block", borderRadius: '0px 0px 0px 0px'});
    setState({...state, backgroundcolorofsubmenu: "rgba(0,0,0,0.7)"});
    setState({...state, ImageVisible: "none"});
      }
  
}


  let rendermaterial = 
  <div>
  <div className="reset-this menu-wrapper center-relative" style={{textAlign: "center"}}>
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <a className="navbar-brand" href="#">
    <img src={require('./media/logo.png')} height="30" className="d-inline-block align-top" alt=""/>
  </a>
  <button className="navbar-toggler" type="button" onClick={() => {document.getElementById('navbarNav').style.display = document.getElementById('navbarNav').style.display === 'block' ? 'none' : 'block'}}>
    <span className="navbar-toggler-icon"></span>
</button>
  <div className="navbar-collapse" id="navbarNav" style={{display: 'none'}}>
    <ul className="navbar-nav">
      <li className="nav-item">
      <Link className="nav-link" to="#"><span style={{color: "red"}} onClick={() => {LogOut(); toggleMenu()}}>Log out</span></Link>
      </li>
      <li className="nav-item">
        <a className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">Amarillo Texas Tech Portal - {state.UserName}</a>
      </li>
    </ul>
  </div>
</nav>
  </div>
  <br/>
  <ControlButtons></ControlButtons>
  <AdministrativeBanner></AdministrativeBanner>
  <div className="content-div" style={{paddingTop: "60px"}}>
  {props.children}
  </div>
  <div className={window.location.pathname.toString().toLowerCase() === "/hoster" || window.location.toString().toLowerCase() === "/slave" ? null : "BGContentDiv"} />
  </div>



  return(
    <div>
    {rendermaterial}
    </div>
    );
}

const mapStateToProps = (state) => {
  return {User: state.Userfunc}
}

const Container = connect(mapStateToProps)(withRouter(App));


export default Container;
