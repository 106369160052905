import React, { Fragment, useState, useEffect } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { submitComplaint } from '../actions/addContact';
import Alerter from './alerter';
import { getTokens, getDepartment } from '../utils/misc';



const Tab3 = (props) => {

    const [complaint, setComplaint] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [alerter, setAlerter] = useState(false);
    const [user, setUser] = useState('');
    const [department, setDepartment] = useState('');

    useEffect(() => 
    {
      getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())}); 
      getDepartment(val => setDepartment(val))
    }, [])
    

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitting(true);
        props.dispatch(submitComplaint({
          id: props._id,
          'department': department,
          complaint: complaint,
          user: user,
        })).then((res) => {
          if (res.payload.success === true || res.payload.success === 'true') {
            setSubmitting(false);
            setUploadSuccess(true);
            setAlerter(true);
            setComplaint("");
            setTimeout(()=>{setAlerter(false); setUploadSuccess(false)}, 2000)
          } else {
            setSubmitting(false);
            setUploadSuccess(false);
            setAlerter(true);
            setTimeout(()=>{setAlerter(false);}, 2000)
          }
        });
      };

    return(
      
        <Fragment>
        <div>
          {alerter ? <Alerter props={{success: uploadSuccess}}/> : null}
          <div style={{width: '100vw', textAlign: 'center'}}>
            <img src={require('../media/angry.png')} width='100px'></img>
            <br/>
            <label className="badge badge-danger" style={{margin: '10px 0px 10px 0px'}}>Feedback</label>
          </div>
          <form onSubmit={handleSubmit} style={{textAlign: 'center'}}>
            <div className="form-group" style={{}}>
              <label style={{paddingLeft: '10%', paddingRight: '10%'}} htmlFor="complaint">Please provide as much information as you want. Please do not add patient data. Please do not hesitate to provide any feedback. We will try our best to improve whenever possible. We take all feedback seriously.</label>
              <br/>
              <textarea
                className="form-control"
                id="complaint"
                rows="3"
                value={complaint}
                style={{width: '80vw', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                onChange={(event) => setComplaint(event.target.value)}
              ></textarea>
              <br/>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-dark"
                disabled={submitting}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Fragment>
        
    )
}

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(Tab3);


export default Container;
