

export const FIREBASEURL = `https://plab-2.firebaseio.com`;
// export const SIGNUP = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${APIKEY}`;
// export const SIGNIN = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${APIKEY}`;
// export const REFRESH = `https://securetoken.googleapis.com/v1/token?key=${APIKEY}`;

export const url = 'https://amaresidents.onrender.com';

export const getTokens = (cb) => {

    const a = sessionStorage.getItem('token');
    const b = sessionStorage.getItem('refreshToken');
    const c = sessionStorage.getItem('expireToken');
    const d = sessionStorage.getItem('uid');
    const e = sessionStorage.getItem('UserName');
    const f = sessionStorage.getItem('SessionCode')

    var value = [["0", a],["0", b],["0", c],["0", d],["0", e],["0", f]]
    cb(value);

}

export const setTokens = (values, userName, cb) => {
    const dateNow = new Date();
    const expiration = dateNow.getTime() + (3600 * 1000);
    
    sessionStorage.setItem('token', values.token);
    sessionStorage.setItem('refreshToken', values.refToken)
    sessionStorage.setItem('expireToken', expiration.toString())
    sessionStorage.setItem('uid', values.uid)
    sessionStorage.setItem('UserName', userName)
    if(values.SessionCode !== undefined && values.SessionCode !== null){
    sessionStorage.setItem('SessionCode', values.SessionCode)
    }
    cb()


    // AsyncStorage.multiSet([
    //     ['@nba_app@token',values.token],
    //     ['@nba_app@refreshToken',values.refToken],
    //     ['@nba_app@expireToken',expiration.toString()],
    //     ['@nba_app@uid',values.uid]
    // ]).then( response => {
    //     cb();
    // });
}

export const setHostingCode = (values, cb) => {
    sessionStorage.setItem('HostingCode', values);
    cb()
}

export const setAdmins = (values, cb) => {
    sessionStorage.setItem('Admins', JSON.stringify(values));
    if(cb) cb()
    }


export const getAdmins = (cb) => {
    var value = sessionStorage.getItem('Admins');
    cb(JSON.parse(value));
}

export const setSlaveCode = (values, cb) => {
    sessionStorage.setItem('SlaveCode', values);
    cb();
}

export const setDepartment = (values, cb) => {
    sessionStorage.setItem('Department', values);
    cb();
}

export const getDepartment = (cb) => {

    var value = sessionStorage.getItem('Department');
    cb(value)
}

export const getHostingCode = (cb) => {

    var value = sessionStorage.getItem('HostingCode');
    cb(value)
}

export const getSlaveCode = (cb) => {
    var value = sessionStorage.getItem('SlaveCode');
    cb(value);
}

export const convertFirebase = (data) => {
    const newData = [];

    for(let key in data){
        newData.push({
            ...data[key],
            id: key
        })
    }
    return newData;
}

export const findTeamData = (itemId, teams) => {
    const value = teams.find((team)=>{
        return team.id === itemId
    })
    return value;
}

export const getPreviousNavTime = (cb) => {

    var value = sessionStorage.getItem('PreviousNavTime');
    cb(value)
}

export const storeCurrentNavTime = (cb) => {
    sessionStorage.setItem('PreviousNavTime', Date.now());
    cb();
}
