import axios from 'axios';
import { getTokens, url } from '../utils/misc';

export async function putContact(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/addContact`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SET_CONTACT',
  payload: request}

}

export async function updateContact(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/updateContact`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'UPDATE_CONTACT',
  payload: request}

}

export async function submitComplaint(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/submitComplaint`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SUBMIT_COMPLAINT',
  payload: request}

}

export async function submitAttendance(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/markattendance`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SUBMIT_ATTENDANCE',
  payload: request}

}

export async function submitHandoff(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/markhandoff`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SUBMIT_HANDOFF',
  payload: request}

}

export async function deleteComplain(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/deletecomplain`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'DELETE_COMPLAIN',
  payload: request}

}

export async function deleteAttendances(attendanceIds, department) {
  let token = {};
  await getTokens(tok => token = { ...tok });
    
  try {
    const response = await axios.post(`${url}/api/deleteattendances`, {
      ids: attendanceIds,
      department: department,
      headers: {
        Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1]
      }
    });

    return {
      type: 'DELETE_ATTENDANCE',
      payload: response.data
    };
  } catch (error) {
    console.warn(error);
    return {
      type: 'DELETE_ATTENDANCE',
      payload: null
    };
  }
}



export async function deleteAllAttendances(department) {
  let token = {};
  await getTokens(tok => token = { ...tok });
    
  try {
    const response = await axios.post(`${url}/api/deletealllectures`, {
      headers: {
        Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1]
      },
      department: department
    });

    return {
      type: 'DELETE_ALLATTENDANCE',
      payload: response.data
    };
  } catch (error) {
    console.warn(error);
    return {
      type: 'DELETE_ALLATTENDANCE',
      payload: null
    };
  }
}


export async function deleteAllHandoffs(department) {
  let token = {};
  await getTokens(tok => token = { ...tok });
    
  try {
    const response = await axios.post(`${url}/api/deleteallhandoffs`, {
      headers: {
        Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1]
      }, department: department
    });

    return {
      type: 'DELETE_ALLHANDOFFS',
      payload: response.data
    };
  } catch (error) {
    console.warn(error);
    return {
      type: 'DELETE_ALLHANDOFFS',
      payload: null
    };
  }
}



export async function submitAdmin(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/addadmin`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SUBMIT_ADMIN',
  payload: request}

}

export async function submitProblemResident(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/submitproblemresident`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SUBMIT_PROBLEMRESIDENT',
  payload: request}

}

export async function deleteAdmin(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/deleteadmin`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'DELETE_ADMIN',
  payload: request}

}

export async function submitResident(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/addresidenttype`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SUBMIT_RESIDENT',
  payload: request}

}


export async function deleteResident(updateContactObject) {
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/deleteresidenttype`, updateContactObject,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'DELETE_RESIDENT',
  payload: request}

}

export async function resendVerifyLink(idToken){
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/resendverifyemail`, {idToken: idToken},{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'RESEND_VERIFICATION',
  payload: request}

}

export async function setRules(object){
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/setrules`, object,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SET_RULES',
  payload: request}

}

export async function addLectureType(object){
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/addlecturetype`, object,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SET_LECTURETYPE',
  payload: request}

}

export async function deleteLectureType(id){
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/removelecturetype`, {id: id},{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'DELTE_LECTURETYPE',
  payload: request}

}

export async function setBlockStartDates(object){
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/setblockstartdates`, object,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SET_BLOCKSTARTDATE',
  payload: request}

}

export async function setQRCode(object){
  let token = {};

  await getTokens(tok => token = {...tok});
    
  
      const request = axios.post(`${url}/api/addqrcode`, object,{ headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] }})
                  .then ( (response) => {return response.data} )
                  
                  .catch((error) => console.warn(error));


  return{type: 'SET_QRCODE',
  payload: request}

}


export async function deleteqrcodes(department, _id){

  let token = {};

  await getTokens(tok => token = {...tok});
    
      
      const request = axios.post(`${url}/api/deleteqrcodes`, {department: department, _id: _id}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                  .then ( (response) => { return response.data.data } )
                  .catch((error) => console.warn(error));

                  return({type: 'DELETE_QRCODES',
                  payload: request})
}

export async function saveqrcodes(department, JSON){

  let token = {};

  await getTokens(tok => token = {...tok});
    
      
      const request = axios.post(`${url}/api/saveqrcodes`, {department: department, JSON: JSON}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                  .then ( (response) => { return response.data.data } )
                  .catch((error) => console.warn(error));

                  return({type: 'SET_MANYQRCODES',
                  payload: request})
}

export async function saveblockedusers(department, lectureType, users){

  let token = {};

  await getTokens(tok => token = {...tok});
    
      
      const request = axios.post(`${url}/api/saveblocked`, {department: department, lectureType: lectureType, users: users}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                  .then ( (response) => { return response.data.data } )
                  .catch((error) => console.warn(error));

                  return({type: 'SET_BLOCKEDRESIDENTS',
                  payload: request})
}

export async function savetimezone(department, timezone){

  let token = {};

  await getTokens(tok => token = {...tok});
    
      
      const request = axios.post(`${url}/api/savetimezone`, {department: department, timezone: timezone}, { headers: { Authorization: "grant_type=refresh_token&refresh_token=" + token[1][1] } })
                  .then ( (response) => { return response.data.data } )
                  .catch((error) => console.warn(error));

                  return({type: 'SET_TIMEZONE',
                  payload: request})
}
