import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = ({ children }) => {
  const [state, setState] = useState({
    menuVisible: "none",
    borderRadius: '15px 15px 15px 15px',
    backgroundcolorofsubmenu: "transparent",
    width: 0,
    height: 0,
    prevwidth: 0,
    Pay: false,
    UserName: "",
    ImageVisible: "inline",
  });

  const updateWindowDimensions = () => {
    const { innerWidth, innerHeight } = window;

    if (innerWidth < 900 && state.prevwidth < 900) {
      setState({ ImageVisible: "none", width: innerWidth, height: innerHeight, prevwidth: innerWidth });
    } else if (innerWidth < 900 && state.prevwidth >= 900) {
      setState({ ImageVisible: "none", width: innerWidth, height: innerHeight, menuVisible: "none", borderRadius: '15px 15px 15px 15px', prevwidth: innerWidth });
    } else if (innerWidth >= 900 && state.prevwidth < 900) {
      setState({ ImageVisible: "inline", width: innerWidth, height: innerHeight, menuVisible: "block", borderRadius: '15px 15px 0px 0px', prevwidth: innerWidth });
    } else {
      setState({ ImageVisible: "inline", width: innerWidth, height: innerHeight, menuVisible: "block", borderRadius: '15px 15px 0px 0px', prevwidth: innerWidth });
    }
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []); // Empty dependency array means it runs only once after the initial render

  const toggleMenu = () => {
    if (state.menuVisible === "block" && window.innerWidth < 900) {
      setState({ menuVisible: "none", borderRadius: '0px 0px 0px 0px', backgroundcolorofsubmenu: "rgba(0,0,0,0.0)", ImageVisible: "inline" });
    } else if (window.innerWidth < 900) {
      setState({ menuVisible: "block", borderRadius: '0px 0px 0px 0px', backgroundcolorofsubmenu: "rgba(0,0,0,0.7)", ImageVisible: "none" });
    }
  };

  return (
    <div>
      {children}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { User: state.Userfunc };
};

const Container = connect(mapStateToProps)(withRouter(App));

export default Container;
