import React, { Fragment, useState, useEffect } from 'react';
import 'react-bootstrap/Button';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
import { getTokens, getDepartment } from '../utils/misc';
import { QrReader } from 'react-qr-reader';
import { submitHandoff } from '../actions/addContact';
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import Problem from './problem';

var CryptoJS = require("crypto-js");


const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const QRgenerator = (props) => {

    const [user, setUser] = useState("");
    const [QRString, setQRString] = useState('');
    const [signIn, setSignIn] = useState(false);
    const [location, setLocation] = useState(null);
    const [dataReader, setDataReader] = useState("");
    const [data1, setData1] = useState("");
    const [Department, setDepartment] = useState('');
    //const [data2, setData2] = useState("");
    //const [data3, setData3] = useState("");
    const [reader, setReader] = useState(null);
    let iter = 0;
    
    // useEffect(() => {
      // props.dispatch(getCurrentTime()).then(res => 
      //   {
      //     let diffs = (new Date() - new Date(res.payload))/60000;
      //     if(Math.abs(diffs) > 10){alert('The time of your system does not match the time in Amarillo. Please fix your time prior to continuing.')}
      //   }
        
    //     )
    // },[])

    useEffect(() => {
      getDepartment(val => {setDepartment(val)})
      const handleGeoLocation = () => {
        window.navigator.geolocation.getCurrentPosition(e => {
            setLocation(e)
        }, e => {
            setReader(<label>We are sorry, we cannot track handoff without tagging your location. Please allow the location tag before proceeding.</label>)});
      };
      handleGeoLocation();
  //     window.navigator.geolocation.getCurrentPosition(e => {
  //       setLocation(e)
  // }, e => {
  //     setReader(<label>We are sorry, we cannot track attendance without tagging your location. Please allow the location tag before proceeding.</label>)
  // });
    }, [])

    useEffect(()=>{getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})}, [])

    useEffect(() => {
      
        if(iter === 0 && user !== "" && location) {
        iter++;
        const interval = setInterval(() => {
            setQRString(CryptoJS.AES.encrypt(JSON.stringify(user + "," + new Date() + "," + location?.coords?.latitude + "$" + location?.coords?.longitude + "$" + location?.coords?.altitude + "$" + location?.coords?.accuracy + ",^" + "handoff" + "^" + Department), "shit").toString());
         }, 10000);

         return () => {clearInterval(interval)};
        //return;
        }
    }, [user, location])

    useEffect(() => {
      if (dataReader !== 'No result') {
        if (dataReader !== "") {
          if (dataReader.includes(",")) {
            if(dataReader.split(',') && dataReader.split(',')[1] && dataReader.split(',')[1].includes(' ') && dataReader.split(',')[1].split(' ')[4] && dataReader.split(',')[1].split(' ')[4].split(':')[0]){
            if(dataReader.split(',')[1].split(' ')[0] === new Date().toString().split(' ')[0] && dataReader.split(',')[1].split(' ')[1] === new Date().toString().split(' ')[1] && dataReader.split(',')[1].split(' ')[2] === new Date().toString().split(' ')[2] &&  dataReader.split(',')[1].split(' ')[3] === new Date().toString().split(' ')[3] && parseInt(new Date().toString().split(' ')[4].split(':')[0]) - parseInt(dataReader.split(',')[1].split(' ')[4].split(':')[0]) < 2) {
            if (dataReader.split("^")[1] === "handoff") {
              if(dataReader.split('^')[2] === Department){
              if (data1 === "") {
                setData1(dataReader);
              } 
              // else if (data1 !== dataReader && data2 === "") {
              //   setData2(dataReader);
              // } else if (data3 === "" && data1 !== dataReader && data2 !== dataReader) {
              //   setData3(dataReader);
              // }
            }else{alert("This is not a resident from your department");}
            } else {
              alert("This is not a handoff QR code");
            }
          }
          else{alert('The code you printed has either passed the hourly limit or is not printed today. Please print a new code')}
        }
        } else {
            alert('Inappropriate QR code detected');
            
          }
        }
      }
    }, [dataReader, data1]
    //[dataReader, data1, data2, data3]
    );

    useEffect(() => {
            //if(location && data3 !== "")
            if(location && data1 !== "")
            {
              uploadHandoff(location)
            }

    }, [data1]
    //[data3]
    )

    const uploadHandoff = (e) => {
        // let leavingPerson = data3.split(",")[0];
        // let dateTimeofExchange = data3.split(",")[1];
        let leavingPerson = data1.split(",")[0];
        let dateTimeofExchange = data1.split(",")[1];
        let long = e.coords?.longitude.toString() || "";
        let alt = e.coords.altitude?.toString() || "";
        props.dispatch(submitHandoff({
            id: props._id,
            department: Department,
            incomingUser: user,
            leavingUser: leavingPerson,
            dateOfExchange: dateTimeofExchange,
            Latitude: e.coords?.latitude.toString() || "",
            Longitude: long,
            Altitude: alt,
            Accuracy: e.coords?.accuracy.toString() || '',
            // OutgoingLatitude: data3.split(",")[2].split("$")[0],
            // OutgoingLongitude: data3.split(",")[2].split("$")[1],
            // OutgoingAltitude: data3.split(",")[2].split("$")[2],
            // OutgoingAccuracy: data3.split(",")[2].split("$")[3]
            OutgoingLatitude: data1.split(",")[2].split("$")[0],
            OutgoingLongitude: data1.split(",")[2].split("$")[1],
            OutgoingAltitude: data1.split(",")[2].split("$")[2],
            OutgoingAccuracy: data1.split(",")[2].split("$")[3]
          })).then((res) => {
            if (res.payload.success === true) {
                setReader(
                    <>
                    <label style={{fontSize: '10rem'}}>🫱🏼‍🫲🏼</label>
                    <br/>
                    <h2>Remember IPASS</h2>
                    {props.history.push('/attendancesuccess', {data: dataReader, type: 'Handoff'})}
                    </>
                )
            } else {
                alert('Please try again, the attendance did not work')
            }
          });
    }

    const printPageWithRows = () => {
        const rowHeight = 260; // Height of each row in pixels
        const lineHeight = 1; // Thickness of the horizontal lines in pixels
        const totalRows = 6; // Total number of rows
      
        //const pageHeight = totalRows * rowHeight + (totalRows - 1) * lineHeight;
        const pageHeight = 1500;
        const content = document.body.innerHTML;
        
        document.body.innerHTML = `
        <div id="print-container" style="height: 100vh; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-between; padding: 20px; box-sizing: border-box;">
        <div style="width: 100%; text-align: right"><h4 style="padding-right: 200px;">Date:</h4></div>
        ${Array(totalRows)
              .fill('')
              .map((_, index) => `
                <div style="height: ${rowHeight}px; border-top: ${lineHeight}px solid black; width: 100%">
                  <div style="margin: 10px;">
                    <div style="display: flex"><h3 style="font-size: 18px;">Last name - Room Number:</h3><h4 style="font-size: 16px; margin-left: 450px;">Code Status:</h4></div>
                    <h4 style="font-size: 16px;">Illness Severity:</h4><label style="margin-left: 200px"> Stable</label><label style="margin-left: 200px">Watcher</label><label style="margin-left: 200px">Unstable</label><label style="margin-left: 100px">(circle one)</label>
                    <br/>
                    <h4 style="font-size: 16px;">Patient Summary:</h4>
                    <br/>
                    <h4 style="font-size: 16px;">To do list:</h4>
                    <br/>
                    <h4 style="font-size: 16px;">Alerts, what might happen, what to do:</h4>
                  </div>
                </div>
              `)
              .join('')}
          </div>
        `;
      
        window.print();
        document.body.innerHTML = content;
      }
      
      
      const printQRhandoff = () => {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');
        mywindow.document.write('<html><head><title>Your personal QR Code</title>');
        mywindow.document.write('</head><body>');
        mywindow.document.write('<br/><br/><br/><br/>');
        mywindow.document.write('<div style="width: 100%; text-align: center">');
        mywindow.document.write('<h1>' + user + '</h1>');
        mywindow.document.write('<h1>' + new Date().toDateString() + ' QR Code</h1>');
        mywindow.document.write(document.getElementById('QRcode').innerHTML);
        mywindow.document.write('<h1>Please Use a computer that has camera on it.</h1>');
        mywindow.document.write('<h1>Make sure to print this page and scan it using the scanner of the incoming resident.</h1>');
        mywindow.document.write('</div>');
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
    
        mywindow.print();
        mywindow.close();
    
        return true;
      }
      
    

    return(

        <Fragment>
        <div style={{width: '100vw', textAlign: 'center'}}>
        <div id="movementChoice" className="btn-group" role="group" aria-label="Basic example" style={{textAlign: 'center', width: '80vw'}}>
        <button className="btn btn-danger" onClick={() => setSignIn(false)} style={{ borderRadius: '8px', padding: '8px 16px', fontSize: '14px', fontWeight: 'bold', background: '#dc3545', color: 'white', border: 'none', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', marginRight: '10px' }}>Leaving</button>
        <button className="btn btn-dark" onClick={() => setSignIn(true)} style={{ borderRadius: '8px', padding: '8px 16px', fontSize: '14px', fontWeight: 'bold', background: '#343a40', color: 'white', border: 'none', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }}>Arriving/Staying</button>
        </div>
        </div>
        <br/>
        { signIn === false ? <div style={{ textAlign: 'center', margin: '20px' }}>
        <div
          id='QRcode'
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px'
          }}
        >
        <br/>
         {reader ? (reader) : (location && user !="" && QRString !== "" ? <QRCode id='QRCodeprintable' value={QRString} /> : <>     
        <div id="QRandProblem" style={{width: '100%', display: 'block', textAlign: 'center'}}> 
        <ClimbingBoxLoader
        color="#da2d48"
        size={20}
        cssOverride={{
          display: "block",
          margin: "0 auto",
        }}
        aria-label="Loading Spinner"
        data-testid="loader"
      /><br/><label style={{display: 'block', width: '100vw'}}>Your QR code is being generated. Please be patient it will be generated shortly.</label><br/></div></>)
        }
        <br/>
        </div>
        <h2 style={{display: "block"}}>Do not forget to use IPASS for handoff</h2>
        <br/>
        <Problem/>
          </div>

          :

          <div style={{ textAlign: 'center', margin: '20px' }}>
    <div
      id='QRcode'
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
      }}
    >
    <div style={{width: '70vw', maxWidth: '600px', marginLeft: '50%', transform: 'translateX(-50%)'}}>
    {reader || <QrReader
    constraints={{facingMode: "environment" }}
    onResult={(result, error) => {
      if (!!result) {
        var bytes = '';
        decryptedData = '';
        bytes  = CryptoJS.AES.decrypt(result?.text, 'shit');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setDataReader(decryptedData);
      }

      if (!!error) {
        // alert('QR code is not readable')
      }
    }}

  />}
  </div>

    </div>
      </div>}
      <br/><br/>
      {/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) === false && signIn === false ? 
      <div style={{width: '100vw', textAlign: 'center'}}>
      <button className="btn btn-dark" onClick={() => printPageWithRows()} style={{ borderRadius: '8px', padding: '8px 16px', fontSize: '14px', fontWeight: 'bold', background: '#343a40', color: 'white', border: 'none', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'}}>Print handoff papers</button>
      </div>: null}
      <br/>
      {/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) === false && signIn === false ? 
      <div style={{width: '100vw', textAlign: 'center'}}>
      <button className="btn btn-dark" onClick={() => printQRhandoff()} style={{ borderRadius: '8px', padding: '8px 16px', fontSize: '14px', fontWeight: 'bold', background: '#343a40', color: 'white', border: 'none', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'}}>Print handoff barcode</button>
      </div>: null}
      <br/>
      <br/>
      </Fragment>
    )


}

function mapStateToProps(state) {
  return { khara: state }
}


   const Container = connect(mapStateToProps)(QRgenerator);


export default Container;
