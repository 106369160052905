import React, { Fragment, useState, useRef, useEffect } from 'react';
import 'react-bootstrap/Button';
import 'react-bootstrap/InputGroup';
import 'react-bootstrap';
import { connect } from 'react-redux';
import logo from '../media/logo.png';
import PrivacyPolicyOverlay from './Privacy';
import { getqrcodes } from '../actions/getContact';
import { getDepartment } from '../utils/misc';

const Home = props => {
  const [privacy, setPrivacy] = useState(false);
  const [lectures, setLectures] = useState([]);


  useEffect(() => {
    getDepartment(val => {
      props.dispatch(getqrcodes(val)).then((result) => {
        const filteredLectures = result.payload.filter(
          (lecture) => new Date(lecture.LectureDate + 'T23:59:59') >= new Date()
        );

        // Sort lectures by start date and start time in ascending order
        const sortedLectures = filteredLectures.sort((a, b) => {
          const aDateTime = new Date(`${a.LectureDate}T${a.StartTime}`);
          const bDateTime = new Date(`${b.LectureDate}T${b.StartTime}`);
          return aDateTime - bDateTime;
        });

        setLectures(sortedLectures);
      });
    });
  }, []);

  useEffect(()=>{
    setTimeout(()=>{document.getElementsByTagName('h3')[0]?.scrollIntoView();},1000)
  },[])

    return(
        <div style={{width: '100vw'}}>
          <div style={{textAlign: 'center'}}>
          {privacy ? <PrivacyPolicyOverlay></PrivacyPolicyOverlay>: <><p style={{ fontSize: '14px', lineHeight: '1.5', marginBottom: '20px' }}>By using our website you agree to our terms and conditions. Click <a onClick={() => setPrivacy(true)} style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}>here</a> to view our terms and conditions.</p></>}
          </div>
            <div style={{textAlign: 'center', alignContent: 'center', backgroundColor: '#f7f7f7', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                <div style={{width: '90vw', marginLeft: '50%', transform: 'translateX(-50%)'}}>
  <h1 style={{ textAlign: 'center', color: '#333', marginBottom: '40px', fontSize: '2rem' }}>Amarillo Texas Tech Resident Portal</h1>
  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
      <img
        src={logo}
        alt="Logo"
        style={{
          width: '200px',
          marginRight: '20px',
          animation: 'fadeInOut 2s ease-in-out infinite', // Add the fadeInOut animation with infinite loop
        }}
      />
      <style>
        {`
          @keyframes fadeInOut {
            0%, 100% {
              opacity: 1;
            }
            50% {
              opacity: 0.2;
            }
          }
        `}
      </style>
    </div>
  <p style={{ fontSize: '18px', lineHeight: '1.5', marginBottom: '20px' }}>
    Welcome to the Amarillo Texas Tech Portal!
  </p>
  {lectures.length > 0 ? (
  <div style={styles.container}>
    <h3 style={styles.heading}>Upcoming lectures</h3>
    <ul style={styles.list}>
      {lectures.map(lecture => (
        <li key={lecture._id} style={styles.listItem}>
          <span role="img" aria-label="Calendar" style={styles.icon}>📅</span>
          <div style={styles.textContainer}>
            <h3 style={styles.title}>{lecture.LectureTitle}</h3>
            <p style={styles.date}>Date: {lecture.LectureDate}</p>
            <p style={styles.startTime}>Start Time: {lecture.StartTime}</p>
            <p style={styles.type}>{lecture.LectureType}</p>
          </div>
        </li>
      ))}
    </ul>
  </div>
) : null}
</div>
</div>


        </div>
    )
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: 'auto',  // Ensure the container is centered
    padding: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    marginLeft: '0px',
    width: '100%',
    color: '#333',
    textAlign: 'center',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  title: {
    color: '#dc3545',
    width: '60%',
    margin: '0 auto',  // Center the title horizontally
    fontSize: '0.9em',  // Adjust the font size as needed
  },
  textContainer: {
    textAlign: 'center',
  },
  date: {
    color: '#555',
    margin: 0,
  },
  startTime: {
    color: '#555',
    margin: 0,
  },
  type: {
    color: '#0084ce',
    margin: 0,
  },
  listItem: {
    marginBottom: '20px',
    padding: '15px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    position: 'relative',  // Add this to position the icon relative to the list item
  },
  icon: {
    fontSize: '1.5em',
    position: 'absolute',
    left: '50px',  // Adjust the left position as needed
    top: '50%',  // Center the icon vertically
    transform: 'translateY(-50%)',  // Center the icon vertically
  },
};

function mapStateToProps(state) {
  return { khara: state }
}

const Container = connect(mapStateToProps)(Home);

export default Container;
