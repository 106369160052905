import React from 'react';

// import LogoImage from '';

const LogoComponent = props => (
    <div style={{width: '100%', textAlign: 'center'}}>
        <br></br>
        <br></br>
        <img alt="logo" style={{width: '15vw', margin: '10px 0px 10px 0px', minWidth: '200px'}} src={require('../media/msf.png')} />
    </div>
)

export default LogoComponent;
