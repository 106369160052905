import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getqrcodes, saveqrcodes, getLectureType, setQRCode } from '../actions';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';
import Alerter from './AlerterwithMessage';
import { QRCode } from 'react-qrcode-logo';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
var CryptoJS = require("crypto-js");

const QRManager = (props) => {
  const [Admins, setAdmin] = useState([]);
  const [user, setUser] = useState('');
  const [lectures, setLectures] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [department, setDepartment] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [lectureTypes, setLectureTypes] = useState([]);
  const [alerter, setAlerter] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [newLecture, setNewLecture] = useState({
    LectureTitle: '',
    LectureDate: '',
    StartTime: '',
    EndTime: '',
    LectureType: '',
    department: '',
    creatingUser: ''
  });
  const [QRIndex, setQRIndex] = useState(null);
  const [hiddenQR, setHiddenQR] = useState(<></>);
  const [modalQR, setModalQR] = useState(<></>);
  const [firstStart, setFirstStart] = useState(0);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isPointed, setIsPointed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getDepartment((val) => {
      setDepartment(val);
      props.dispatch(getqrcodes(val)).then((result) => {
        const filteredLectures = result.payload.filter(
          (lecture) => new Date(lecture.LectureDate + 'T23:59:59') >= new Date()
        );

        // Sort lectures by start date and start time in ascending order
        const sortedLectures = filteredLectures.sort((a, b) => {
          const aDateTime = new Date(`${a.LectureDate}T${a.StartTime}`);
          const bDateTime = new Date(`${b.LectureDate}T${b.StartTime}`);
          return aDateTime - bDateTime;
        });

        setLectures(sortedLectures);
      });
      props.dispatch(getLectureType(val)).then(value => 
        {
          setLectureTypes(value.payload)
        })
    });
    getTokens((value) => {
      if (value && value[1, 4] && value[1, 4][1] !== null) setUser(value[1, 4][1].toLowerCase());
    });
    getAdmins((val) => setAdmin(val));
  }, []);

  // useEffect(()=>{
  //   if(hiddenQR !== <></> && lectures && lectures[QRIndex] && firstStart > 0){
  //     var mywindow = window.open('', 'PRINT', 'height=400,width=600');
  //       setTimeout(()=>{
  //         mywindow.document.write('<html><head><title>' + document.title  + '</title>');
  //       mywindow.document.write('</head><body>');
  //       mywindow.document.write('<div style="width: 100%; text-align: center">');

  //       mywindow.document.write(`<img width='100' src=${require('../media/200logo.png')}></img>`);
  //       mywindow.document.write(`<h1>` + lectures[QRIndex].LectureTitle  + '</h1>');
  //       mywindow.document.write('<h1>' + lectures[QRIndex].LectureDate.split('-')[1] + '/' + lectures[QRIndex].LectureDate.split('-')[2] + '/' + lectures[QRIndex].LectureDate.split('-')[0]  + '</h1>');
  //       mywindow.document.write('<h2>' + lectures[QRIndex].LectureType  + '</h2>');
        
  //       var div = document.getElementsByTagName('canvas');
  //       const dataURL = div[0].toDataURL();
  //       //mywindow.document.write(document.getElementById(elem).innerHTML);
  //       mywindow.document.write(`<img width='400' src=${dataURL}></img>`);
  //       mywindow.document.write('<h1>Please scan the QR code using your amaresidents.onrender.com account</h1>');
  //       mywindow.document.write('<label>This QR code will start at ' + lectures[QRIndex].StartTime  + `</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Department: ${lectures[QRIndex].department}</span>`);
  //       mywindow.document.write('</div>');
  //       mywindow.document.write('</body><style type="text/css" media="print"> @page { size: portrait; } </style></html>');
  //       setTimeout(function() {
  //       mywindow.document.close(); // necessary for IE >= 10
  //       mywindow.focus(); // necessary for IE >= 10*/
    
  //       mywindow.print();
  //       mywindow.close();
  //     }, 250);
  //       },250)
      
        
  //   }
  //   setFirstStart(firstStart + 1)
  // },[hiddenQR])

  useEffect(()=>{
    setNewLecture({...newLecture, LectureType: lectureTypes[0]?.LectureType || '', StartTime: lectureTypes[0]?.LectureDefaultStartTime || "", EndTime: lectureTypes[0]?.LectureDefaultEndTime || ""})
  },[showAddModal])

  useEffect(()=>{
    let indexCreated = -1;
    lectureTypes.forEach((lecture, ind) => {if(lecture?.LectureType === newLecture?.LectureType){indexCreated = ind}})
    setNewLecture({...newLecture, StartTime: lectureTypes[indexCreated]?.LectureDefaultStartTime || "", EndTime: lectureTypes[indexCreated]?.LectureDefaultEndTime || ""})
  },[newLecture.LectureType])

  // Function to handle delete
  const handleDelete = (index) => {
    const updatedLectures = [...lectures];
    updatedLectures.splice(index, 1);
    setLectures(updatedLectures);
  };

  // Function to handle save
  const handleSave = () => {
    // Call your save function here with the updated lectures
    props.dispatch(saveqrcodes(department, lectures)).then(res => {if(res?.payload?.length >= 0){triggerAlert('Saved Successfully', true);}else{triggerAlert('Error while saving data', false);}});
  };

  const handleDragStart = (e, index) => {
    setDraggedItem(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  // Function to handle drag over
  const handleDragOver = (index) => {
    if (draggedItem === null || draggedItem === index) {
      return;
    }

    const updatedLectures = [...lectures];
    const draggedItemData = updatedLectures[draggedItem];
    updatedLectures.splice(draggedItem, 1);
    updatedLectures.splice(index, 0, draggedItemData);

    setLectures(updatedLectures);
    setDraggedItem(index);
  };

  // Function to handle drag end
  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  // Function to handle adding a new lecture
  const handleAddLecture = () => {
    setShowAddModal(true);
  };

  // Function to handle closing the add lecture modal
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

// Function to check if the title contains special characters
const containsSpecialCharacters = (title) => {
  const regex = /[=!@#$%^&*(),.?":{}|<>]/g; // Define a regular expression for special characters
  return regex.test(title); // Check if the title contains any special characters
};

const titlesAreSimilar = (title1, title2) => {
  // Convert titles to lowercase for case-insensitive comparison
  title1 = title1.toLowerCase();
  title2 = title2.toLowerCase();

  // Calculate the length of the longer title
  const maxLength = Math.max(title1.length, title2.length);

  // Calculate the Levenshtein distance between the two titles
  let distance = 0;
  for (let i = 0; i < maxLength; i++) {
    if (title1[i] !== title2[i]) {
      distance++;
    }
  }

  // Calculate the similarity ratio
  const similarityRatio = 1 - distance / maxLength;

  // Check if the similarity ratio exceeds the threshold
  return similarityRatio >= 0.8;
};

// Function to handle saving the new lecture
const handleSaveNewLecture = () => {
  // Check if there is another lecture with the same date and start time
  const isDuplicate = lectures.some(lecture => lecture.LectureDate === newLecture.LectureDate && lecture.StartTime === newLecture.StartTime || titlesAreSimilar(lecture.LectureTitle, newLecture.LectureTitle));

  // Check if the title contains special characters
  const hasSpecialCharacters = containsSpecialCharacters(newLecture.LectureTitle);
  if (isDuplicate) {
    setShowConfirmationModal(true);
  } else if (hasSpecialCharacters) {
    // Show an alert or handle the case where the title contains special characters
    triggerAlert('Title cannot contain special characters',false);
  } else {
    // Add the department to the new lecture
    const lectureWithDepartment = { ...newLecture, department: department, creatingUser: user };

    // Update the state with the new lecture
    setLectures([...lectures, lectureWithDepartment]);
    props.dispatch(setQRCode({LectureDate: lectureWithDepartment.LectureDate, LectureTitle: lectureWithDepartment.LectureTitle, department: lectureWithDepartment.department, StartTime: lectureWithDepartment.StartTime, EndTime: lectureWithDepartment.EndTime, LectureType: lectureWithDepartment.LectureType, creatingUser: lectureWithDepartment.creatingUser}))
    // Close the modal
    setShowAddModal(false);

    // Optionally, you can clear the form fields
    setNewLecture({
      LectureTitle: '',
      LectureDate: '',
    });
  }
};

// Function to handle user confirmation
const handleConfirmation = (confirm) => {
  if (confirm) {
    // Add the department to the new lecture
    const lectureWithDepartment = { ...newLecture, department: department, creatingUser: user };

    // Update the state with the new lecture
    setLectures([...lectures, lectureWithDepartment]);

    // Close the modal
    setShowConfirmationModal(false);
    setShowAddModal(false)
    // Optionally, you can clear the form fields
    setNewLecture({
      title: '',
      date: '',
      startTime: '',
      type: '',
    });
  } else {
    // Cancel the operation
    setShowConfirmationModal(false);
  }
};

  const triggerAlert = (message, success) => {
    setAlerter(true);
    setMessage(message)
    setSuccess(success)
    //setTimeout(function(){ setAlerter(false); setMessage('') }, 10000);
}

  // const handlePrintCode = async (index) => {
  //   setQRIndex(index);
  //   let image = <img src={require('../media/squareLogo.png')}/>;
  //   //setHiddenQR(<div style={{display: 'none', visible: 'collapse'}}><QRCode size={256} quietZone={10} removeQrCodeBehindLogo={true} logoPadding={10} logoPaddingStyle='circle' qrStyle='dots' logoImage={image.props.src} value={CryptoJS.AES.encrypt(JSON.stringify(lectures[index].LectureDate + "*" + lectures[index].StartTime + "*" + lectures[index].EndTime + '*,' + lectures[index].LectureTitle + ',^' + "attendance" + '^@' + lectures[index].LectureType + '@' + lectures[index].department), "shit").toString()} /></div>)
  // }

  const handleViewCode = async (index) => {
    setQRIndex(index);
    let image = <img src={require('../media/squareLogo.png')}/>;
    setModalQR(<div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', borderRadius: '5px' }}>
        <QRCode size={window.screen.width < 600 ? window.screen.width*70/100 : 550} quietZone={10} removeQrCodeBehindLogo={true} logoPadding={10} logoPaddingStyle='circle' qrStyle='dots' logoImage={image.props.src} value={CryptoJS.AES.encrypt(JSON.stringify(lectures[index].LectureDate + "*" + lectures[index].StartTime + "*" + lectures[index].EndTime + '*,' + lectures[index].LectureTitle + ',^' + "attendance" + '^@' + lectures[index].LectureType + '@' + lectures[index].department), "shit").toString()} />
        <br/>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button className='btn btn-danger' style={{ marginRight: '10px' }} onClick={() => setModalQR(<></>)}>Close</button>
            <button className='btn btn-dark' onClick={() => printVisibleCode(index)}>Print</button>
        </div>

    </div>
</div>)
  }

  const printVisibleCode = (index) => {
  
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
      setTimeout(()=>{
        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
      mywindow.document.write('</head><body>');
      mywindow.document.write('<div style="width: 100%; text-align: center">');

      mywindow.document.write(`<img width='100' src=${require('../media/200logo.png')}></img>`);
      mywindow.document.write(`<h1>` + lectures[index].LectureTitle  + '</h1>');
      mywindow.document.write('<h1>' + lectures[index].LectureDate.split('-')[1] + '/' + lectures[index].LectureDate.split('-')[2] + '/' + lectures[index].LectureDate.split('-')[0]  + '</h1>');
      mywindow.document.write('<h2>' + lectures[index].LectureType  + '</h2>');
      
      var div = document.getElementsByTagName('canvas');
      const dataURL = div[0].toDataURL();
      //mywindow.document.write(document.getElementById(elem).innerHTML);
      mywindow.document.write(`<img width='400' src=${dataURL}></img>`);
      mywindow.document.write('<h1>Please scan the QR code using your amaresidents.onrender.com account</h1>');
      mywindow.document.write('<label>This QR code will start at ' + lectures[index].StartTime  + `</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Department: ${lectures[index].department}</span>`);
      mywindow.document.write('</div>');
      mywindow.document.write('</body><style type="text/css" media="print"> @page { size: portrait; } </style></html>');
      setTimeout(function() {
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
  
      mywindow.print();
      mywindow.close();
    }, 250);
      },250)
    
      
  }

  const closeAlerter = () => {setAlerter(false); setMessage('')}

  const handleOpenEditModal = (index) => {
    setSelectedLecture(lectures[index]);
    setShowEditModal(true);
  };

  // Function to handle closing the edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedLecture(null);
  };

  const handleSaveEditedLecture = () => {
    // Find the index of the selected lecture in the array
    const lectureIndex = lectures.findIndex((lecture) => lecture._id === selectedLecture._id);
  
    if (lectureIndex !== -1) {
      // Create a new array with the edited lecture
      const updatedLectures = [...lectures];
      updatedLectures[lectureIndex] = { ...selectedLecture };
  
      // Update the state with the new array
      setLectures(updatedLectures);
    }
  
    // Close the modal
    handleCloseEditModal();
  };

  const handleTouchStart = () => {
    setIsTouched(true);
  };
  
  const handleTouchEnd = () => {
    setIsTouched(false);
    // Add any additional logic for touch end if needed
  };
  
  const handlePointStart = () => {
    setIsPointed(true);
  };
  
  const handlePointEnd = () => {
    setIsPointed(false);
    // Add any additional logic for touch end if needed
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Fragment>
    {alerter ? <Alerter success={success} failed={!success} message={message} closeAlerter={closeAlerter}/> : null}
    {Admins.filter(obj => obj.user === user && ( obj.position === "chief" || obj.position === "pd" || obj.position === "apd" || obj.position === "coordinator")).length > 0 ? <>
    <br/> 

    <div style={styles.container}>
    <div>
      <button
        className="btn btn-dark"
        style={{
          position: 'fixed',
          bottom: isExpanded ? '220px' : '20px',
          right: '20px',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          fontSize: '20px',
          fontWeight: 'bold',
          backgroundColor: '#333',
          color: '#fff',
          zIndex: '3',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        onClick={toggleExpand}
      >
        {isExpanded ? '🔽' : '🛠️'}
      </button>
      
      {isExpanded && (
        <>
          <div>
    <span
        style={{
          position: 'fixed',
          bottom: '70px',
          right: '60px',
          fontSize: '12px',
          backgroundColor: '#998ea4',
          padding: '8px',
          borderRadius: '5px',
          visibility: isHovered ? 'visible' : 'hidden',
          zIndex: '10',
          border: '1px solid #52495b',
          transition: 'visibility 0.3s ease',
          marginLeft: '10px',
        }}
      >
        Set the start date from which users can check their attendance rate.
      </span>
  <button
      className="btn btn-dark"
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        fontSize: '20px',
        fontWeight: 'bold',
        backgroundColor: '#333',
        color: '#fff',
        zIndex: '3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
      onClick={() => props.history.push('/blockstartdate')}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
    >
      📅
    </button>
        <span
          style={{
            position: 'fixed',
          bottom: '160px',
          right: '130px',
          transform: 'translateX(50%)',
          fontSize: '12px',
          backgroundColor: '#998ea4',
          padding: '8px',
          borderRadius: '5px',
          visibility: isPointed ? 'visible' : 'hidden',
          zIndex: '3',
          width: 'max-content',
          }}
        >
          QR control settings
        </span>
      <button
        className="btn btn-dark"
        style={{
          position: 'fixed',
        bottom: '130px',
        right: '20px',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        fontSize: '20px',
        fontWeight: 'bold',
        backgroundColor: '#333',
        color: '#fff',
        zIndex: '3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        color: '#dc3545',
        }}
        onClick={() => props.history.push('/qrcodegenerator')}
        onTouchStart={handlePointStart}
        onTouchEnd={handlePointEnd}
        onMouseEnter={handlePointStart}
        onMouseLeave={handlePointEnd}
      >
        ⚙️
      </button>

        </div>
        <div>
        <span
          style={{
            position: 'fixed',
          bottom: '110px',
          right: '140px',
          transform: 'translateX(50%)',
          fontSize: '12px',
          backgroundColor: '#998ea4',
          padding: '8px',
          borderRadius: '5px',
          visibility: isTouched ? 'visible' : 'hidden',
          zIndex: '3',
          width: 'max-content',
          }}
        >
          Verify Printed QR code
        </span>
      <button
        className="btn btn-dark"
        style={{
          position: 'fixed',
        bottom: '75px',
        right: '20px',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        fontSize: '20px',
        fontWeight: 'bold',
        backgroundColor: '#333',
        color: '#fff',
        zIndex: '3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        color: '#dc3545',
        }}
        onClick={() => props.history.push('/verifyprintedcode')}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseEnter={handleTouchStart}
        onMouseLeave={handleTouchEnd}
      >
        ✓
      </button>
        </div>
        </>
      )}
    </div>
        <div style={{overflowY: 'auto' }}> {/* Adjust maxHeight as needed */}
  <h3 style={styles.heading}>QR Code Manager</h3>
  <ul style={styles.list}>
    {lectures.map((lecture, index) => (
      <li
        key={index}
        draggable
        onDragStart={(e) => handleDragStart(e, index)}
        onDragOver={() => handleDragOver(index)}
        onDragEnd={handleDragEnd}
        style={index === draggedItem ? { ...styles.listItem, backgroundColor: '#ddd', margin: '10px' } : styles.listItem}
      >
        <span role="img" aria-label="Calendar" style={styles.icon}>
          📅
        </span>
        <div style={styles.textContainer}>
          <h3 style={styles.title}>{lecture.LectureTitle}</h3>
          <p style={styles.date}>Date: {lecture.LectureDate}</p>
          <p style={styles.startTime}>Start Time: {lecture.StartTime}</p>
          <p style={styles.startTime}>End Time: {lecture.EndTime}</p>
          <p style={styles.type}>{lecture.LectureType}</p>
        </div>
        <button style={styles.deleteButton} onClick={() => handleDelete(index)}>
          🗑️
        </button>
        <button style={styles.editButton} onClick={() => handleOpenEditModal(index)}>
          ✏️
        </button>
        <button style={styles.printButton} onClick={() => handleViewCode(index)}>
          👁️
        </button>
      </li>
    ))}<br/><br/>
  </ul>
</div>
<div style={{fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: 'auto', padding: '20px', backgroundColor: 'rgb(240, 240, 240)', borderRadius: '8px', position: 'fixed', bottom: '0', left: '50%', transform: 'translateX(-50%)'}}>
      <div style={{maxWidth: '560px', width: '100vw'}}>
        <button className="btn btn-danger"  style={{ marginLeft: '10px', marginBottom: '10px' }} onClick={handleSave}>
  Save
</button>
<button className="btn btn-dark" style={{ marginLeft: '10px', marginBottom: '10px' }} onClick={handleAddLecture}>
  Add Lecture
</button>
<label style={{display: 'inline', fontSize: '10px', padding: '10px', color: '#93928e'}}>{window.screen.width > 510 ? 'Your work is not saved until you click the save button': ''}</label></div>
      </div>
{hiddenQR}
{modalQR}

      </div>

      {/* {showConfirmationModal ? ( */}
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Another lecture with the same title or date and time already exists. Are you sure you want to continue adding?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleConfirmation(false)}>No</Button>
        <Button variant="primary" onClick={() => handleConfirmation(true)}>Yes</Button>
      </Modal.Footer>
    </Modal>
{/* // ) : null} */}


      {/* Add Lecture Modal */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Lecture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="lectureTitle">
              <Form.Label>Lecture Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter lecture title"
                value={newLecture.LectureTitle}
                onChange={(e) => setNewLecture({ ...newLecture, LectureTitle: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="lectureDate">
              <Form.Label>Lecture Date</Form.Label>
              <Form.Control
                type="date"
                value={newLecture.LectureDate}
                onChange={(e) => setNewLecture({ ...newLecture, LectureDate: e.target.value })}
              />
              <Form.Group controlId="lectureType">
    <Form.Label>Lecture Type</Form.Label>
    <Form.Control
      as="select"
      value={newLecture.LectureType}
      onChange={(e) => setNewLecture({ ...newLecture, LectureType: e.target.value })}
    >
      {lectureTypes.map(obj => (
        <option key={obj._id} value={obj.LectureType}>
          {obj.LectureType}
        </option>
      ))}
    </Form.Control>
  </Form.Group>
            </Form.Group>
            <Form.Group controlId="lectureStartTime">
              <Form.Label>Lecture Start Time</Form.Label>
              <Form.Control
                type="time"
                value={newLecture.StartTime}
                onChange={(e) => setNewLecture({ ...newLecture, StartTime: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="lectureEndTime">
              <Form.Label>Lecture End Time</Form.Label>
              <Form.Control
                type="time"
                value={newLecture.EndTime}
                onChange={(e) => setNewLecture({ ...newLecture, EndTime: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
          {newLecture.LectureTitle !== '' && newLecture.EndTime !== '' && newLecture.LectureDate !== '' && newLecture.LectureType !== '' && newLecture.StartTime !== '' ? <Button variant="primary" onClick={handleSaveNewLecture}>
            Add
          </Button>: null}
        </Modal.Footer>
      </Modal>

      {/* Edit Lecture Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Lecture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Your form for editing lecture details */}
          <Form>
            <Form.Group controlId="editLectureTitle">
              <Form.Label>Lecture Title</Form.Label>
              <Form.Control
                type="text"
                value={selectedLecture?.LectureTitle || ''}
                onChange={(e) =>
                  setSelectedLecture({
                    ...selectedLecture,
                    LectureTitle: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="editLectureDate">
              <Form.Label>Lecture Date</Form.Label>
              <Form.Control
                type="date"
                value={selectedLecture?.LectureDate || ''}
                onChange={(e) =>
                  setSelectedLecture({
                    ...selectedLecture,
                    LectureDate: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="lectureType">
    <Form.Label>Lecture Type</Form.Label>
    <Form.Control
      as="select"
      value={selectedLecture?.LectureType}
      onChange={(e) => setSelectedLecture({ ...selectedLecture, LectureType: e.target.value })}
    >
      {lectureTypes.map(obj => (
        <option key={obj._id} value={obj.LectureType}>
          {obj.LectureType}
        </option>
      ))}
    </Form.Control>
    </Form.Group>
            <Form.Group controlId="editLectureStartTime">
              <Form.Label>Lecture Start Time</Form.Label>
              <Form.Control
                type="time"
                value={selectedLecture?.StartTime || ''}
                onChange={(e) =>
                  setSelectedLecture({
                    ...selectedLecture,
                    StartTime: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="lectureEndTime">
              <Form.Label>Lecture End Time</Form.Label>
              <Form.Control
                type="time"
                value={selectedLecture?.EndTime}
                onChange={(e) => setSelectedLecture({ ...selectedLecture, EndTime: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEditedLecture}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
      </> : <div style={{textAlign: 'center'}}><label style={{fontSize: '10rem'}}>⛔</label></div>}
    </Fragment>
  );
};

const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '600px',
      margin: 'auto',
      padding: '20px',
      backgroundColor: '#f0f0f0',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
      marginLeft: '0px',
      width: '100%',
      color: '#333',
      textAlign: 'center',
    },
    list: {
      listStyleType: 'none',
      padding: 0,
    },
    title: {
      color: '#dc3545',
      width: '60%',
      margin: '0 auto',
      fontSize: '0.9em',
    },
    textContainer: {
      textAlign: 'center',
    },
    date: {
      color: '#555',
      margin: 0,
    },
    startTime: {
      color: '#555',
      margin: 0,
    },
    type: {
      color: '#555',
      margin: 0,
    },
    listItem: {
      marginBottom: '20px',
      padding: '15px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
      position: 'relative',
    },
    icon: {
      fontSize: '1.5em',
      position: 'absolute',
      left: '50px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    deleteButton: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      fontSize: '1.5em',
      color: '#dc3545',
    },
    printButton: {
        position: 'absolute',
        right: '50px',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1.5em',
        color: '#dc3545',
      },
      editButton: {
        position: 'absolute',
        right: '100px', // Adjust as needed
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1.5em',
        color: '#007bff', // Use a color of your choice
      },
      viewButton: {
        position: 'absolute',
        right: '10px', // Adjust as needed
        top: '80%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1.5em',
        color: '#007bff', // Use a color of your choice
      },
      modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      modal: {
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        maxWidth: '400px',
        width: '100%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      },
      modalHeader: {
        padding: '15px',
        borderBottom: '1px solid #dddddd',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      closeButton: {
        cursor: 'pointer',
        fontSize: '24px',
        color: '#999999',
      },
      modalBody: {
        padding: '20px',
      },
      buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
      },
      button: {
        cursor: 'pointer',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
      },
      yesButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        marginLeft: '10px',
      },
      noButton: {
        backgroundColor: '#f44336',
        color: '#ffffff',
      },
  };

function mapStateToProps(state) {
  return { khara: state };
}

export default connect(mapStateToProps)(QRManager);
