import React from 'react';
import { security } from '../utils/wm';
import 'react-bootstrap/Badge';


const Mode = props => {

    let Background = require('../media/lab.jpg');

    return(
        <div style={{textAlign: 'center'}}>
            <div style={{backgroundImage: `url(${Background})`, height: 'calc(100vh - 155px)', backgroundSize: 'cover'}}>
            <br/>
            <br/>
            <div style={{width: 'fit-content', height: 'fit-content', padding: '20px', borderRadius: '15px', background: 'rgba(255,255,255,0.8)', marginLeft: '50%', transform: 'translateX(-50%)'}}>
            <img src={require('../media/msf.png')} style={{width: '40%'}}></img>
            <br/>
            <br/>
            <label className="badge badge-dark" style={{width: '200px'}}>{security()}</label>
            </div>
            </div>
        </div>
    )
}

export default Mode;
