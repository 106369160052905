import React, { Fragment, useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import 'react-bootstrap/Button';

const Alerter = props => {

    const [renderWindow, setRenderWindow] = useState(<></>)

    useEffect(() => {
        setRenderWindow(
        <div style={{position: 'fixed',
        left: '0',
        top: '0',
        background: props.props.success ? '#dc3545' : "#212529",
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: '99999999999999999999'
    }}>
<label style={{display: 'inlineBlock',
maxWidth: '100%',
fontSize: '10rem'}}>{props.props.success ? "😎" : "😡"}</label>
<br/>
<label style={{display: 'inlineBlock',
maxWidth: '100%',
fontSize: '3rem',
color: props.props.success ? 'black' : "white"}}>{props.props.success ? "Uploaded" + "!" : "Error" }</label>


    </div>)
    }, [props])

    let style = {
        
  background : {
    background: '#dc3545',
      position: 'absolute',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100vw',
  },
  keyboard : {
    display: 'flex',
    background: '#191919',
    width: '170px',
    marginLeft: '50%',
    marginTop: '50vh',
    transform: 'translate(-50%, -50%)',
    height: '95px',
    padding: '3px',
    justifyContent: 'center',
    borderRadius: '10px'
  },
  whitebuttons: {
    width: '50px',
    height: '70px',
    background: '#FFFFFF', 
    position: 'relative',
    top: '20px',
    borderRadius: '5px',
    margin: '2px'
  },
  
  transparentkeyboard: {
     display: 'flex',
    background: 'transparent',
    position: 'absolute',
    width: '170px',
    marginLeft: '50%',
    marginTop: '50vh',
    transform: 'translate(-50%, -50%)',
    height: '95px',
    padding: '3px',
    justifyContent: 'center',
    borderRadius: '10px',
    zIndex: '1'
  },
  
  blackbuttons: {
    width: '15px',
    height: '39px',
    background: '#191919', 
    position: 'relative',
    top: '18px',
    left: '-4px',
    marginLeft: '9px',
  }
    }

    return(
        
<>
{renderWindow}
</>
        
        
    )
}

export default Alerter;
