import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-bootstrap/Button';
import { getTokens, getAdmins, getDepartment } from '../utils/misc';
import { getAllAdministrators } from '../actions';

const AdministrativeBanner = props => {
    const [Admins, setAdmin] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [Department, setDepartment] = useState('');
    const [user, setUser] = useState('');
    const [activateAdmin, setActivateAdmin] = useState(false);
    const [screenDimentions, setScreenDimensions] = useState([0,0]);

    const handleButtonClick = () => {
        setExpanded(!expanded);
      };


    useEffect(()=>{
      getTokens(value => {if(value && value[1,4] && value[1,4][1] !== null) setUser(value[1,4][1].toLowerCase())})
      getAllAdministrators().then(()=> getAdmins(val => setAdmin(val)))
      getDepartment(val => {setDepartment(val)})
      setScreenDimensions([window.screen.width, window.screen.height]);
    },[])

    useEffect(()=>{Admins?.length > 0 && Admins?.filter(obj => obj.user === user).length > 0 && user !== '' && Department !== '' ? setActivateAdmin(true): setActivateAdmin(false)},[user,Admins,Department])

    useEffect(()=>{if(!activateAdmin){
      setTimeout(() => {
            if(Admins.length < 1){getAdmins(val => {setAdmin(val)})}
          }, 500);
    };
  },[activateAdmin])


  if(activateAdmin)
  return(
    
     <>
      { expanded ? 
       <>
       <br/>
       <br/>
      <div className="btn-group" role="group" aria-label="Basic example" style={{textAlign: 'center', width: '100vw'}}>
      { Admins?.filter(obj => obj.user === user && (obj.position === "chief" || obj.position === "pd" || obj.position === "apd" || obj.position === "coordinator"  || obj.position === "housestaff")).length > 0 ? <>
          <button className={window.location.pathname.toLowerCase() === "/qrmanager" ?"btn btn-danger" : "btn btn-dark"} onClick={()=>props.history.push('/qrmanager')}>{screenDimentions[0] < 1921 ? <img src={require('../media/QRcodeLogo.png')} style={{width: '20px'}}/>: 'QR code'}</button>
          { Admins?.filter(obj => obj.user === user && (obj.position === "chief" || obj.position === "pd" || obj.position === "apd" || obj.position === "coordinator")).length > 0 ?
          <button className={window.location.pathname.toLowerCase() === "/lectureattendance" ?"btn btn-danger" : "btn btn-dark"} onClick={()=> props.history.push('/LectureAttendance')}>{screenDimentions[0] < 1921 ? <img src={require('../media/Attendance.png')} style={{width: '30px'}} />: 'Attendance'}</button>
          : null}
          </>: null}
          { Admins?.filter(obj => obj.user === user && (obj.position === "chief" || obj.position === "pd" || obj.position === "apd" || obj.position === "va")).length > 0 ? <>
          <button className={window.location.pathname.toLowerCase() === "/handofftracking" ?"btn btn-danger" : "btn btn-dark"} onClick={()=> props.history.push('/HandoffTracking')}>{screenDimentions[0] < 1921 ? <img src={require('../media/handoff.png')} style={{width: '35px'}} />: 'Handoff'}</button>
          {Admins?.filter(obj => obj.user === user && (obj.position === "chief" || obj.position === "pd" || obj.position === "apd")).length > 0 ?
          <button className={window.location.pathname.toLowerCase() === "/reviewcomplaints" ?"btn btn-danger" : "btn btn-dark"} onClick={()=> props.history.push('/ReviewComplaints')}>{screenDimentions[0] < 1921 ? <img src={require('../media/feedback.png')} style={{width: '30px'}} />: 'Feedback'}</button>
          : null}
          </> : null}
          { Admins?.filter(obj => obj.user === user && (obj.position === "pd" || obj.position === "apd")).length > 0 ? 
          <button className={window.location.pathname.toLowerCase() === "/administrators" ?"btn btn-danger" : "btn btn-dark"} onClick={()=> props.history.push('/Administrators')}>{screenDimentions[0] < 1921 ? <img src={require('../media/admin.png')} style={{width: '20px'}} />: 'Administrators'} </button>: null}
          { Admins?.filter(obj => obj.user === user && (obj.position === "pd" || obj.position === "apd" || obj.position === "coordinator" || obj.position === "chief")).length > 0 ? 
          <button className={window.location.pathname.toLowerCase() === "/residenttypes" ?"btn btn-danger" : "btn btn-dark"} onClick={()=> props.history.push('/ResidentTypes')}>{screenDimentions[0] < 1921 ? <img src={require('../media/users.png')} style={{width: '30px'}} />: 'Users'} </button>: null}
      </div>
      </>
      : <><button
      onClick={handleButtonClick}
      style={{
        margin: '20px',
        padding: '10px',
        backgroundColor: '#343a40',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        boxShadow: '0px 2px 4px rgba(52, 58, 64, 0.1)',
        transition: 'background-color 0.3s, box-shadow 0.3s',
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#495057';
        e.target.style.boxShadow = '0px 4px 8px rgba(52, 58, 64, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = '#343a40';
        e.target.style.boxShadow = '0px 2px 4px rgba(52, 58, 64, 0.1)';
      }}
    >
      {expanded ? null : <img src={require('../media/wrench.png')} width={'25px'}></img> }
    </button></> }
      </>)

      else return(<></>)




}

function mapStateToProps(state) {
  return { khara: state }
}

  
   const Container = connect(mapStateToProps)(withRouter(AdministrativeBanner));


export default Container;
